import React from 'react';
// import { usePowerPointStore } from '@src/providers/store';
import { message } from 'antd';
import usePowerPointStore from '@src/providers/store';
import { toast } from 'sonner';
AbortController;
const images = [
    '/pptx/p1.jpg',
    '/pptx/p2.jpg',
    '/pptx/p6.jpeg',
    '/pptx/ptbg.jpg',
    '/pptx/ptbg2.jpg',
    '/pptx/coach.webp',
    '/pptx/pptx-canva2.png',
    '/pptx/coach-1.jpg',
    '/pptx/coach-2.jpg',
    '/pptx/pptxcanva-3.png',
    '/pptx/pptxcanva-4.png',
    '/pptx/pptxcanva-5.png',
    '/pptx/pptxcanva-6.png',
];

const BackgroundTemplateDisplayer: React.FC = () => {
    const setBackground = usePowerPointStore((state) => state.setBackground);
    const background = usePowerPointStore((state) => state.background);

    const handleImageClick = (imageUrl: string) => {
        setBackground(imageUrl);
        toast.info('Background image updated successfully!');
    };

    return (
        <div style={styles.gridContainer}>
            {images.map((image, index) => (
                <div key={index} style={styles.thumbnailContainer}>
                    <img
                        src={image}
                        alt={`Background ${index + 1}`}
                        style={styles.thumbnail}
                        onClick={() => handleImageClick(image)}
                    />
                    {background === image && (
                        <small
                            className="text-[12px] text-red !mt-[-4rem] "
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                padding: '2px 5px',
                                borderRadius: '4px',
                                fontSize: '12px',
                            }}
                        >
                            selected
                        </small>
                    )}
                </div>
            ))}
        </div>
    );
};

const styles = {
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '10px',
    } as React.CSSProperties,
    thumbnailContainer: {
        cursor: 'pointer',
    } as React.CSSProperties,
    thumbnail: {
        width: '100%',
        height: '80px',
        borderRadius: '8px',
    } as React.CSSProperties,
    selectedLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '2px 5px',
        borderRadius: '4px',
        fontSize: '12px',
    } as React.CSSProperties,
};

export default BackgroundTemplateDisplayer;
