import menu from '@assets/transcriber/menu.svg';
import logo from '@assets/gmind.png';
import gmind from '@assets/essay/Gmind Logo White.png';
import history from '@assets/history_clock.svg';
import { useNavigate } from 'react-router-dom';
import BackButton from '@src/components/BackButton';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@src/theme_provider/theme';
import { Dropdown, Menu, MenuProps } from 'antd';
import { IoMdLogOut } from 'react-icons/io';
import { History } from 'lucide-react';
const MobileNavbar = ({
    showSidebar,
    showHistory,
    style,
    showMobileHistory,
    className,
    isGuest,
    onShowHistoryOption,
}: {
    showSidebar: () => void;
    showHistory: () => void;
    style?: React.CSSProperties;
    showMobileHistory?: boolean;
    className?: string;
    isGuest?:boolean;
    onShowHistoryOption?: () => void;
}) => {
    const navigate = useNavigate();
    const { savedTheme, toggleTheme } = useContext(ThemeContext);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        console.log(window.location.pathname);

    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, [window.location.pathname]);


    function handleLogout() {
        if ( isGuest ){
            navigate('/signup');
            return; 
        }

        if (!authenticated) {
            navigate('/login');
            return;
        }
        localStorage.removeItem('token');
        localStorage.setItem('isLoggedOut', 'true');
        window.location.reload();
    }

    const dropdownItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <button
                    onClick={handleLogout}
                    className="p-2 transition-all flex items-center hover:bg-brandOrange hover:text-white gap-2 text-md justify-center rounded-md w-full text-black dark:text-black"
                    type="button"
                >
                    <IoMdLogOut className="text-lg text-black  rotate-180" />
                    <span className='dark:text-black'>{isGuest ? 'Signup' : authenticated ? 'Logout' : 'Login'}</span>
                </button>
            ),
        },
        {
            key: '2',
            label: (
                <div className='flex flex-row items-center gap-2'>
                {isGuest ? null: <button
                    onClick={() => showHistory && showHistory()}
                    type="button"
                    className="flex flex-row items-center gap-2 text-black  w-fit p-1 px-3 text-md"
                >
                    <History className="h-5 text-black  rotate-180" />
                    History
                </button>}
                </div>
            ),
        },
    ];


    return (
        <section style={style} className={'p-6 flex items-center justify-between ' + className}>
            {window.location.pathname == 'pricing' || window.location.pathname == '/pricing' ? (
                <>
                    <BackButton
                        onclick={() => {
                            navigate(-1);
                        }}
                    />
                </>
            ) : savedTheme === 'dark' ? (
                <button onClick={showSidebar} type="button">
                <IconMenu />
                </button>
            ) : (
                <button onClick={showSidebar} type="button">
                    <img src={menu} alt="menu" />
                </button>
            )}
            <div>
                <img className="h-8" onClick={() => navigate('/')} src={savedTheme === 'dark' ? gmind : logo} alt="" />
            </div>
            {showMobileHistory ? (
                          <Dropdown overlayClassName="menu" trigger={['click']} menu={{ items: dropdownItems }}>
                          <button type="button">
                              {savedTheme === 'dark' ? <IconHistory /> : <img src={history} alt="history" />}
                          </button>
                      </Dropdown>
            ) : (
                <div></div>
            )}
        </section>
    );
};

export default MobileNavbar;

export const IconMenu = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="27" height="27" fill="rgba(255,255,255,0)" />
            <rect
                x="4.5"
                y="4.5"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="4.5"
                y="4.5"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="15.75"
                y="4.5"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="15.75"
                y="4.5"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="4.5"
                y="15.75"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="4.5"
                y="15.75"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="15.75"
                y="15.75"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="15.75"
                y="15.75"
                width="6.75"
                height="6.75"
                rx="1"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const IconHistory = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5 9V13.5L15.75 15.75"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.5 9V13.5L15.75 15.75"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.6855 12.299C2.64353 12.711 2.94356 13.0791 3.35564 13.1211C3.76772 13.1631 4.1358 12.863 4.17778 12.451L2.6855 12.299ZM4.69861 16.6176C4.55649 16.2286 4.12588 16.0284 3.73681 16.1705C3.34774 16.3126 3.14755 16.7432 3.28967 17.1323L4.69861 16.6176ZM2.68164 22.5C2.68164 22.9142 3.01743 23.25 3.43164 23.25C3.84585 23.25 4.18164 22.9142 4.18164 22.5H2.68164ZM3.43164 16.875V16.125C3.01743 16.125 2.68164 16.4608 2.68164 16.875H3.43164ZM9.05664 17.625C9.47085 17.625 9.80664 17.2892 9.80664 16.875C9.80664 16.4608 9.47085 16.125 9.05664 16.125V17.625ZM4.17778 12.451C4.66105 7.70665 8.62888 4.08109 13.3974 4.02662L13.3803 2.52672C7.84878 2.5899 3.24609 6.79555 2.6855 12.299L4.17778 12.451ZM13.3974 4.02662C18.166 3.97216 22.2156 7.50614 22.8071 12.2382L24.2955 12.0521C23.6094 6.56296 18.9118 2.46354 13.3803 2.52672L13.3974 4.02662ZM22.8071 12.2382C23.3986 16.9702 20.3435 21.3923 15.7083 22.5133L16.0609 23.9713C21.4378 22.6709 24.9817 17.5413 24.2955 12.0521L22.8071 12.2382ZM15.7083 22.5133C11.073 23.6343 6.33484 21.097 4.69861 16.6176L3.28967 17.1323C5.1877 22.3284 10.684 25.2717 16.0609 23.9713L15.7083 22.5133ZM4.18164 22.5V16.875H2.68164V22.5H4.18164ZM3.43164 17.625H9.05664V16.125H3.43164V17.625Z"
                fill="white"
            />
            <path
                d="M2.6855 12.299C2.64353 12.711 2.94356 13.0791 3.35564 13.1211C3.76772 13.1631 4.1358 12.863 4.17778 12.451L2.6855 12.299ZM4.69861 16.6176C4.55649 16.2286 4.12588 16.0284 3.73681 16.1705C3.34774 16.3126 3.14755 16.7432 3.28967 17.1323L4.69861 16.6176ZM2.68164 22.5C2.68164 22.9142 3.01743 23.25 3.43164 23.25C3.84585 23.25 4.18164 22.9142 4.18164 22.5H2.68164ZM3.43164 16.875V16.125C3.01743 16.125 2.68164 16.4608 2.68164 16.875H3.43164ZM9.05664 17.625C9.47085 17.625 9.80664 17.2892 9.80664 16.875C9.80664 16.4608 9.47085 16.125 9.05664 16.125V17.625ZM4.17778 12.451C4.66105 7.70665 8.62888 4.08109 13.3974 4.02662L13.3803 2.52672C7.84878 2.5899 3.24609 6.79555 2.6855 12.299L4.17778 12.451ZM13.3974 4.02662C18.166 3.97216 22.2156 7.50614 22.8071 12.2382L24.2955 12.0521C23.6094 6.56296 18.9118 2.46354 13.3803 2.52672L13.3974 4.02662ZM22.8071 12.2382C23.3986 16.9702 20.3435 21.3923 15.7083 22.5133L16.0609 23.9713C21.4378 22.6709 24.9817 17.5413 24.2955 12.0521L22.8071 12.2382ZM15.7083 22.5133C11.073 23.6343 6.33484 21.097 4.69861 16.6176L3.28967 17.1323C5.1877 22.3284 10.684 25.2717 16.0609 23.9713L15.7083 22.5133ZM4.18164 22.5V16.875H2.68164V22.5H4.18164ZM3.43164 17.625H9.05664V16.125H3.43164V17.625Z"
                fill="white"
                fill-opacity="0.25"
            />
        </svg>
    );
};
