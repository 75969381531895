/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor';
import {
    table,
    font,
    fontSize,
    formatBlock,
    list,
    align,
    video,
    imageGallery,
    hiliteColor,
    fontColor,
    link,
    image,
} from 'suneditor/src/plugins';
import { marked } from 'marked';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { useSearchParams } from 'react-router-dom';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor's CSS
import './editor/styles/index.scss';
import { UpdateDocument } from '@src/core/endpoints/essay';

import { Button, Dropdown, MenuProps } from 'antd';

import darkLogo from '@assets/essay/gmind_circular.png';
import { RightOutlined } from '@ant-design/icons';
import DraggableButton from './editor/drag';
import { toast } from 'react-toastify';
import { on } from 'process';
import { ChatType } from '../../endpoints';
import DOMPurify from 'dompurify';
import { uploadToCloudinary } from '../slide/upload';
import { ThemeContext } from '@src/theme_provider/theme';

const NewSunEditorComponent = ({
    humanized_content,
    translated_content,
    hasAccess,
    initial_content,
    onChangeEditorContent,
    contentHeight,

    Response,
    setResponse,

    setIsEditContent,
    contentIsTyping,
    editorContent,
    setEditorContent,
    editorInstanceRef,
    isContent,
}: {
    translated_content?: any;
    humanized_content?: any;
    initial_content: any;
    hasAccess: boolean;
    contentHeight?: boolean;
    onChangeEditorContent: (content: string) => void;
    Response?: string;
    setResponse?: Dispatch<React.SetStateAction<string>>;
    setIsEditContent?: (e: boolean) => void;
    contentIsTyping?: boolean;
    editorContent?: string;
    setEditorContent?: Dispatch<React.SetStateAction<string>>;
    editorInstanceRef: React.MutableRefObject<any>;
    isContent?: boolean;
}) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const [params] = useSearchParams();
    const documentId = params.get('document');

    const desktopToolBarValue = [
        'link',
        'image',
        'video',
        'fullScreen',
        'showBlocks',
        'codeView',
        'preview',
        'print',
        'save',
    ];
    const mobileToolBarValue = ['link', 'image', 'video', 'showBlocks', 'codeView', 'preview', 'print', 'save'];

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const EditorRef = useRef<any>();

    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const token = localStorage.getItem('token') || '';
    const editor_content = useRef<string | null>(null);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const [position, setPosition] = useState({ x: 150, y: 120 });
    const [isTyping, setIsTyping] = useState<{ [key: string]: boolean }>({});
    const [latestRespone, setLatestRespone] = useState('');
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [fieldTexts, setFieldTexts] = useState<string>('');
    const editorContainerRef = useRef<HTMLDivElement>(null);
    const { savedTheme, isMobile, isLargeTab } = useContext(ThemeContext);

    const buttonRef = useRef<HTMLDivElement>(null);

    const handleContentChange = (content: string) => {
        editor_content.current = content;

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a debounce timeout of 2 seconds (2000 ms)
        debounceTimeout.current = setTimeout(() => {
            onChangeEditorContent(content);
        }, 2000);
    };

    useEffect(() => {
        if (editorRef.current) {
            editorInstanceRef.current = SunEditor.create(editorRef.current, {
                className: 'sun-editor ',
                toolbarContainer: '#toolbar_container',
                showPathLabel: false,

                // charCounter: true,
                // charCounterLabel: 'characters',

                width: contentHeight ? 'auto' : '80%',
                maxWidth: '100%',
                height: '100%',
                stickyToolbar: 'true',

                // minHeight: '22rem',
                // maxHeight: '100%',
                buttonList: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    isMobile ? mobileToolBarValue : desktopToolBarValue,
                ],
                plugins: [
                    table,
                    font,
                    fontSize,
                    formatBlock,
                    list,
                    align,
                    video,
                    imageGallery,
                    hiliteColor,
                    fontColor,
                    link,
                    image,
                ],
                font: [
                    'Arial',
                    'Calibri',
                    'Comic Sans MS',
                    'Courier New',
                    'Georgia',
                    'Impact',
                    'Lucida Console',
                    'Palatino Linotype',
                    'Tahoma',
                    'Times New Roman',
                    'Trebuchet MS',
                    'Verdana',
                    // Add more fonts as needed
                ],
                callBackSave: function (contents, isChanged) {
                    console.log(contents);
                },
            });

            // Set initial content
            let initialContent = marked(initial_content);
            if (humanized_content) {
                initialContent = marked(humanized_content);
            } else if (translated_content) {
                initialContent = marked(translated_content);
            }

            editorInstanceRef.current.setContents(initialContent);

            editorInstanceRef.current.onChange = () => {
                handleContentChange(editorInstanceRef.current.getContents());
                onChangeEditorContent(editorInstanceRef.current.getContents());
            };

            if (!hasAccess) editorInstanceRef.current.disable();
            EditorRef.current = editorInstanceRef.current;
        }

        return () => {
            // Cleanup SunEditor instance on component unmount
            if (editorInstanceRef.current) {
                editorInstanceRef.current.destroy();
            }
        };
    }, [initial_content, translated_content, humanized_content, hasAccess]);

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSockets((prevSockets) => ({
            ...prevSockets,
            edit: newSocket,
        }));
        return () => {
            newSocket.close();
        };
    }, []);

    // const scrollEditorDown = () => {
    //     if (editorRef.current) {
    //         editorRef.current.scrollTop = editorRef.current.scrollHeight;
    //     }
    // };

    // useEffect(() => {

    //     if (isTyping ) {
    //         scrollEditorDown();
    //     }
    //   }, [isTyping]);

    const onGenerateMessages = async (message: string, field: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }

        toast.info('Please sit tight, your beautiful content is on its way.');
        setIsTyping((prevState) => ({ ...prevState, [field]: true }));
        setFieldTexts(field);

        const msgs = { role: 'user', content: message };
        // console.log(msgs);

        try {
            sockets['edit']?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',

                            content: message,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    useEffect(() => {
        if (!sockets['edit']) return;
        let tempBuffer = '';

        sockets['edit']?.on('data', (data: string) => {
            tempBuffer += data;
        });

        sockets['edit']?.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;

            console.log(assistant);
            const assistantMessage = assistant[assistant.length - 1].content;
            setLatestRespone(assistantMessage);
        });

        return () => {
            sockets['edit']?.off('message');
        };
    }, [sockets['edit']]);
    useEffect(() => {
        const updateContent = async () => {
            const content = editorInstanceRef.current.getContents();

            console.log('highlightedTexts: ', highlightedTexts);
            console.log('content: ', content);
            console.log('latestResponse: ', latestRespone);

            if (latestRespone && content && highlightedTexts) {
                try {
                    // Replace the highlighted text with the latest response
                    const updatedResponse = content.replace(new RegExp(highlightedTexts, 'i'), latestRespone);
                    const emergencyContent = Response?.replace(new RegExp(highlightedTexts, 'i'), latestRespone);

                    const cleanHTML = DOMPurify.sanitize(updatedResponse);

                    // Update editor content
                    await editorInstanceRef.current.setContents(cleanHTML);

                    const currentContent = editorInstanceRef.current.getContents();
                    if (Response !== updatedResponse) {
                        toast.success('Content updated successfully');
                        // Use return instead of break
                    } else {
                        console.log('Content is the same. No update needed.');
                        if (setResponse) setResponse(cleanHTML); // Set the Response if successful
                        toast.success('Content updated successfully');
                    }

                    // Wait for content update before clearing states
                    setHighlightedTexts('');
                    setLatestRespone('');
                    setIsTyping((prevState) => ({ ...prevState, [fieldTexts]: false }));
                } catch (error) {
                    console.error('Error updating editor content:', error);
                }
            }
        };

        updateContent(); // Call the async function
    }, [latestRespone, fieldTexts]);

    useEffect(() => {
        if (!sockets) return;
        const interval = setInterval(() => {
            // hasAccess &&
            //     UpdateDocument(token, { documentId, data: { title: '', data: editor_content.current } })
            //         .then(({ status }) => {
            //             const data_exist = localStorage.getItem('essay_data');
            //             if (data_exist) {
            //                 localStorage.removeItem('essay_data');
            //             }
            //             status !== 200 && console.log('Update document failed');
            //         })
            //         .catch((e: any) => {
            //             if (e.response) {
            //                 console.error(e.response?.data?.message);
            //             } else {
            //                 console.log(e);
            //             }
            //         });
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [sockets]);

    const [highlightedRange, setHighlightedRange] = useState<Range | null>(null);

    useEffect(() => {
        const handleSelectionChange = () => {
            const selection = document.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0); // Get the highlighted range
                const selectedText = range.toString();

                if (selectedText) {
                    setHighlightedRange(range); // Save the range
                    setHighlightedTexts?.(selectedText); // Save the highlighted text
                    console.log('Highlighted Text:', selectedText);
                }
            }
        };

        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, []);

    const handleRewrite = async () => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to rewrite');
            return;
        }

        if (setIsEditContent) setIsEditContent(true);

        const prompt = `
        Rewrite the following text. Return only the rewritten text. Do not include the original text or any additional comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'rewrite');
    };
    const handleLength = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to rewrite');
            return;
        }

        const prompt = `
            ${condition === 'increase' ? 'Increase' : 'Decrease'} the length of the following text. Return only the updated text without any extra comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'length');
    };

    const handleChangeTone = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to change the tone');
            return;
        }

        if (setIsEditContent) setIsEditContent(true);

        const prompt = `
            Change the tone of the following text to be more ${condition}. Return only the updated text without any extra comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'tone');
    };

    const handleImproveQuality = async (condition: string) => {
        if (!highlightedTexts) {
            toast.error('Highlight text in the editor to improve the quality');
            return;
        }

        if (setIsEditContent) setIsEditContent(true);

        const prompt = `
            Improve the quality of the following text to make it more ${condition}. Return only the improved text without any additional comments:\n\n${highlightedTexts}
        `;

        await onGenerateMessages(prompt.trim(), 'quality');
    };

    useEffect(() => {
        const updateButtonPosition = () => {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const rect = range.getBoundingClientRect();

                if (rect.width > 0 && rect.height > 0) {
                    setPosition({ x: isContent ? 150 : rect.left + window.scrollX, y: rect.top + window.scrollY });
                }
            }
        };

        const handleMouseDown = updateButtonPosition;

        document.addEventListener('mouseup', updateButtonPosition);
        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mouseup', updateButtonPosition);
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div className="relative  ">
            <div
                className={` relative  ${contentHeight ? 'bg-white h-screen shadow-lg shadow-orange-500 overflow-auto' : 'h-screen bg-white shadow-lg shadow-orange-500 flex flex-row justify-center  overflow-auto'}`}
                ref={editorContainerRef}
            >
                <div id="row"></div>
                <div ref={editorRef} id={`suneditor_${Math.random().toString(36).substring(7)}`}></div>
                {/*             
            <Draggable handle='.drag-handle' onDrag={handleDrag} defaultPosition={{ x: 0, y: 0 }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        zIndex: 9999,
                    }}
                    className="drag-handle"
                >
                  
                        <button className="h-8 w-8 bg-transparent" onClick={()=> console.log('click')}  style={{ pointerEvents: 'auto' }} >
                            <img src={darkLogo} alt="gmind" />
                        </button>
                   
                </div>
            </Draggable> */}
            </div>

            <DraggableButton
                isTyping={isTyping}
                position={position}
                handleChangeTone={handleChangeTone}
                handleImproveQuality={handleImproveQuality}
                handleRewrite={handleRewrite}
                handleLength={handleLength}
            />
        </div>
    );
};
export default NewSunEditorComponent;
