/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { newGenerationHomeDataProps } from '../interface';
import BackButton from '@src/components/BackButton';
import { newGenerationHomeData } from '../constants';
import chevronRight from '@icons/chevron-right.svg';
import WorkSheetGenerator from '../sub_pages/worksheet_generator';
import ClassLetter from '../sub_pages/campaign_ad';

import Report from '../../media_resources/sub_pages/blog';
import { useNavigate, useOutletContext } from 'react-router';
import HooksGenerator from '../sub_pages/job_description';
import ExitTicketGenerator from '../../media_resources/sub_pages/new_writer';
import CampaignAdGeneratorForm from '../sub_pages/campaign_ad';
import CopywritingPlanTemplate from '../sub_pages/copy_writing';
import PolicyWriter from '../sub_pages/policy';
import JobDescriptionWriter from '../sub_pages/job_description';
import NewsWriter from '../../media_resources/sub_pages/new_writer';
import Transcriber from '../../transcriber';
import ProjectTemplateForm from '../sub_pages/project';
import './global.scss';
import SocialMediaContent from '../../generate/sub_pages/social_media';
import TextTranslator from '../sub_pages/translator';
import TextSummarizer from '../../school_letters copy/sub_pages/text_summarizer';
import CreateChart from '../../data_visualizer/sub_pages/create_chart_analysis';
import { ThemeContext } from '@src/theme_provider/theme';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';

interface BusinessResourcesProps {
    setShowSidebar: (e: boolean | null) => void;
    onGenerate: (e: string) => void;
    setPageName: (e: string) => void;
    setTopic: (e: string) => void;
    setPageNumber: (e: number) => void;
    currentIndex?: number;
}

const NewGenerateLetter = ({
    setShowSidebar,
    onGenerate,
    setPageName,
    currentIndex = 0,
    setTopic,
    setPageNumber,
}: BusinessResourcesProps) => {
    const [currentPage, setCurrentPage] = useState<number>(currentIndex);
    const [contentOptions, setContentOptions] = useState<newGenerationHomeDataProps[]>(newGenerationHomeData);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const { savedTheme, toggleTheme } = useContext(ThemeContext);
    const { subPage, setSubPage,showHistoryModal,setResponse } = useOutletContext<any>();

    useEffect(() => {
        if (user.plan && user.plan?.restrictions) {
            const canUseLetter = user.plan.restrictions.letterWriter;
            const canUseArticleWritter = user.plan.restrictions.articleWriter;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else if (user.freeTrialAvailable) {
            const canUseLetter = true;
            const canUseArticleWritter = true;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else {
            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer') return false;
                if (e.label === 'article') return false;

                return true;
            });

            setContentOptions(contentOptions);
        }
    }, [window.location.pathname]);

    useEffect(()=>{
        if(currentPage === 0){setSubPage('')}
    },[subPage])

    const setSubPageName = (index: number) => {
        switch (index) {
            case 0:
                setSubPage('');
                break;
            case 1:
                setSubPage('Copywriting Tool');
                break;
            case 2:
                setSubPage('Campaign Ad Generator');
                break;
            case 3:
                setSubPage('Policy Writer');
                break;
            case 4:
                setSubPage('Job Description Writer');
                break;
            case 5:
                setSubPage('News Writer');
                break;
            case 6:
                setSubPage('Transcriber');
                break;
            case 7:
                setSubPage('Social Media Content');
                break;
            case 8:
                setSubPage('Text Translator');
                break;
            case 9:
                setSubPage('Text Summarizer');
                break;
            case 10:
                setSubPage('Data Visualizer');
                break;
            default:
                console.log('Invalid page');
                setSubPage('');
        }
        console.log('SubPage', subPage);
    };
    

    return (
        <div
            className={`w-full flex flex-col h-auto md:h-screen hover:overflow-y-scroll  ${currentPage > 0 ? `${currentPage === 6 ? 'p-0':'pt-5 pb-20 px-[2rem]'}` : 'pt-7 pb-2 px-[2rem]'}  gap-y-2`}
        >
            {currentPage > 0 && currentPage != 10 && currentPage != 6 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(null);
                        setPageName('');
                        setSubPage("");
                    }}
                />
            )}
            {currentPage == 1 && <CopywritingPlanTemplate onGenerateCopy={(e) => onGenerate(e)} setTopic={(e) => {}} />}
            {currentPage == 2 && (
                <CampaignAdGeneratorForm setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />
            )}
            {currentPage == 3 && <PolicyWriter setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />}
            {currentPage == 4 && <JobDescriptionWriter onGenerate={(e) => onGenerate(e)} />}
            {currentPage == 5 && <ProjectTemplateForm onGenerate={(e) => onGenerate(e)} />}
            {currentPage == 6 && <Transcriber currentPage={setCurrentPage} />}
            {currentPage == 7 && <SocialMediaContent setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />}
            {currentPage == 8 && <TextTranslator setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />}
            {currentPage == 9 && <TextSummarizer onGenerate={(e, t) => onGenerate(e)} setTopic={(e) => {}} />}
            {currentPage == 10 && <CreateChart onClickBack={() => {
                setCurrentPage(0)
                setSubPage("");
                }} />}

            {currentPage == 0 && (
                <div className="w-full flex flex-col items-center justify-center">
                    <h2 className="self-start md:self-center font-medium text-[2rem] md:text-[2.3rem] text-[var(--gmind-black)] dark:text-white text-center">
                        Generate Different Business Resources
                    </h2>
                    <p className="mx-0 md:mx-20 self-start md:self-center text-[1.125rem] md:text-[0.8rem] text-[var(--gmind-white60)] mb-2  dark:text-gray-300 text-center">
                        {' '}
                        Explore standardized content tailored to your needs! Click to unlock resources, carefully
                        curated to align with academic standards, tailored to meet your unique needs and goals
                    </p>

                    <div className="flex flex-wrap justify-center items-center mb-0 md:mb-20  gap-x-2 my-3 w-full ">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPageName(item.pageName);
                                    setShowSidebar(true);
                                    setSubPage(item.pageName);
                                    setResponse('');
                                    if (item.naviagatePage) {
                                        navigate(`/dashboard/${item.label}`);
                                        return;
                                    }
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                }}
                                className={`w-full ${showHistoryModal? 'lg:w-[calc(43.33%-8px)]':'lg:w-[calc(33.33%-8px)] md:w-[65%] lg:w-[55%]'}  flex flex-row items-center h-[6.5rem] px-2.5 py-2.5 mb-3 bg-white dark:bg-transparent  dark:shadow-lg dark:hover:shadow-gray-300  shadow shadow-md rounded-lg 
                 cursor-pointer hover:shadow-2xl`}
                                style={{
                                    border: savedTheme==='dark'?'2px solid white':  '1px solid var(--gmind-border-black8)',
                                }}
                            >
                                <div className="w-full flex items-center justify-between gap-x-2">
                                    <div className="flex gap-x-2 items-center">
                                        {item.pageName.toLowerCase() === 'report' ? (
                                            <div className="flex flex-col items-center justify-center w-[3rem] h-[2.5rem] bg-[var(--gmind-black)] dark:bg-transparent rounded-[0.5rem]">
                                                {item.imgElement ?? (
                                                    <img
                                                        className="w-[3rem] md:w-auto h-[3rem]"
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className={`w-auto ${index > 4 ? 'md:w-[10%]' : 'md-w-[30%]'}`}>
                                                {index <= 4 ? (
                                                    <img
                                                        className={`w-[3rem] md:w-full h-[3rem]`}
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                ) : (
                                                    <div
                                                        className={`flex flex-col items-center justify-center w-[3rem] h-[2.5rem] ${index == 5 ? 'bg-[#28C76F20] dark:bg-transparent' : 'bg-[#43B0FF20] dark:bg-transparent'} rounded-[0.5rem]`}
                                                    >
                                                        {item.imgElement ?? (
                                                            <img
                                                                className="w-[3rem] md:w-auto h-[1.5rem]"
                                                                src={item.img}
                                                                alt={item.head}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="flex flex-col gap-y-1 w-auto mx-5">
                                            <h5 className="font-bold text-[0.7rem]  md:text-[0.9rem] text-[var(--gmind-light-black)] dark:text-white">
                                                {item.head}
                                            </h5>
                                            <p className=" font-normal text-[0.6rem]  dark:text-gray-300 md:text-[0.6rem] text-[var(--gmind-white60)] dark:text-white">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    <RightOutlined className="text-[var(--gmind-black)] dark:text-white text-[0.7rem]" />    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewGenerateLetter;
