/* eslint-disable react-hooks/exhaustive-deps */
import right from '@assets/right.svg';
import collapse from '@assets/collapse.svg';
import './style.css';
import React, { useEffect, useState } from 'react';
import type { ChatType, HistoryTypeFormat } from '@src/core/interfaces/chat';

const History = ({
    click,
    data,
    className,
    isMobile,
    collapsed,
    style,
    isHidden,
}: {
    click: (v: string) => void;
    data: HistoryTypeFormat[];
    className?: string;
    isMobile?: boolean;
    collapsed?: () => void;
    onClose?: () => void;
    date?: string;
    style?: React.CSSProperties;
    isHidden?: (b: boolean) => void;
}) => {
    const [hidden, setHidden] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        const listener: any = window.addEventListener('resize', () => {
            console.log(window.innerWidth);
            if (window.innerWidth > 820) {
                setHidden(false);
                isHidden && isHidden(false);
            }
        });
        if (window.innerWidth <= 820) {
            setHidden(true);
            setIsCollapsed(false)
            isHidden && isHidden(true);
        } else {
            setHidden(false);
            isHidden && isHidden(false);
        }

        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [window.innerWidth]);

    return (
        <section
            style={{ width: isCollapsed ? '3vw' : '24vw', ...style }}
            className={
                'h-screen fixed transition-all right-0 z-[400] shadow-md border-s border-gray-200 flex flex-col bg-white' +
                className
            }
        >
            <div className="flex mt-0 items-center justify-between  transition-all px-4 h-16 py-7">
                <span className="flex items-center gap-2">
                    {!isCollapsed && <p className="font-medium dark:text-white text-lg m-0">History</p>}
                </span>
                <button
                    style={
                        isCollapsed
                            ? { position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: 'fit-content' }
                            : {}
                    }
                    className="w-fit"
                    type="button"
                    onClick={() => {}}
                >
                    <img
                        onClick={() => {
                            !isMobile && setIsCollapsed(!isCollapsed);
                            collapsed && isMobile && collapsed();
                        }}
                        role="button"
                        src={collapse}
                        className="w-5 block"
                    />
                </button>
                <span
                    style={isCollapsed ? { opacity: '1' } : { opacity: '0', userSelect: 'none' }}
                    className="-rotate-90 max-lg:text-sm max-lg:top-20 absolute transition-all  top-24 text-lg left-[50%] translate-x-[-50%] text-nowrap"
                >
                    Chat History
                </span>
            </div>

            <div
                className="mt-3 px-3 h-full overflow-auto delay-100 flex flex-col gap-3 transition-all"
                style={hidden ? { scrollbarWidth: 'auto' } : isCollapsed ? { opacity: '0' } : { opacity: '1' }}
            >
                {data?.length && !isCollapsed
                    ? data.map((history: HistoryTypeFormat) => {
                        return <div className="">
                            {
                               history.histories.map((d: ChatType[]) => {
                                    const { content: assistantContent } = d[1];
                                    const { content: userContent } = d[0];
                                    const { id } = d[d.length - 2];
                                    const { date } = d[d.length - 1];
                                    return (
                                        <Card
                                            key={id}
                                            title={userContent}
                                            content={assistantContent}
                                            id={id}
                                            date={date ?? '14:24 12-8-2023'}
                                            onClick={(id: string) => {
                                                click(id);
                                                window.innerWidth <= 768 && setHidden(true);
                                            }}
                                        />
                                    );
                               }) 
                            }
                        </div>
                      })
                    : !isCollapsed && (
                          <p className=" text-gray-400">Start a new conversation to see your histories here.</p>
                      )}
            </div>
        </section>
    );
};

const Card = ({
    title,
    content,
    date,
    id,
    onClick,
}: {
    title: string;
    content: string;
    id: string;
    date: string;
    onClick: (id: string) => void;
}) => {
    return (
        <section
            onClick={() => onClick(id)}
            role="button"
            className="flex history_card items-center gap-2 border border-gray-200 rounded-lg p-2"
        >
            <div className="flex flex-col gap-1">
                <p className="font-medium text-sm">{title?.length > 10 ? title.slice(0, 30) : title}</p>
                <p className="">{content?.length > 30 ? content.slice(0, 30) + '...' : content}</p>
                <p className="text-[0.6rem] text-brandOrange font-medium">{date}</p>
            </div>
            <img src={right} alt="load" className="w-5 ms-auto transition-all history_right" />
        </section>
    );
};

export default History;
