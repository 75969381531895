import { CloseOutlined, DeleteColumnOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { CircularProgress } from '@mui/material';
import { Button, Card, Form, FormInstance, Input, Modal, Radio, Tooltip, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import AIGenerate from '@icons/generate.svg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useState } from 'react';
import '../component/Forms/index.css';
import '../component/Forms/index_dark.css';
import { ThemeContext } from '@src/theme_provider/theme';
import { marked } from 'marked';
import { DeleteIcon, Plus } from 'lucide-react';

interface LoadingState {
    [key: number]: boolean;
}

interface SlideData {
    id: number;
    title: string;
    details: string;
    imageType: 'AI Generated' | 'Manual';
    aiCommand: string;
    uploadedImage?: string;
    previewImage?: string;
    loadingState: boolean;
}
interface FormProps {
    [key: number]: FormInstance;
}
interface PreviewPageProps {
    id: number;
    title: string;
    details: string;
    imageType: 'AI Generated' | 'Manual';
    aiCommand: string;
    uploadedImage?: string;
    previewImage?: string;
    loadingState: boolean;
    handleInputChange: (slidesId: number, field: keyof SlideData, value: string) => void;
    handleGenerateAI: (slidesId: number) => void;
    handleButtonClick: (slidesId: number) => void;
    handleUpload: (slidesId: number, file: File) => void;
    isUploading: boolean;
    handleRemoveImage: (slidesId: number) => void;
    handleRemoveSlide: (slidesId: number) => void;
    handleAddById: (slidesId: number) => void;
    
}

const PreviewPage2 = ({
    id,
    title,
    details,
    imageType,
    aiCommand,
    uploadedImage,
    previewImage,
    loadingState,
    handleInputChange,
    handleGenerateAI,
    handleButtonClick,
    handleUpload,
    isUploading,
    handleRemoveImage,
    handleRemoveSlide,
    handleAddById,
}: PreviewPageProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [editorContent, setEditorContent] = useState<string>('');

    const { savedTheme } = React.useContext(ThemeContext);


    const showModal = () => {
        setEditorContent(details);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = () => {
        handleInputChange(id, 'details', editorContent);
        setIsModalVisible(false);
    };

    const RemoveById = (id: number) => {
        handleRemoveSlide(id);
    }

    const addEmptySlide = (id:number) => {
       handleAddById(id);
    }
    return (
        <div className="h-auto flex flex-col gap-4" id={`slide-${id}`}>
            <div key={id} title={`Slide ${id}`} style={{ width: '100%' }} className="flex flex-col gay-y-2">
                <Form layout="vertical">
                    {/* Slide Title */}
                    <Form.Item label="Slide Title">
                        <Input
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => handleInputChange(id, 'title', e.target.value)}
                        />
                    </Form.Item>

                    {/* Slide Details */}
                    <Form.Item label="Slide Details">
                        <div className="relative">
                            <div>
                                {/* The TextArea for input */}
                                <TextArea
                                    rows={4}
                                    placeholder="Enter details"
                                    value={details}
                                    className={`${savedTheme === 'light' ? 'markdown-body' : 'dark_markdown-body'}`}
                                    onChange={(e) => handleInputChange(id, 'details', e.target.value)}
                                />
{/* 
                                {!loadingState ? (
                                    <div
                                        onClick={() => handleButtonClick(id)}
                                        className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                    >
                                        <img src={AIGenerate} alt="" />
                                        <span>Use Gmind AI</span>
                                    </div>
                                ) : (
                                    <div className="absolute top-[65px] right-[20px] ">
                                        <CircularProgress size={15} className="w-1 h-1" />
                                    </div>
                                )} */}
                            </div>
                            <Tooltip
                                className=" "
                                title="Edit Slide Details"
                                placement="right"
                                overlayInnerStyle={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                }}
                            >
                                <button
                                    onClick={() => {
                                        showModal();
                                    }}
                                    className=" absolute top-[10px] right-[-100px] flex items-center  justify-center ml-6 w-5 h-5  lg:w-10 lg:h-10 rounded-ful bg-transparent  text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md"
                                >
                                    <EditOutlined />
                                </button>
                            </Tooltip>
                            <Tooltip
                                className=" "
                                title="Add new Slide"
                                placement="right"
                                overlayInnerStyle={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                }}
                            >
                                <button
                                    onClick={() => {
                                        addEmptySlide(id);
                                    }}
                                    className=" absolute top-[40px] right-[-100px] flex items-center  justify-center ml-6 w-5 h-5  lg:w-10 lg:h-10 rounded-ful bg-transparent  dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md"
                                >
                                    <Plus />
                                </button>
                            </Tooltip>

                            <Tooltip
                                className=" "
                                title="Delete Slide"
                                placement="right"
                                overlayInnerStyle={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px',
                                }}
                            >
                                <button
                                    onClick={() => {
                                        RemoveById(id);
                                    }}
                                    className=" absolute top-[70px] right-[-100px] flex items-center  justify-center ml-6 w-5 h-5  lg:w-10 lg:h-10 rounded-ful bg-transparent  dark:border-white text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none shadow-md"
                                >
                                    <DeleteIcon />
                                </button>
                            </Tooltip>
                        </div>
                    </Form.Item>

                    {/* Image Type */}
                    <Form.Item label="Select Image Type">
                        <Radio.Group
                            value={imageType}
                            onChange={(e) =>
                                handleInputChange(id, 'imageType', e.target.value as SlideData['imageType'])
                            }
                        >
                            <Radio
                                value="AI Generated"
                                className={`${
                                    imageType === 'AI Generated'
                                        ? 'bg-customOrange text-white rounded-xl border border-blue-500'
                                        : 'border border-white text-white rounded-xl'
                                } py-2 px-4`}
                            >
                                AI Generated
                            </Radio>
                            <Radio
                                value="Manual"
                                className={`${
                                    imageType === 'Manual'
                                        ? 'bg-customOrange text-white rounded-xl border border-blue-500'
                                        : 'border border-white text-white rounded-xl'
                                } py-2 px-4`}
                            >
                                Manual
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* AI Command or Upload */}
                    {imageType === 'AI Generated' ? (
                        <>
                            <Form.Item label={`Enter Command to Generate AI Image for Slide ${id}`}>
                                <Input
                                    placeholder="e.g., A scientific background"
                                    value={aiCommand}
                                    onChange={(e) => handleInputChange(id, 'aiCommand', e.target.value)}
                                />
                            </Form.Item>
                            <Button className="text-white" type="primary" onClick={() => handleGenerateAI(id)}>
                                Generate
                            </Button>
                        </>
                    ) : (
                        <Form.Item label="Upload Image">
                            <Upload
                                accept="image/*"
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    handleUpload(id, file);
                                    return false; // Prevent upload to server
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    )}

                    {/* Preview Image */}
                    {isUploading ? (
                        <div className='bg-[rgba(0, 0, 0, 0.8)] w-20 h-20 mt-4 flex-col gap-y-2 rounded-lg flex justify-center items-center'>
                            <CircularProgress/>
                            <span className='text-white'>Generating</span>
                        </div>
                    ) : (
                        <>
                           {previewImage && (
    <Form.Item label="Preview Image">
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <img
                src={previewImage || uploadedImage}
                alt={`Preview for Slide ${id}`}
                style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
            />
            {/* Remove Button */}
            <button
                onClick={() => handleRemoveImage(id)}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                   
                   
                    border: 'none',
                    borderRadius: '50%',
                    padding: '5px',
                    cursor: 'pointer',
                    fontSize: '16px',
                }}
            >
              <CloseOutlined className=' text-lg bg-customOrange p-3 rounded-full  text-white' />
            </button>
        </div>
    </Form.Item>
)}

                        </>
                    )}
                </Form>
            </div>

            <Modal
                title="Edit Slide Details"
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleSave}
                width="80%" // Make the modal full width
            >
                <ReactQuill
                    value={editorContent}
                    onChange={setEditorContent}
                    theme="snow"
                    style={{ height: '400px' }}
                />
            </Modal>
        </div>
    );
};
export default PreviewPage2;