import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Menu, MenuProps } from 'antd';
import interact from 'interactjs';
import darkLogo from '@assets/essay/gmind_circular.png';
import { MdHighQuality } from 'react-icons/md';
import { SpeakerIcon } from 'lucide-react';
import { LeftOutlined } from '@ant-design/icons';

const DraggableButton = ({
    position,
    handleImproveQuality,
    handleChangeTone,
    handleRewrite,
    isTyping,
    handleLength,
}: {
    position: { x: number; y: number };
    handleImproveQuality?: (condition: string) => void;
    handleChangeTone?: (condition:string) => void;
    handleRewrite?: () => void;
    handleLength?: (condition: string) => void;
    isTyping: {
        [key: string]: boolean;
    };
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        const scalingFactor = 1;

        if (buttonRef.current) {
            const target = buttonRef.current;
            target.style.transform = `translate(${position.x}px, ${position.y}px)`;
            target.dataset.x = String(position.x);
            target.dataset.y = String(position.y);
        }

        interact('.draggable').draggable({
            listeners: {
                start() {
                    setIsDragging(false);
                },
                move(event) {
                    const target = event.target as HTMLElement;
                    const x = (parseFloat(target.dataset.x || '0') || 0) + event.dx * scalingFactor;
                    const y = (parseFloat(target.dataset.y || '0') || 0) + event.dy * scalingFactor;

                    target.style.transform = `translate(${x}px, ${y}px)`;
                    target.dataset.x = String(x);
                    target.dataset.y = String(y);
                    setIsDragging(true);
                },
                end() {
                    setTimeout(() => setIsDragging(false), 0);
                },
            },
        });
    }, [position]);

    // Menus for the dropdowns
    const items: MenuProps['items'] = [
        {
            key: 'tone',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            { key: 'Formal', label: 'Formal', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleChangeTone?.('Formal')} },
                            { key: 'Informative', label: 'Informative', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleChangeTone?.('Informative')} },
                            { key: 'Persuasive', label: 'Persuasive', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleChangeTone?.('Persuasive')}
                             },
                            { key: 'Conversational', label: 'Conversational', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleChangeTone?.('Conversational')}
                             },
                            { key: 'Friendly', label: 'Friendly', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleChangeTone?.('Friendly')} },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"
                >
                    <div className="flex items-center gap-2">
                        {isTyping['tone'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Change Tone</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'length',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            { key: 'Increase', label: 'Increase', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleLength?.('increase') }},
                            { key: 'Decrease', label: 'Decrease', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleLength?.('decrease')} },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"
                  
                >
                    <div className="flex items-center gap-2">
                        {isTyping['length'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Change Length</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'quality',
            label: (
                <Dropdown
                    menu={{
                        items: [
                            { key: 'Comprehensive', label: 'Comprehensive', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleImproveQuality?.('Comprehensive')} },
                            { key: 'Engaging', label: 'Engaging', onClick: (e) => {
                                e.domEvent.stopPropagation();
                                handleImproveQuality?.('Engaging')
                            } },
                        ],
                    }}
                    trigger={['hover']}
                    placement="topLeft"
                  
                    // Adjust the offset to position the dropdown below the button
                    
                >
                    <div className="flex items-center gap-2">
                        {isTyping['quality'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                        <span>Improve Quality</span>
                    </div>
                </Dropdown>
            ),
        },
        {
            key: 'rewrite',
            label: (
                <div
                    className="flex items-center gap-2"
                    onClick={(e) => {
                        e.stopPropagation();

                        handleRewrite && handleRewrite?.();
                    }}
                >
                    {isTyping['rewrite'] ? <EllipsisLoader /> : <LeftOutlined className="h-4 w-4" />}
                    <span>Rewrite</span>
                </div>
            ),
        },
    ];

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <button
                
                className="draggable"
                onClick={(e) => !isDragging && e.stopPropagation()}
                style={{
                    position: 'absolute',
                    border: 'none',
                    cursor: 'pointer',
                    zIndex: 9999,
                    top: position.y,
                    right: position.x,
                }}
            >
                <img className="h-8 w-8" src={darkLogo} alt="gmind" />
            </button>
        </Dropdown>
    );
};

export default DraggableButton;
const EllipsisLoader = () => (
    <div className="flex space-x-1">
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-200"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-400"></div>
    </div>
);
