import cancel from '@assets/cancel.svg';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    GabIcon,
    GabShareButton,
    HatenaIcon,
    HatenaShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    VKIcon,
    VKShareButton,
    ViberIcon,
    ViberShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
} from 'react-share';

export const Share = ({ url, onClose }: { url: string; onClose: () => void }) => {
    return (
        <section className="fixed inset-0 z-[1000] flex items-center justify-center">
            <div className="bg-black bg-opacity-60 z-50 absolute inset-0"></div>
            <div className="h-[40vh] rounded-xl max-sm:w-[90vw] max-sm:h-fit w-[40vw] bg-white z-[1000]">
                <div className="flex items-center p-4 justify-between">
                    <p>Share</p>
                    <img role="button" onClick={() => onClose()} className="w-5" src={cancel} alt="cancel" />
                </div>
                <div className="border p-2 px-4 w-full">
                    <p className="flex items-center gap-2">
                        URL:{' '}
                        <span role="button" className="text-[0.6rem] text-blue-500">
                            {url}
                        </span>{' '}
                    </p>
                </div>
                <div className="flex items-center max-sm:justify-between box-border flex-wrap gap-3 p-4">
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={32} borderRadius={50} />
                    </FacebookShareButton>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={32} borderRadius={50} />
                    </WhatsappShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={32} borderRadius={50} />
                    </TwitterShareButton>
                    <EmailShareButton url={url}>
                        <EmailIcon size={32} borderRadius={50} />
                    </EmailShareButton>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={32} borderRadius={50} />
                    </LinkedinShareButton>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={32} borderRadius={50} />
                    </TwitterShareButton>
                    <FacebookMessengerShareButton appId="0" url={url}>
                        <FacebookMessengerIcon size={32} borderRadius={50} />
                    </FacebookMessengerShareButton>
                    <PinterestShareButton media="" url={url}>
                        <PinterestIcon size={32} borderRadius={50} />
                    </PinterestShareButton>
                    <RedditShareButton url={url}>
                        <RedditIcon size={32} borderRadius={50} />
                    </RedditShareButton>
                    <TelegramShareButton url={url}>
                        <TelegramIcon size={32} borderRadius={50} />
                    </TelegramShareButton>
                    <VKShareButton url={url}>
                        <VKIcon size={32} borderRadius={50} />
                    </VKShareButton>
                    <TumblrShareButton url={url}>
                        <TumblrIcon size={32} borderRadius={50} />
                    </TumblrShareButton>
                    <ViberShareButton url={url}>
                        <ViberIcon size={32} borderRadius={50} />
                    </ViberShareButton>
                    <MailruShareButton url={url}>
                        <MailruIcon size={32} borderRadius={50} />
                    </MailruShareButton>
                    <LineShareButton url={url}>
                        <LineIcon size={32} borderRadius={50} />
                    </LineShareButton>
                    <WeiboShareButton url={url}>
                        <WeiboIcon size={32} borderRadius={50} />
                    </WeiboShareButton>
                    <PocketShareButton url={url}>
                        <PocketIcon size={32} borderRadius={50} />
                    </PocketShareButton>
                    <LivejournalShareButton url={url}>
                        <LivejournalIcon size={32} borderRadius={50} />
                    </LivejournalShareButton>
                    <WorkplaceShareButton url={url}>
                        <WorkplaceIcon size={32} borderRadius={50} />
                    </WorkplaceShareButton>
                    <HatenaShareButton url={url}>
                        <HatenaIcon size={32} borderRadius={50} />
                    </HatenaShareButton>
                    <GabShareButton url={url}>
                        <GabIcon size={32} borderRadius={50} />
                    </GabShareButton>
                </div>
            </div>
        </section>
    );
};
