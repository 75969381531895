import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};


const StatusComponent = () => {
    const navigate = useNavigate();

    const [message, setMessage] = useState<string>('')
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined)
    const query = useQuery();

    useEffect(() => {
        const status = query.get('status') || undefined;
        const statusMessage = query.get('message') || undefined;

        if ( !status) {
            navigate('/');
            return;
        }

        setIsSuccess( status === '1')
        setMessage(statusMessage ?? 'No message');
    }, []);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         navigate('/');
    //       }, 10000);
      
    //       // Cleanup function to clear the timeout if the component unmounts
    //       return () => clearTimeout(timer);
    // }, [navigate]);

    return (
        <div className="mx-0 md:mx-20 my-[15%] md:my-[10%] h-full w-full flex flex-col items-center justify-center">
            <div className="card-success shadow-none md:shadow-md">
                <div className="rounded-[12.5rem] h-[12.5rem] w-[12.5rem] mt-0 mx-auto shadow-md bg-[#F8FAF5]"
                >
                    <i className={`${isSuccess ? 'checkmark text-[#9ABC66]': 'text-[#FF6B6B] cancel'} text-[100px] ml-[30%]`}
                        style={{
                            lineHeight: '200px'
                        }}
                    >{isSuccess ? '✓' : '✗'}</i>
                </div>
                <h1 className="text-[#88B04B] text-center font-extrabold text-[40px] mb-[10px] font-optimistic">{ isSuccess ? 'Successful' : 'Failed' }</h1> 
                <p className="text-[#404F5E] text-[20px] m-0 text-center font-optimistic" >{message}</p>
            </div>
        </div>
    );
}


export default StatusComponent;