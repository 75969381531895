import About from '../components/about';
import wavyDesign from '../../../assets/Wave element.png';
import Playground from '../components/playground/index.tsx';
import Testimonies from '../components/testimonies';
import { Footer } from '../components/footer/footer';
import Faq from '../components/faq';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { landingPagePrps } from '@src/core/interfaces/general.tsx';
import AdvancedFeatures from '../features/components/advanced_features.tsx';
import { useEffect, useRef, useState } from 'react';
import InformationCard from '../components/info.tsx';
import { landingPageStructuredData } from '../strutured_data/structured_data.ts';


const LandingPage = ({ faqRef, featuresRef, isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate();

    const [isMobile, setIsMobile] = useState(true);
    const newFaqRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    return (
        <div className="w-full h-full lg:h-screen bg-gradient-to-b from-[#001F73] to-[#00040D]">
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(landingPageStructuredData)}</script>
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta
                    name="description"
                    content="Boost productivity and streamline your workflow with GMind AI, the ultimate AI-powered productivity tool. Automate daily tasks, optimize workflows, and enhance efficiency with smart automation."
                />

                <title>GMind AI: AI Productivity For Everyone</title>
                <link rel="canonical" href="https://www.gmind.ai/" />
                <meta name="keywords" content="gmind, ai, chat, gmindai, teachers ai" />
            </Helmet>
            <div className="bg-gradient-to-b from-[#001F73] to-[#00040D] h-full xl:h-full">
                {/* <div className=" w-full "> */}

                <div className=" w-full ">
                    {/* <div className='flex flex-col gap-y-5'> */}

                    <div className="w-full">
                        <About isLoggedIn={isLoggedIn} faqRef={faqRef ?? newFaqRef} />
                    </div>

                </div>
                <div className="w-full pt-2 md:hidden">
                    <hr />
                    <p className="text-white font-[Poppins] pb-4 xl:pb-0 text-[0.8rem] md:text-[1rem] mt-4 font-normal  px-10 md:px-20" >Copyright © 2024 Gmind. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
