import React, { useState } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";
import useEndpoint from "@src/pages/acquisition/auth/auth_endpoints";

const ForgotPassword = () => {
  const navigatePage = useNavigate();
  const [ showPassword, setShowPassword ] = useState<boolean>(false)
  const [ passwordVisible, setPasswordVisible ] = useState<boolean>(false);
  const [ confirmPasswordVisible, setConfirmPasswordVisible ] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState(false);
  
  const onFinish = async (values: any) => {
    const email = values.email;
    
    if ( !email ){
      toast.error('Kindly enter your email');
      return;
    }

    if ( !showPassword ){
      await verifyEmail(email);
    }else {
      if ( !otp ){
        toast.error('Kindly enter your otp');
        return;
      }
      const { password, confirm_password } = values;

      if ( !password ){
        toast.error('Kindly enter your new password');
        return;
      }

      if ( password != confirm_password ){
        toast.error('Kindly enter your new password');
        return;
      }

      await updatePassword({
        email,
        otp,
        password
      });
    }
  };

  const verifyEmail = async (email: string) => {
    try {
      let response = await useEndpoint().resetPasswordOTP({email});
      if ( response.statusCode == 1 ) {
        setLoading(false);
        toast.success('Otp sent to your mail');
        setShowPassword(true);
      }else{
        setLoading(false);
        toast.error(response.message || 'Something went wrong' );
      }
      
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message || 'Something went wrong' );
      return;
    }
  }


  const updatePassword = async (_data: { email: string, password: string, otp: string }) => {
    try {
      let response = await useEndpoint().forgotPassword(_data);
      if ( response.statusCode == 1 ) {
        setLoading(false);
        toast.success('Password reset successfully');
        setTimeout(() => {
          navigatePage('/login')
        }, 4000)
      }else{
        setLoading(false);
        toast.error(response.message || 'Something went wrong' );
      }
      
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message || 'Something went wrong' );
      return;
    }
   
  }

  return (
    <div className="px-20 forgot--password">
      <div className="login">
        <div className="login--title text-[1.5rem] md:text-[2.5rem]">Forgot Password</div>

        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name={"email"}
            label={<label className="label"> Email</label>}
            rules={[
              {
                message: "email is required",
                required: false,
              },
            ]}
          >
            <input
              className="px-4"
              style={{
                border: "1px solid #DBDADE",
                borderRadius: "4px",
                backgroundColor: "transparent",
                flex: 1,
                width: "100%",
                height: "40px",
              }}
              type="text"
            />
          </Form.Item>
          
          {
            showPassword && (
              <>
                <Form.Item
                  name={"otp"}
                  label={<label className="label">Otp Sent</label>}
                >
                  <OtpInput
                    value={otp}
                    onChange={(value) => setOtp(value)}
                    numInputs={6}
                    inputStyle={{
                      border: '1px solid #DBDADE',
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      flex: 1,
                      width: '100%',
                      height: '40px',
                  }}
                  renderInput={(props) => <input {...props} className="otp-input" />}
                />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  label={<label className="label">New Password</label>}
                >
                  <div className="flex items-center relative">
                      <input
                          className="px-3"
                          style={{
                              border: '1px solid #DBDADE',
                              borderRadius: '4px',
                              backgroundColor: 'transparent',
                              flex: 1,
                              width: '100%',
                              height: '40px',
                          }}
                          type={passwordVisible ? 'text' : 'password'}
                      />
                      <span
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          className="cursor-pointer absolute right-4"
                      >
                          {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </span>
                  </div>
                </Form.Item>

                <Form.Item
                  name={"confirm_password"}
                  label={<label className="label">Confirm Password</label>}
                >
                  <div className="flex items-center relative">
                      <input
                          className="px-3"
                          style={{
                              border: '1px solid #DBDADE',
                              borderRadius: '4px',
                              backgroundColor: 'transparent',
                              flex: 1,
                              width: '100%',
                              height: '40px',
                          }}
                          type={confirmPasswordVisible ? 'text' : 'password'}
                      />
                      <span
                          onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          className="cursor-pointer absolute right-4"
                      >
                          { confirmPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </span>
                  </div>
                </Form.Item>
              </>
            )
          }

          <button type="submit" className="reset text-white">
            { showPassword ? 'Reset Password' : "Forgot Password" }
          </button>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
