import create from 'zustand';

interface FormState {
    currentStep: number;
    totalSteps: number;nextStep: () => void;
    previousStep: () => void;
    goToStep: (step: number) => void;
}

const useFormStore = create<FormState>((set) => ({
    currentStep: 0,
    totalSteps: 3,
    nextStep: () =>
        set((state) => ({
            currentStep: Math.min(state.currentStep + 1, state.totalSteps - 1),
        })),
    previousStep: () => set((state) => ({ currentStep: Math.max(state.currentStep - 1, 0) })),
    goToStep: (step) => set({ currentStep: step }),
}));

export default useFormStore;
