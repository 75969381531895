const ShareSVG = ({isDark, onClick}: {isDark:boolean; onClick: () => void;}) => {
    return (
        <svg onClick={() => onClick()} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="4.25" cy="8" rx="2.125" ry="2" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="12.75" cy="4" rx="2.125" ry="2" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="12.75" cy="12" rx="2.125" ry="2" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.16406 7.13385L10.8391 4.86719" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.16406 8.86719L10.8391 11.1339" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}


export default ShareSVG;