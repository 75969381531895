import React from 'react';
import PricingLogo from '@assets/icons/GMindAI for School.png';
import { CheckCircle2, } from 'lucide-react';

const RequestPricing = () => {
    const packages = [
        'AI Literacy Training',
        'Dedicated Customer Success Manager',
        'Tailored Professional Learning on AI',
        'Priority Support',
        'Admin Dashboard'
    ];

    return (
        <div className='rounded-[20px] md:w-[800px]  grid grid-cols-1 md:grid-cols-2 p-[25px] bg-[#f6f5f4] border-2 border-gray-100'>
            <div className='text-[20px] font-bold font-Poppins text-black flex flex-col items-start justify-start'>
                
                <p className=' hidden md:block w-[290px] h-[220px]'>
                    <img src={PricingLogo} alt='gmind' />
                </p>
            </div>

            <div className='flex flex-col gap-y-5 mt-3 md:mt-0'>
                <p className='text-[14px] font-bold font-Poppins text-black'>
                    Everything in the GMind Advanced Plan plus more:
                </p>

                <div>
                    {packages.map((item, index) => (
                        <div className='flex flex-row gap-2 pb-4' key={index}>
                            <CheckCircle2 size={20} className="my-auto text-[#00DF76]" />
                            <span className='text-sm font-Poppins'>{item}</span>
                        </div>
                    ))}

                    <p className='w-full'>
                        <button className='  w-full bg-transparent border-[1.5px] border-customOrange px-4 py-[10px] mt-[10px]  rounded-md'>
                            Request Pricing
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RequestPricing;
