import policy_pattern from '@assets/policies/policy_pattern.png';

export const Hero = ({ label, highlighted, link }: { label: string; highlighted?: string; link: string }) => {
    return (
        <section
            style={{ backgroundImage: `url(${policy_pattern})` }}
            className="h-[50vh] bg-cover bg-center w-full bg-no-repeat flex items-center justify-center"
        >
            <div className="flex flex-col items-center gap-8">
                <h1 className="text-3xl text-black">
                    {label} <span className="text-brandOrange">{highlighted}</span>
                </h1>
                <div className="flex items-center gap-2">
                    <a href="/home">Home</a>
                    <span>&gt;</span>
                    <a href={`/${link}`}>
                        {label} {highlighted}
                    </a>
                </div>
            </div>
        </section>
    );
};
