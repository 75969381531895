import { useState } from "react";
import FeaturesImg from '@icons/features_page.svg';
// import img_pic from "@assets/pic.svg";
// import img_pic_grey from "@assets/grey_pic.svg";
// import purseImg from "@assets/purse_img.svg";
// import heart from "@assets/heart.svg";
// import check from "@assets/check.svg";
// import dottedLine from "@assets/dotted_line.svg";
// import dottedLine2 from "@assets/lines_2.svg";
// import userAvatar from "@assets/user_avatar.svg";
// import plus from "@assets/plus.svg";
// import { MyImageIcon, MyThinkIcon, MyGroupIcon, MyGroup2Icon } from "@src/pages/acquisition/components/svg";

type imageCardType = {
    active: boolean,
}

type userCards = {
    name: string
    color: string
}

const ExampleCard = () => {
    const imageCards: imageCardType[] = [
        {
            active: true
        },
        {
            active: false
        },
        {
            active: false
        },
    ];

    const userCards: userCards[] = [
        {
            name: "JK",
            color: "var(--gmind-orange3)"
        },
        {
            name: "HR",
            color: "var(--gmind-green)"
        },
        {
            name: "Li",
            color: "var(--gmind-violet)"
        },
        {
            name: "DP",
            color: "var(--gmind-teal)"
        },

    ]
    
    const [currentCards, setCurrentCard] = useState<imageCardType[]>(imageCards);

    const handleCard = (index: number) => {
        const newCards = [...currentCards];
        newCards.forEach((card, i) => {
            if (i === index) {
                newCards[i].active = true;
            } else {
                newCards[i].active = false;
            }
        });
        setCurrentCard(newCards);
    }
    

    return (
        
        <div className="hidden md:flex md: w-[35%] md: mr-[25px] lg:w-[50%]  gap-x-[18px]">
            <img className='w-full h-full' src={FeaturesImg} alt="features img" />

            {/* <div className="flex flex-col gap-y-5">
              

                <div className="flex flex-col gap-y-5 rounded-[1.3rem] w-[18rem] bg-white px-3 py-5 shadow-md items-center justify-center">
                   
                    <div className="w-full rounded-[0.6rem] flex items-center justify-center ">
                    <MyImageIcon/>
                  
                    </div>
                    
                </div>

                <div className="border border-gray-200 flex items-center justify-between gap-x-3 rounded-[5.1rem] w-full h-[3.75rem] shadow-md bg-white p-3">
                    
                  

                    <div className="w-[100%] flex flex-col gap-y-2">
                        <h2 className="font-normal text-[0.8rem] text-[var(--gmind-light-black)] ">Hi, I’m GMind! Ask me anything</h2>
                   
                    </div>

                    
                    <MyThinkIcon/>
                </div>

            </div>
            

            <div className= "dash--audio--controls lg:block md:hidden">
              <MyGroup2Icon/>
              <MyGroupIcon/>
                
            </div> */}
        </div>

    );
}

export default ExampleCard