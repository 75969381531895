import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import agate from 'react-syntax-highlighter/dist/esm/styles/hljs/agate';
import 'github-markdown-css';
import './index.css';
// import './index_dark.css';
import { ThemeContext } from '@src/theme_provider/theme';
import { EditOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { getUrlPath } from '@src/utils/app_functions';

interface ChatContent {
    text?: string;
}

interface ChatMessageProps {
    id: number; // Added id to uniquely identify the message
    role: string;
    className?: string;
    content: string | ChatContent[];
    sendUpdated?: () => void;
    editedContent: string;
    setEditedContent: Dispatch<SetStateAction<string>>;
}

function isChatContentArray(content: any): content is ChatContent[] {
    return (
        Array.isArray(content) &&
        content.every((item) => typeof item.text === 'string' || typeof item.text === 'undefined')
    );
}

function ChatMessage({ id, role, content, className, sendUpdated, editedContent, setEditedContent }: ChatMessageProps) {
    const [editingMessages, setEditingMessages] = useState<Record<number, boolean>>({}); // Track editing state for each message
    const [isVisible, setIsVisible] = useState<Record<number, boolean>>({});
    const mouseEnter = () => {
        setIsVisible((prevState) => ({
            ...prevState,
            [id]: true,
        }));
    };
    const mouseLeave = () => {
        setIsVisible((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };
    // Track edited content for each message
    const messageContent =
        typeof content === 'string'
            ? content
            : isChatContentArray(content) && content.length > 0
              ? content[0].text || ''
              : '';

    const handleEditClick = () => {
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: true, // Mark this message as being edited
        }));

        setEditedContent(
            messageContent, // Use current content if not already set
        );
        console.log(messageContent);
    };

    const handleCancel = () => {
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: false, // Mark this message as not being edited
        }));

        setEditedContent(messageContent);
        console.log('messageContent');
    };

    const handleSave = () => {
        if (sendUpdated) {
            sendUpdated(); // Pass id and updated content
        }
        setEditingMessages((prevState) => ({
            ...prevState,
            [id]: false, // Mark this message as not being edited
        }));
    };

    return (
        <>
            {messageContent && (
                <div className={`flex flex-row gap-x-2 ${role === 'assistant' ? 'justify-start' : 'justify-end'}`} onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}>
                    {role !== 'assistant' && (
                        <div className="flex items-center justify-center" >
                            {isVisible[id] && (
                                <Tooltip
                                    className="custom-tooltip flex"
                                    title="Edit Chat"
                                    placement="left"
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <button
                                        onClick={handleEditClick}
                                        className="flex items-center justify-center ml-6 w-6 h-6 rounded-full bg-transparent border-[1.5px] border-gray-700 dark:border-white text-gray-700 dark:text-white  focus:outline-none shadow-md"
                                    >
                                        <EditOutlined />
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    <div
                        className={`flex rounded-xl ${role === 'assistant'  ? 'text-left w-full bg-transparent' : ' px-3 text-left max-w-[90%]  dark:bg-[#424242] bg-white'}`}
                        
                    >
                        {editingMessages[id] ? (
                            <div>
                                <Input.TextArea
                                    value={editedContent}
                                    onChange={(e) => setEditedContent?.(e.target.value)}
                                    className="w-full p-2 border rounded dark:text-white"
                                />
                                <div className="flex justify-end gap-2 mt-2">
                                    <button onClick={handleCancel} className="bg-gray-300 px-3 py-1 rounded">
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSave}
                                        className="bg-customOrange text-white px-3 py-1 rounded"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`flex ${role === 'assistant' ? 'justify-start' : 'flex  justify-end'} font_Poppins text-[#333237] p-1 m-0 text-[16px]`}
                            >
                                <ChatMarkDown className={className} messageContent={messageContent} role={role} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default ChatMessage;

export const ChatMarkDown = ({ className, messageContent,role }: { className?: string; messageContent?: string, role?: string }) => {
    const { savedTheme } = useContext(ThemeContext);
    const path = getUrlPath().pagename;

    return (
        <div className={clsx(className, `${savedTheme === 'dark' ? ` mode ${role === 'assistant' ? '':  `${path === 'chat' || path === 'smart_chat' ?'user-response':''}`}` : 'markdown-body'}`, '!font-optimistic')}>
            <ReactMarkdown
                children={messageContent}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    code({ className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return match ? (
                            <SyntaxHighlighter
                                style={agate}
                                language={match[1]}
                                showLineNumbers
                                lineNumberStyle={{ color: '#999' }}
                                PreTag="div"
                                {...props}
                            >
                                {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                }}
            />
        </div>
    );
};
