import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import StatusComponent from './pages/acquisition/components/status';

import NotFoundPage from './pages/404';
import LoginPage from './pages/acquisition/login';
import SignUpPage from './pages/acquisition/signup';
import Layout from './pages/acquisition/layout/Layout';
import Cookies from './pages/acquisition/policies/cookies';
import TermsOfService from './pages/acquisition/policies/tos';
import Privacy from './pages/acquisition/policies/privacy';
import PricingHome from './pages/acquisition/pricing/home/page';
import EmailVerification from './pages/acquisition/verify_mail';
import OtpScreen from './pages/acquisition/verify_mail/otp';
import EditorPage from './pages/dashboard/pages/essay/editor';
import MaintenancePage from './pages/acquisition/maintanance/maintainance';
import ForgotPage from './pages/acquisition/forgot_password';
import Company from './pages/acquisition/company/Company';
import Features2 from './pages/acquisition/features/features2';
import Features3 from './pages/acquisition/features/features3';
import Features4 from './pages/acquisition/features/features4';

// Lazy loaded components
// const NotFoundPage = React.lazy(() => import('./pages/404'));
// const LoginPage = React.lazy(() => import('./pages/acquisition/login'));
// const SignUpPage = React.lazy(() => import('./pages/acquisition/signup'));
// const Layout = React.lazy(() => import('./pages/acquisition/layout/Layout'));
// const Cookies = React.lazy(() => import('./pages/acquisition/policies/cookies'));
// const TermsOfService = React.lazy(() => import('./pages/acquisition/policies/tos'));
// const Privacy = React.lazy(() => import('./pages/acquisition/policies/privacy'));
// const PricingHome = React.lazy(() => import('./pages/acquisition/pricing/home/page'));
// const EmailVerification = React.lazy(() => import('./pages/acquisition/verify_mail'));
// const OtpScreen = React.lazy(() => import('./pages/acquisition/verify_mail/otp'));
// const EditorPage = React.lazy(() => import('./pages/dashboard/pages/essay/editor'));
// const MaintenancePage = React.lazy(() => import('./pages/acquisition/maintanance/maintainance'));


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />}>
            <Route path="/" element={<Layout />} errorElement={<NotFoundPage />} />
            <Route path="login" element={<LoginPage />} errorElement={<NotFoundPage />} />
            <Route path="company" element={<Company isLoggedIn={ localStorage.getItem('token') ? true: false } />} errorElement={<NotFoundPage />} />
            <Route path="forgot_password" element={<ForgotPage />} errorElement={<NotFoundPage />} />
            <Route path="signup" element={<SignUpPage />} errorElement={<NotFoundPage />} />
            <Route path="cookies" element={<Cookies />} errorElement={<NotFoundPage />} />
            <Route path="terms-of-service" element={<TermsOfService />} errorElement={<NotFoundPage />} />
            <Route path="privacy-policy" element={<Privacy />} errorElement={<NotFoundPage />} />
            {/* <Route path="playground" element={<PlaygroundResponse />} errorElement={<NotFoundPage />} /> */}
            <Route path="pricing" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="success" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="cancel" element={<PricingHome />} errorElement={<NotFoundPage />} />
            <Route path="verify_mail" element={<EmailVerification />} errorElement={<NotFoundPage />} />
            <Route path="verify" element={<OtpScreen />} errorElement={<NotFoundPage />} />
            <Route path="features" element={<Layout />} errorElement={<NotFoundPage />} />
            <Route path="creation" element={<Features2  />} errorElement={<NotFoundPage />} />
            <Route path="assistant" element={<Features3 />} errorElement={<NotFoundPage />} />
            <Route path="researchers" element={<Features4 />} errorElement={<NotFoundPage />} />
            <Route path="dashboard/article/editor" element={<EditorPage />} errorElement={<NotFoundPage />} />
            <Route path="status" element={<StatusComponent />} />
        </Route>,

    ),
);

export default router;
