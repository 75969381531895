import { Socket } from "socket.io-client";

const onGenerateFromEditor = (socket: Socket | null, messageSent: string, prompt: string) => {
    let msgs = { role: 'user', content: messageSent };

    try {
        socket?.emit('data', {
            data: {
                messages: [
                    {
                        role: 'system',
                        content: prompt
                    },
                    msgs,
                ],
            },
        });
    } catch (error) {
        console.error(error);
    }
};


export default onGenerateFromEditor;