import React from 'react';
import { Circle, File } from 'lucide-react';
interface UploadRecordProps {
    onUpload: () => void;
    isClicked: boolean;
}

const UploadRecord = ({ onUpload,isClicked }: UploadRecordProps) => {
    return (
        <button
            onClick={onUpload}
            className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors duration-200 border-2  
    ${isClicked ? 'dark:border-customOrange border-customOrange bg-transparent text-white dark:text-customOrange' : 'dark:border-white bg-transparent border-gray-700 text-gray-700 dark:text-white'}
    hover:bg-gray-200 dark:hover:bg-gray-700`}
        >
            <Circle className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} />
            <File className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} />

            <span className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`}>Upload Audio</span>
        </button>
    );
};

export default UploadRecord;
