const Collapse = ( {isDarkMode, className} : {isDarkMode: boolean; className: string;} ) => {
   return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.66675" y="3.66699" width="14.6667" height="14.6667" rx="2" stroke={isDarkMode ? "white" :"black"} strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="3.66675" y="3.66699" width="14.6667" height="14.6667" rx="2" stroke={isDarkMode ? "white" :"black"} stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.25008 3.66699V18.3337" stroke={isDarkMode ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.25008 3.66699V18.3337" stroke={isDarkMode ? "white" :"black"} stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
   );
}

export default Collapse;