export const Card = ({ title, content }: { title: string; content: string | JSX.Element }) => {
    return (
        <div className="bg-[#f9fafa] w-full rounded-2xl p-8 space-y-2">
            <h2 className="text-xl font-semibold">{title}</h2>
            <p className="text-xs">{content}</p>
        </div>
    );
};

export const ContentElement = ({ heading, list }: { heading: string | JSX.Element; list: string[] | JSX.Element[] }) => {
    return (
        <div className="flex flex-col gap-2">
            <p>{heading}</p>
            <ul style={{ listStyleType: 'disc' }} className="list-[disc!important] translate-x-6 max-md:translate-x-0 space-y-1">
                {list.map((list) => (
                    <li>{list}</li>
                ))}
            </ul>
        </div>
    );
};
