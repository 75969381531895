// Define and export the interface for a teaching standard set
export interface TeachingStandardSet {
    name: string;
    description: string;
  }
  

  const teachingStandardSets: TeachingStandardSet[] = [
    {
      name: "Common Core State Standards (CCSS)",
      description: "English Language Arts (ELA) and Mathematics standards for K-12 education in the United States",
    },
    {
      name: "Next Generation Science Standards (NGSS)",
      description: "Science standards for K-12 education in the United States focusing on inquiry-based learning and critical thinking",
    },
    {
      name: "National Council of Teachers of Mathematics (NCTM) Standards",
      description: "Mathematics standards for K-12 education emphasizing problem-solving and reasoning",
    },
    {
      name: "International Society for Technology in Education (ISTE) Standards",
      description: "Technology standards for educators focusing on digital citizenship, collaboration, and innovation",
    },
    {
      name: "National Council of Teachers of English (NCTE) Standards",
      description: "English Language Arts standards for K-12 education emphasizing literacy, communication, and critical thinking",
    },
    {
      name: "National Science Teachers Association (NSTA) Standards",
      description: "Science standards for K-12 education focusing on inquiry-based learning and scientific literacy",
    },
    {
      name: "National Council for the Social Studies (NCSS) Standards",
      description: "Social Studies standards for K-12 education emphasizing critical thinking, civic engagement, and cultural awareness",
    },
    {
      name: "National Art Education Association (NAEA) Standards",
      description: "Visual Arts standards for K-12 education focusing on creativity, critical thinking, and artistic expression",
    },
    {
      name: "National Association for Music Education (NAfME) Standards",
      description: "Music Education standards for K-12 education emphasizing musical literacy, creativity, and performance",
    },
    {
      name: "National Foreign Language Resource Center (NFLRC) Standards",
      description: "Foreign Language Education standards for K-12 education focusing on communication, culture, and linguistic proficiency",
    },
    {
      name: "Advanced Placement (AP)",
      description: "College-level courses and exams for high school students developed by the College Board",
    },
    {
      name: "International Baccalaureate (IB)",
      description: "Comprehensive and rigorous pre-university curriculum leading to an IB diploma emphasizing critical thinking, extracurricular activities, and community service",
    },
    {
      name: "Southern African Development Community (SADC)",
      description: "A regional economic community aiming to promote economic development, peace, and security in southern Africa",
    },
    {
      name: "South African Council for Educators (SACE)",
      description: "A professional council regulating the teaching profession in South Africa promoting teacher development and ethical conduct",
    },
    {
      name: "Teachers Service Commission (TSC)",
      description: "A government agency responsible for managing the teaching profession in Kenya including teacher recruitment, deployment, and professional development",
    },
    {
      name: "Professional Standards for Nigerian Teachers (PSNT)",
      description: "Teaching standards developed by the Teachers Registration Council of Nigeria emphasizing teacher competence, professionalism, and accountability",
    },
  ];
  

  export default teachingStandardSets;