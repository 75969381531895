import { IoIosArrowForward, IoMdMore } from 'react-icons/io';
import Edit from '@src/assets/edit';
import { useEffect, useState } from 'react';
import { GiShipWheel } from 'react-icons/gi';
import { Select } from '@src/pages/dashboard/pages/essay/editor/SubNav';

type ConditionType<T> = {
    translationLoading: T;
    humanizing: T;
};

const TopNavMobile = ({
    onBack,
    title = 'Osmosis In Plants',
    options = [],
    selectedOption,
    showActions,
    selected,
    loading = false,
    canHumanize,
    canTranslate,
    onHumanizeClick,
    humanized = false,
}: {
    onBack: () => void;
    title?: string;
    options?: string[];
    selectedOption?: (v: string) => void;
    showActions?: boolean;
    selected: (v: string) => void;
    canHumanize: boolean;
    canTranslate: boolean;
    loading: boolean;
    onHumanizeClick?: () => void;
    humanized?: boolean;
}) => {
    const [optionOpened, openOption] = useState(false);
    const [conditions, setConditions] = useState<ConditionType<boolean>>({
        translationLoading: false,
        humanizing: false,
    });

    useEffect(() => {
        setConditions((c) => ({ ...c, translationLoading: !loading }));
    }, [loading]);

    useEffect(() => {
        setConditions((c) => ({ ...c, humanizing: !humanized }));
    }, [humanized]);

    function handleLanguage(language: string) {
        selected(language);
        setConditions((c) => ({ ...c, translationLoading: true }));
    }

    return (
        <section className="h-14 relative z-[200] flex items-center justify-between px-3">
            <button className="flex items-center gap-2" type="button" onClick={onBack}>
                <IoIosArrowForward className=" rotate-180 bg-gray-300 rounded-full p-1 text-3xl" />
            </button>
            {
                !canTranslate ? (
                    <div className="flex items-center gap-4">
                        <p className="font-semibold text-xl text-black">{title}</p>
                        <Edit color="#e55109" className="scale-125" />
                    </div>
                ): (
                    <div className="flex items-center gap-3 relative">
                        {showActions && (
                            <>
                                <label htmlFor="translate">Translate</label>
                                <Select
                                    selected={(v) => {
                                        handleLanguage(v);
                                    }}
                                    options={['English', 'Yoruba', 'Igbo', 'Hausa', 'French', 'Spanish', 'Arabic']}
                                />
                                {conditions.translationLoading && (
                                    <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />
                                )}
                            </>
                        )}
                    </div>
                )
            }
            
            <IoMdMore role="button" onClick={() => openOption(!optionOpened)} className="text-2xl" />

            {optionOpened && (
                <ul className="bg-white shadow-md w-32 list-none rounded-md absolute right-3 top-12">
                    {/* {options?.map((option) => {
                        return (
                            <li
                                role="button"
                                onClick={() => {
                                    selectedOption && selectedOption(option);
                                    openOption(false);
                                }}
                                className="p-2 select-none"
                                key={option}
                            >
                                {option}
                            </li>
                        );
                    })} */}
                    <li
                        role="button"
                        onClick={onHumanizeClick}
                        className="p-2 select-none"
                    >
                        {
                            <div className="relative  flex items-center">
                                {showActions && (
                                    <button
                                        className="bg-brandOrange text-sm p-2 py-1 text-white font-medium rounded-md"
                                        type="button"
                                    >
                                        <span>Humanize</span>
                                    </button>
                                )}
                                {conditions.humanizing && <GiShipWheel className=" animate-spin absolute right-[-2rem] text-2xl" />}
                            </div>  
                        }
                    </li>
                </ul>
            )}
        </section>
    );
};

export default TopNavMobile;
