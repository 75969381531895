import minus from '@icons/minus.svg';
import plus from '@icons/plus.svg';
import { getUrlPath } from '@src/utils/app_functions';
import { useState } from 'react';

type AccordionProps = {
    question: string;
    response: string;
};

const Faq = () => {
    const accordionQuestion: Array<AccordionProps> = [
        {
            question: 'What is Gmind.ai?',
            response:
                "GMind AI saves educator's time taking care of the content creation process allowing them concentrate on the core of their work which is teaching their students.",
        },
        {
            question: 'How does Gmind.ai Work?',
            response:
                'GMind.ai uses intelligent task automation and AI-powered tools to optimize workflows and save time. It automates daily tasks, manages projects, and provides AI-driven insights to maximize work performance, making it easier for users to achieve their goals efficiently.',
        },
        {
            question: 'What Services Does Gmind.ai Offer?',
            response:
                'Gmind.ai offers a wide range of AI-based services. This includes a powerful AI language model capable of engaging communication, natural language processing tools for thorough conversation analysis, data analytics solutions for in-depth interpretation and insight generation, and machine learning systems for robust predictive modeling. Additionally, we also offer custom AI solutions tailored to meet your unique business needs and objectives.',
        },
        {
            question: 'How Can Gmind.ai Benefit Users?',
            response:
                'Gmind.ai delivers multiple benefits across various industries. For businesses, it can revolutionize operations, enhance productivity, and improve customer relationship. Through AI-driven analysis, businesses can make data-based decisions, identify pain points, and forecast future trends.\n\nFor individuals, Gmind.ai can be a powerful assistant for day-to-day tasks, academic research, study, content generation, and more. Its language model can simplify communication and ensure understanding even with complex concepts. With continuous use, Gmind.ai grows to understand the user better, leading to more personalized results and experiences.',
        },
        {
            question: 'Is Gmind.ai Safe to Use?',
            response:
                "Absolutely. Gmind.ai has robust security protocols integrated into its systems to ensure the safety and privacy of user data. We respect confidentiality and abide strictly by international standards and legal requirements for data protection. User data is only employed to improve our system's efficiency and effectiveness.",
        },
    ];

    const assistantQuestion: Array<AccordionProps> = [
        {
            question: 'What is GMind AI’s Assistant for Educators?',
            response:
                "GMind AI’s Assistant for Educators is a suite of AI-powered tools that support teachers in lesson planning, curriculum organization, and classroom management, helping them save time and improve teaching efficiency.",
        },
        {
            question: 'How does the Lesson Material Generator work?',
            response:
                'Simply input your topic, grade level, and other preferences, and the Lesson Material Generator creates standards-aligned lesson plans, worksheets, and handouts tailored to your classroom needs.',
        },
        {
            question: 'Can GMind AI tools be used for remote teaching?',
            response:
                'Yes, GMind AI’s tools are perfect for both in-person and remote learning environments, offering resources that are accessible from any device and compatible with virtual classroom setups.',
        },
        {
            question: 'Is GMind AI secure for educational use?',
            response:
                'Absolutely. GMind AI adheres to strict data privacy standards, ensuring that all student and teacher data remains secure and confidential.',
        }
    ];
    const landingQuestion: Array<AccordionProps> = [
        {
            question: 'Is GMIND AI suitable for educational purposes, such as for teachers and students?',
            response:
                "Yes, GMIND AI's platform is ideal for educational environments, providing tools for content generation, learning support, and collaboration to enhance teaching and learning experiences.",
        },
        {
            question: 'Can GMIND AI be used by non-technical users effectively?',
            response:
                'Absolutely, GMIND AI is designed with an intuitive interface that allows non-technical users to easily navigate and utilize features such as AI-powered search, content creation, and multi-linguistic support without requiring advanced technical skills.',
        },
        {
            question: 'How can GMIND AI enhance team collaboration and productivity?',
            response:
                'By allowing teams to invite members via email for task collaboration, GMIND AI facilitates easy project management, editing, and commenting, optimizing team productivity.',
        },
        {
            question: "What are the benefits of GMIND AI's guided and structured templates?",
            response:
                "GMIND AI's guided templates streamline task execution by providing step-by-step assistance, ensuring consistency, clarity, and efficiency in daily operations.",
        },
        {
            question: 'What languages does GMIND AI support for multilingual users?',
            response:
                "GMIND AI offers multi-linguistic capabilities, supporting a diverse range of languages to cater to global users and break down communication barriers.",
        },
        {
            question: 'How does GMIND AI customize responses to fit industry-specific needs?',
            response:
                "GMIND AI leverages domain-specific algorithms and customizable templates to tailor responses and solutions for various industries, ensuring relevance and accuracy.",
        },
        {
            question: 'How does GMIND AI support business professionals in their daily tasks?',
            response:
                "GMIND AI offers business professionals capabilities like data analysis, report generation, and seamless workflow integration to improve efficiency and productivity in various business operations.",
        },
        {
            question: 'How does GMIND AI handle complex analytical tasks?',
            response:
                "GMIND AI utilizes powerful algorithms to process and analyze complex data, providing insightful recommendations and detailed analyses tailored to specific needs.",
        },
    ];

    const handleAccordion = (index: number) => {
        if (currentAccordion === index) {
            setCurrentAccordion(-1);
        } else {
            setCurrentAccordion(index);
        }
    };

    const [currentAccordion, setCurrentAccordion] = useState(1);
    const faqQuestions = getUrlPath().pagename === 'assistant'
    ? assistantQuestion
    : getUrlPath().pagename === ''
    ? landingQuestion
    : accordionQuestion;
    
    return (
        <div id="faq_section" className="w-full px-4 py-8 flex flex-col gap-y-6">
            <h2 className="text-[var(--gmind-black)] font-archivo font-semibold text-2xl md:text-3xl text-center">
                FAQs
            </h2>

            {faqQuestions.map((item, index) => (
                <div
                    className="border-b border-[var(--gmind-playground-grey)]"
                    key={index}
                >
                    <button
                        onClick={() => handleAccordion(index)}
                        className="w-full flex justify-between items-center py-3 px-2 text-left focus:outline-none"
                    >
                        <h3
                            className={`font-medium font-workSans text-sm md:text-base ${currentAccordion === index
                                    ? 'text-[var(--gmind-orange)] scale-105'
                                    : 'text-[var(--gmind-black)] scale-100'
                                } transform transition-transform duration-300`}
                        >
                            {item.question}
                        </h3>
                        <img
                            src={currentAccordion === index ? minus : plus}
                            alt="toggle icon"
                            className="w-4 h-4"
                        />
                    </button>

                    {currentAccordion === index && (
                        <div className="bg-[var(--gmind-playground-grey)] px-3 py-4 border-l-2 border-[var(--gmind-orange)]">
                            <p className="text-[var(--gmind-black)] font-workSans text-sm md:text-base leading-relaxed">
                                {item.response}
                            </p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Faq;
