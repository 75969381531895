import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import logo from '@assets/gmind.png';
import { useNavigate } from 'react-router';
import { planAmountDetails } from '@src/core/interfaces/general';
import { toast } from 'react-toastify';
import { BASE_URL } from '@src/utils/constants';
import { getRegion } from '@src/utils/app_functions';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";


const CompleteProfile = ({isVisible}: {isVisible: boolean}) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);

  const navigate = useNavigate();

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible])

  
  return (
    <div>
      <Modal
        open={isModalVisible}
        onCancel={() => {}}
        closable={false}
        maskClosable={false}
        width={500}
        footer={[
            
                <CustomButton key="ok" variant={'outline'} className='bg-[var(--gmind-orange)] text-white'  onClick={() => navigate('/dashboard/profile') }>
                    Complete Profile
                </CustomButton>
            
        ]}
      >
        <div className='w-full flex flex-col items-center justify-center gap-y-3'>
            <img
                onClick={() => {
                    
                }}
                role="button"
                src={logo}
                className="w-28 h-10"
                alt=""
            />

            <h2 className='font-bold text-center  font-optimistic text-[1.2rem] w-[80%]'>
            Welcome to GMind, the Leading AI Platform for Productivity!

            </h2>

            <p className='text-center w-[80%] leading-relaxed'>
            By joining GMind, you're now part of a diverse community worldwide utilizing GMind AI to enhance productivity in various fields. We offer tools designed to save you time and help you focus on what truly matters to you.
            </p>

            <p className='text-center w-[80%] leading-relaxed'>
            At GMind, we recognize the importance of efficiency and strive to provide resources that streamline your work, allowing you to achieve more with less effort.
            </p>

            <p className='text-center w-[80%] leading-relaxed'>
            Before you get started, please share a little about yourself. This will help us provide relevant recommendations and create the best experience tailored just for you!
            </p>
            <p className='text-center w-[80%] leading-relaxed'>
            Complete your profile today and unlock the full potential of GMind AI!  </p>
        </div>
        
      </Modal>
    </div>
  );
};

export default CompleteProfile;