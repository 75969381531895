/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Checkbox, Form, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import Regenerate from '@assets/repeat.svg';
import Profile from '@assets/user-circle.svg';
import { contentPromptOptionsData2 } from '../constants';
import { openEditor } from '@src/utils/app_functions';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { InputTypeProps } from '../../assessment_2/interface';
import { socialMedias } from '../../curriculum/constants';
import { BASE_URL } from '@src/utils/constants';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import NewContentEditorPage from '../../essay/editor/newInstanceOfEditor copy';
import BackButton from '@src/components/BackButton';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const ContentWriter = ({
    isComprehensive = true,
    isSocial = false,
    response,
    setResponse,
    setCurrentPage,
    setPageNumber,
    setShowSidebar,
    setShowTop,

    setPageName,
    setSubPage,
}: {
    isComprehensive: boolean;
    isSocial?: boolean;
    response: string;
    setResponse: any;
    setCurrentPage: any;
    setPageNumber: any;
    setShowSidebar: any;
    setShowTop: any;
    setPageName: any;
    setSubPage: any;
}) => {
    const [isTyping, setIsTyping] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [description, setDescription] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const pageHistory = 'contents';
    const [generating, setGenerating] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [navigate, setNavigate] = useState(false);
    const [pageId, setPageId] = useState('');
    const [showRefrence, setShowReference] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<Chats>({ role: 'assistant', content: '' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [rememberMe, setRememberMe] = useState(false);
    const Document = useRef<any>();
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<() => void>(() => {});
    const historyCloseId = 'historyClose';
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [editorContent, setEditorContent] = useState<string>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [isEditContent, setIsEditContent] = useState(false);
    // const [response, setResponse] = useState('');
    const [latestRespone, setLatestRespone] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const editorInstanceRef = useRef<any>();

    useEffect(() => {
        if (isTyping && response != '') {
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);

    const naviagtePage = useNavigate();

    const options = contentPromptOptionsData2({
        response,
        showDownload: () => {},
    });

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string, humanize = false) => {
        // console.log(isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,);
        // return;
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');

        setIsTyping(true);
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setResponse('');
        console.log(description);
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: isComprehensive
                                ? prompts.content(description, showRefrence)
                                : prompts.shortContent,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    useEffect(() => {
        console.log(`edit content: ${isEditContent}`);
    }, [isEditContent]);

    useEffect(() => {
        if (response  ) {
            setEditorContent(response);
        }
    }, [response]);


    useEffect(() => {
        if (!socket) return;
        let tempBuffer = '';

        socket.on('data', (data: string) => {
            // setResponse((response: any) => response + data);

            setResponse((response: any) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            // console.log(`-------------------Testing ooo------------------`);

            // const assistantMessage = assistant[assistant.length - 1].content;
            // // setLatestRespone(assistantMessage);
            // setEditorContent(assistantMessage);
            setIsTyping(false);
            toast.success('Content generated successfully');
            setIsEdit(true);

            const uuid = uuidv4();
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            setStreamEndMessage(data.assistant);
            setIsTyping(false);
            setDescription('');
            // if (streamId === StreamId.current) {
                StreamId.current = '';
                socket.emit('get-documents', user?.id);
                setNavigate(true);
                setPageId(uuid);
            // }
        });

        return () => {
            socket.off('message');
        };
    }, [socket, isEditContent]);

    const handleStreamEnd = async (prompt: string, data: Chats) => {
        // let dataToSave: Chats[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;

        let docData = {
            content: response,
            doc_id: pageId,
            promptSent: prompt,
            promptType: promptSent,
        };

        await handleAddHistory(dataToSave, pageHistory, docData);
        if (pageId) fetchDocument(pageId ?? '', true);
    };

    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(prompt, streamEndMessage);
        }
        // const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;
        // if (navigate && response != '') {
        //     openEditor({
        //         response,
        //         pageId,
        //         promptSent: prompt,
        //         promptType: promptSent,
        //         navigate: naviagtePage,
        //         pageSocket: socket,
        //     });
        // }
    }, [response, navigate, isTyping, socket]);

    const onFinish = async () => {
        if (!description) {
            toast.error('Enter your description');
            return;
        }

        let message = `Generate a Content of the topic described with this details: ${description}`;
        setIsTyping(true);
        setPrompt(message);
        await onGenerateHandler(message);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };
    const regenerate = async (humanize = false) => {
        let newPrompt = prompt;
        setPrompt(newPrompt);
        await onGenerateHandler(newPrompt, humanize);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    return (
        <div className="w-full px-0 md:px-5 pt-5  h-screen flex flex-col md:flex-row gap-x-10 gap-y-3">
            <div
            style={{boxShadow:"rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px"}}
                className={`${isMobile ? (!isTyping ? 'flex' : 'hidden') : 'flex'} bg-white  flex-col gap-y-3 h-[50vh] md:h-screen w-full md:w-[40%] xl:w-[30%] rounded-[0.5rem] dark:shadow-none px-10 py-5 shadow-lg`}
            >

                <div className='flex flex-row gap-x-2 items-center justify-start'>
                <button
                    className="bg-gray-300 h-6 w-6 rounded-full p-2 flex flex-row gap-x-2 items-center justify-center "
                    onClick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(true);
                        setShowTop(true);
                        setResponse('');

                        setPageName('');
                        setSubPage('');
                    }}
                >
                    {' '}
                    <LeftOutlined />{' '} 
                </button>
                <span>Back</span>
                </div>
                

                <p className="font-medium  text-[1rem] text-[var(--gmind-light-black)]">Content description</p>
                <Form layout="vertical" className="flex flex-col flex-wrap gap-x-5" onFinish={onFinish}>
                    <Form.Item
                        className="w-full mb-1"
                        name={'description'}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full text-black placeholder-gray-700 dark:border border-gray-500 "
                            placeholder="E.g What is Osmosis E.g Rewrite in short sentence E.g Generate a resume with 5 years experience."
                            style={{
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            rows={isMobile ? 4 : 8}
                        />
                    </Form.Item>

                    {isComprehensive && !isSocial && (
                        <div className="w-full items-center justify-between flex gap-x-2 my-3">
                            <p className="font-medium dark:text-black text-[0.75rem]">Show References</p>

                            <div className="flex items-center gap-x-2">
                                {/* <p className="font-medium text-[0.75rem]">Yes / No </p> */}
                                <Checkbox
                                    value={showRefrence ? true : false}
                                    onChange={(e) => setShowReference(!showRefrence)}
                                    className=""
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                    type="checkbox"
                                />

                                {/* <p className="font-medium text-[0.75rem]">No</p>
                            <Checkbox
                                value={!showRefrence ? true: false}
                                onChange={(e) => setShowReference(e.target.value)}
                                className='' 
                                style={{
                                    border: '1px solid #E5E8F1',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="checkbox"
                            />   */}
                            </div>
                        </div>
                    )}

                    {/* <Form.Item
                        className='w-full flex flex-row gap-x-4 mb-0'
                        name={'description'}
                        label={
                            <label className="w-[30%]">Show references</label>
                        }
                    >
                        
                    </Form.Item> */}

                    <button disabled={isTyping} type="submit" className="my-5 text-white transcribe">
                        Generate
                    </button>
                </Form>
            </div>
            <div className="w-full xl:w-[70%] md:w-auto h-screen flex flex-col gap-y-3 " style={{boxShadow:"rgba(229, 81, 9, 0.6) 0px 19px 38px, rgba(255, 20, 147, 0.5) 0px 15px 12px"}}>
                {/* {
                        !generate ? (
                            <div className="mt-5 h-full flex-col flex items-center justify-between">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-center font-medium text-[1.5rem] dark:text-white text-[var(--gmind-black)]">Create Content</p>
                                    <p className="text-center text-gray-400 font-normal">Generate content for course</p>
                                </div>

                                <p className="mb-10 text-center text-[1rem] text-gray-400 font-normal">Enter content description & click Generate to get started</p>
                            </div>
                        ):(
                            <div>
                                {generating ? 
                                    (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <MarkdownDesign className="dark:!text-white">{response}</MarkdownDesign>
                                    )
                                }

                            </div>
                        )
                    } */}

                <NewEditorPage
                    regenerate={regenerate}
                    isBack={true}

                    content={<NewSunEditorComponent
                        isContent={true}
                        editorInstanceRef={editorInstanceRef}
                        // humanizeded_content={Document.current?.data.ops}
                        // hasAccess={hasAccess}
                        hasAccess={(documentResponse?.role ?? '') !== 'view' ? true : false && !isTyping}
                        initial_content={response}
                        onChangeEditorContent={(e: string) => {
                            setEditorContent(e);

                            const newtext = e;
                            // // console.log('newtext', newtext);
                            // console.log('docId', docId);
                            // console.log('userDetails?.userId', userDetails?.userId);
                            // sendChanges(docId, userDetails?.userId, e);
                        } }
                        contentHeight={true}
                        // setHighlightedTexts={setHighlightedTexts}
                        // highlightedTexts={highlightedTexts}
                        Response={response}
                        setResponse={setResponse}
                        setIsEditContent={(e) => {
                            setIsEditContent(e);
                        } }
                        contentIsTyping={isTyping}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent} />}
                    showTopNavBar={false}
                    showInvite={documentResponse?.owner ?? false}
                    isTyping={isTyping}
                    Profile={Profile}
                    setGenerate={setGenerate}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc} editorInstanceRef={editorInstanceRef}                />

                {/* <div id="controls" className="w-full flex items-center justify-between">
                    <div className="flex gap-x-5 w-[50%] justify-start">
                        <div role="button" onClick={() => regenerate(true)} className="flex items-center">
                            <img src={Profile} alt="" />
                            <p>Humanize</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={option.onClick}
                                className="rounded-[1rem] px-[1rem] py-[0.3rem] bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                            >
                                <img className='w-[1rem] h-[1rem]' src={option.icon} alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-x-5 w-[50%] justify-end">
                        <div
                            className="hidden md:flex items-center flex-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => regenerate()}
                        >
                            <img src={Regenerate} alt="" />{' '}
                            <span
                                className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                style={{ marginLeft: '2px' }}
                            >
                                Regenerate
                            </span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" />
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Like} alt="" />
                        <img
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={()=> {}}
                            src={DisLike}
                            alt=""
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ContentWriter;
