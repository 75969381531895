import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';

export const GetEssayHistory = async (token) => {
    const response = await axios.get(`${BASE_URL}/gmind/essay`, {
        headers: {
            authorization: `Bearer ${token}`,
        },
    });

    const { data: d } = response;
    return d;
};

export const SaveEssayHistory = async (token, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/gmind/essay`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const GetDocumentCollaborators = async (token, documentId) => {
    try {
        const base_url = BASE_URL.slice(0, -3);
        const response = await axios.get(`${base_url}/essay/collaborators?documentId=${documentId}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const GetDocument = async (token, documentId) => {
    try {
        const response = await axios.get(`${BASE_URL}/gmind/essay/document?documentId=${documentId}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const CreateDocument = async (token, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/document`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d, status } = response;
        return { d, status };
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const UpdateDocument = async (token, data) => {
    try {
        const response = await axios.put(`${BASE_URL}/document`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d, status } = response;
        return { d, status };
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const AddCollaborator = async (token, data) => {
    try {
        const base_url = BASE_URL.slice(0, -3);
        const response = await axios.put(`${base_url}/essay/collaborators`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const UpdateCollaborator = async (token, data) => {
    try {
        const base_url = BASE_URL.slice(0, -3);
        const response = await axios.put(`${base_url}/essay/collaborator/access`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d, status } = response;
        return { d, status };
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const GetAllComments = async (token, documentId) => {
    try {
        const response = await axios.get(`${BASE_URL}/gmind/essay/comments?documentId=${documentId}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const AddComment = async (token, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/gmind/essay/comments`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const ResendInvite = async (token, data) => {
    try {
        const base_url = BASE_URL.slice(0, -3);

        const response = await axios.post(`${base_url}/resendEmail`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const GetVersionHistories = async (token, documentId) => {
    try {
        const response = await axios.get(`${BASE_URL}/gmind/essay/version?documentId=${documentId}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};

export const SetVersionHistory = async (token, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/gmind/essay/version`, data, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });
        const { data: d } = response;
        return d;
    } catch (error) {
        return { statusCode: 0, message: error };
    }
};
