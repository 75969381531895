import React from 'react';
import googleLogo from '@assets/essay/Logo-google-icon-PNG.webp';

interface GoogleProps {
    handleSignIn: () => void;
}

const GoogleSignInComponents = ({ handleSignIn }: GoogleProps) => {
    return (
        <div className="flex flex-col gap-y-4 mt-4">
            <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-grow border-t border-gray-300" />
                <span className="text-gray-500 text-sm">or</span>
                <hr className="flex-grow border-t border-gray-300" />
            </div>
            <div className='flex flex justify-center'> 
                <button
                    type='button'
                    onClick={handleSignIn}
                    className=" bg-transparent border border-gray-500 rounded p-3 flex flex-row gap-x-2 items-center justify-center"
                 >
                <img className="h-4 w-4" src={googleLogo} alt="google" loading="lazy" />
                <span> Sign In with Google</span>
            </button></div>
           
        </div>
    );
};

export default GoogleSignInComponents;
