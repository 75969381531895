import { landingPagePrps } from '@src/core/interfaces/general';
import React, { useState } from 'react';
import useEndpoint from '../auth/auth_endpoints';
import { toast } from 'react-toastify';
import { getDeviceId } from '@src/utils/app_functions';
import { GetStartedButton, GetStartedForFree } from '@src/components/GetStartedButton';
import { useNavigate } from 'react-router';
import hero from '@assets/hero-researcher.svg'
import bottom from "../../../assets/bottom-left.png"
import top from "../../../assets/top-right.png"
import content from "../../../assets/features/content.svg"
import syllabus from "../../../assets/features/syllabus.svg"
import generator from "../../../assets/features/generator.svg"
import planner from "../../../assets/features/lesson-plan.svg"
import standard from "../../../assets/features/standard.svg"
import grading from "../../../assets/features/grading.svg"
import slides from "../../../assets/features/slides.svg"
import attention from "../../../assets/features/attention.svg"
import syllabus_phone from "../../../assets/features/syllabus-phone.svg"
import Footer from '../components/footer';
import Faq from '../components/faq';
import { Helmet } from 'react-helmet-async';
import { assistantData } from '../strutured_data/structured_data';
import RequestDemoModal from '../components/request_demo_modal';


const Features3 = ({ isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate()
    const [showRequestModal, setShowRequestModal] = useState(false);


    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                // const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = '/dashboard/chat';
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }
    return (
        <div>
            <RequestDemoModal isVisible={showRequestModal} onCancel={() => setShowRequestModal(false)} />

            <div className="w-full md:min-h-screen flex flex-col items-center justify-center bg-[#000A26] text-white relative z-20">
                {/* hero content */}
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify(assistantData)}</script>
                    <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                    <meta
                        name="description"
                        content="Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored TO your needs."     
                    />
                    <meta
                        name="title"
                        content="AI Tools And Features | GMind AI"     
                    />
                    <title>Assistant</title>
                    <link rel="canonical" href="https://gmind.ai/assistant" />
                    <meta name="keywords" content="gmind, ai, chat" />
                </Helmet>
                <div className="text-center px-6 md:px-12 mt-24">
                    <h2 className="text-3xl md:text-6xl font-medium md:max-w-4xl">
                        <span className="text-white font-archivo">Transform Your Teaching<br />
                            With Built-in{' '}
                            <span className="text-[#E65109] font-archivo">Gmind AI</span>
                            {' '} Powered Assistant
                        </span>
                    </h2>
                    <p className="mt-6 md:mt-8 text-sm max-w-2xl mx-auto font-poppins">
                        Empower your teaching with GMind AI, the all-in-one platform designed to save educators time and streamline curriculum planning, lesson creation, and classroom management. Say goodbye to repetitive tasks and focus on what truly matters—your students.
                    </p>
                    <div className="mt-8 md:mt-14 flex flex-col sm:flex-row justify-center gap-4">
                        <GetStartedButton
                            isLoggedIn={isLoggedIn}
                            className="px-4 py-2 border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                            onclick={() => {
                                if ( isLoggedIn ){
                                    navigatePage('/dashboard/chat')
                                    return;   
                                }
                                handleGuestLogin()
                            }}
                        />
                        <a
                            // onClick={() => navigatePage('/features?showSection=1')}
                            onClick={() => {
                                setShowRequestModal(true);
                                // const email = "support@gmind.ai ";
                                // const subject = "Request Demo for Gmind AI";
                                // const body = "Hi,\n\nI am interested in scheduling a demo for Gmind AI. Please let me know the available dates and times.\n\nThank you.";
                              
                                // window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                            }}
                            className="cursor-pointer w-full sm:w-auto flex justify-center md:items-center px-4 py-3 text-center border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                        >
                            {/* Watch a demo */}
                            Request Demo
                        </a>
                    </div>
                </div>
                {/* hero image */}
                <div className="mt-12 flex justify-center">
                    <img src={hero} alt="hero-image" className="w-full max-w-3xl shadow-md" />
                </div>
            </div>
            <section className="w-full bg-white py-16 px-6 md:px-24 relative">
                {/* Top-right Image */}
                <img
                    src={top}
                    alt="Top decorative"
                    className="absolute -top-40 right-0 w-24 sm:w-32 md:w-48 lg:w-64"
                />

                {/* Our Story Section */}
                <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                    {/* Text Content */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <p className="text-[#111111] text-2xl sm:text-3xl md:text-5xl font-medium mt-4 md:leading-[70px] tracking-wide font-jakarta md:max-w-lg">
                            Why Choose GMind AI for Education?
                        </p>
                        <p className="text-[#7E8492] text-sm sm:text-base leading-relaxed mt-4 text-justify font-jakarta ">
                            Our suite of AI-powered tools is designed specifically for teachers, allowing them to spend less time on routine tasks and more time enriching students’ lives. Join thousands of educators who trust GMind AI to enhance their teaching workflows and outcomes.
                        </p>
                    </div>

                    {/* Main Image */}
                    <div className="w-full md:w-2/5 flex justify-center">
                        <img
                            src={content}
                            alt="Story image"
                            className="w-full object-cover rounded-lg"
                        />
                    </div>
                </div>
                {/* Bottom-left Image */}
                <img
                    src={bottom}
                    alt="Bottom decorative"
                    className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                />
            </section>
            <section className="w-full bg-white py-16 px-6 md:px-24 border-t border-[#E8E8E8]">
                {/* Centered Header Section */}
                <div className="flex flex-col items-center text-center mb-12">
                    <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full">
                        GMind AI Tools
                    </span>
                    <h4 className="text-2xl sm:text-3xl md:text-4xl font-semibold font-archivo mt-4 md:max-w-lg">
                        Key Features for AI Powered Assistant for Educators
                    </h4>
                    <p className="text-sm sm:text-base text-[#7E8492] mt-2">
                        Essential Al-powered assistant tools to enhance educator efficiency
                    </p>
                </div>

                {/* Features 1 Section */}
                <div className="flex flex-wrap md:flex-nowrap gap-8 mb-8">
                    {/* First Feature */}
                    <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                        <h5 className="text-lg sm:text-xl md:text-6xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                            Curriculum Planner
                        </h5>
                        <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                            GMind AI Curriculum Planner simplifies lesson planning with an intuitive interface, organizing objectives, schedules, and resources for seamless education management.
                        </p>
                    </div>

                    {/* Second Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={syllabus}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Time-Saving Layout
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Build entire courses with pre-made templates.
                            </p>
                        </div>
                    </div>

                    {/* Third Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={generator}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Easily Customizable
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Adjust plans based on class needs and individual learning goals.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Features 2 section */}
                <div className="flex flex-wrap md:flex-nowrap gap-8 flex-row-reverse mb-8 ">
                    {/* First Feature */}
                    <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                        <h5 className="text-lg sm:text-xl md:text-5xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                            Lesson Material Generator
                        </h5>
                        <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                            Save time with GMind AI’s Lesson Material Generator, crafting engaging, tailored materials that meet standards and student needs.
                        </p>
                    </div>

                    {/* Second Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={planner}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Ready-to-Use
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Create comprehensive lesson plans with minimal effort.
                            </p>
                        </div>
                    </div>


                    {/* Third Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>
                            <img
                                src={standard}
                                alt="writing"
                                className="w-full object-cover rounded-lg "
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Standards-Aligned
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Ensure every plan meets your local and national academic standards.

                            </p>
                        </div>
                    </div>
                </div>

                {/* Features 3 Section  */}
                <div className="flex flex-wrap md:flex-nowrap gap-8 mb-8">
                    {/* First Feature */}
                    <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                        <h5 className="text-lg sm:text-xl md:text-6xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                            Classroom Management
                        </h5>
                        <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                            GMind AI’s Classroom Management Tools streamline tasks like grading, attendance, and data organization, freeing time for student engagement.
                        </p>
                    </div>

                    {/* Second Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={grading}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Automated Grading
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Quickly grade assignments and exams with accuracy.
                            </p>
                        </div>
                    </div>

                    {/* Third Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={slides}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Slide Maker
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Easily record attendance and identify patterns.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-16 flex justify-center">
                    <img src={attention} alt="hero-image" className="w-full max-w-3xl shadow-md rounded-2xl" />
                </div>
                <div className="flex justify-center">
                    <h4 className="text-2xl sm:text-3xl font-medium font-archivo mt-4 md:max-w-lg text-center md:leading-[44px]">
                        Experience a New Level of Classroom Efficiency with GMind AI.
                    </h4>
                </div>
            </section>
            <section className="w-full bg-white py-16 px-6 md:px-24  overflow-hidden">
                {/* Content Section */}
                <div className="flex flex-col md:flex-row items-center gap-8">
                    {/* Left - Image */}
                    <div className="flex-1">
                        <img
                            src={syllabus_phone}
                            alt="Mobile"
                            className="w-full h-auto object-contain"
                        />
                    </div>

                    {/* Right - Text Content */}
                    <div className="flex-1">
                        <h4 className="text-2xl sm:text-3xl md:text-5xl font-medium font-archivo text-[#111111] mb-6 md:leading-[60px] tracking-wider">
                            How GMind AI Benefits Educators

                        </h4>

                        {/* Features List */}
                        <div className="">
                            {/* Feature 1 */}
                            <div className="flex items-start gap-4">
                                {/* Icon and Line */}
                                <div className="flex flex-col items-center">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mb-2"
                                    >
                                        <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                    </svg>
                                    <svg
                                        width="3"
                                        height="125"
                                        viewBox="0 0 3 125"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="1.5"
                                            y1="125"
                                            x2="1.5"
                                            y2="0"
                                            stroke="#24252A"
                                            strokeWidth="2"
                                            strokeDasharray="6 6"
                                        />
                                    </svg>
                                </div>

                                {/* Text Content */}
                                <div>
                                    <h5 className="text-xl font-medium text-[#24252A] mb-2 font-archivo md:text-2xl">
                                        Enhanced Productivity
                                    </h5>
                                    <p className="text-sm text-[#7E8492] font-workSans" >
                                        Free up time to focus on interactive teaching by reducing administrative burdens.
                                    </p>
                                </div>
                            </div>

                            {/* Feature 2 */}
                            <div className="flex items-start gap-4">
                                {/* Icon and Line */}
                                <div className="flex flex-col items-center">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mb-2"
                                    >
                                        <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                    </svg>
                                    <svg
                                        width="3"
                                        height="125"
                                        viewBox="0 0 3 125"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="1.5"
                                            y1="125"
                                            x2="1.5"
                                            y2="0"
                                            stroke="#24252A"
                                            strokeWidth="2"
                                            strokeDasharray="6 6"
                                        />
                                    </svg>
                                </div>

                                {/* Text Content */}
                                <div>
                                    <h5 className="text-xl font-semibold text-[#24252A] mb-2">
                                        Customized Learning Experiences
                                    </h5>
                                    <p className="text-sm text-[#7E8492] font-workSans">
                                        GMind AI adapts to unique student needs, helping educators personalize learning.
                                    </p>
                                </div>
                            </div>
                            {/* Feature 3 */}
                            <div className="flex items-start gap-4">
                                {/* Icon */}
                                <div className="flex flex-col items-center">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                    </svg>
                                </div>

                                {/* Text Content */}
                                <div>
                                    <h5 className="text-xl font-semibold text-[#24252A] mb-2">
                                        Better Student Outcomes
                                    </h5>
                                    <p className="text-sm text-[#7E8492] font-workSans">
                                        With streamlined planning and classroom management, teachers can create more meaningful educational experiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#F9F7FF] py-16 px-6 flex flex-col items-center text-center">
                {/* Badge */}
                <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full mb-4 font-archivo">
                    What you get
                </span>

                {/* Main Heading */}
                <h4 className="text-2xl sm:text-5xl font-medium text-[#24252A] mb-4 font-archivo md:max-w-2xl md:leading-[70px] md:px-8">
                    Ready to Transform Your Classroom?
                </h4>

                {/* Description */}
                <p className="text-sm text-[#7E8492] mb-8 max-w-2xl mx-auto font-workSans">
                    Unlock a more efficient, engaging teaching experience with GMind AI. From lesson planning to student management, our AI-powered tools are here to support you.
                </p>

                {/* Buttons */}
                <div className="flex flex-wrap justify-center gap-4">
                    {/* Try GMind AI Free Button */}
                    <GetStartedForFree
                        isLoggedIn={isLoggedIn}
                        className="px-4 py-2  text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                        onclick={() => {
                            handleGuestLogin()
                        }}
                    />
                    {/* Explore Pricing Button */}
                    <a
                        onClick={() => navigatePage(isLoggedIn ? '/dashboard/pricing' : '/pricing')}
                        className="cursor-pointer flex items-center gap-2 px-6 py-2 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300"
                    >
                        <span>Explore Pricing</span>
                        <span>
                            <svg
                                width="12"
                                height="10"
                                viewBox="0 0 12 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                    fill="#24252A"
                                />
                            </svg>
                        </span>
                    </a>
                </div>
            </section>
            <section className="w-full mt-5 md:mt-0 px-10 md:px-24 bg-white">
                <Faq/>
            </section>
            <Footer />
        </div>
    );
}

export default Features3;
