import { BASE_URL } from "@src/utils/constants";
import { toast } from "react-toastify";

export async function googleSignIn() {
    // Make a GET request to the Google Sign-In endpoint
    const baseURL = BASE_URL;
    try {
        window.location.href = `${baseURL}/user/auth/google`;
    } catch (error) {
        toast.error("Error occured try again later");
        console.error('Error with Google Sign-In request:', error);
    }
}
