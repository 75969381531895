
import SignUp from "./components/SinUp";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import ExampleCard1 from "@src/components/example_card/index copy";
import { signupStructuredData } from "../strutured_data/structured_data";



export default function SignUpPage() {
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-3 py-0 px-5 md:px-20">
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(signupStructuredData)}</script>
                <link rel="canonical" href="https://gmind.ai/signup" />
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta name="description" content="Best AI for educators, save time planning, creating teaching materials and get support for teaching practice" />
                <meta
                    name="title"
                    content="GMind AI SignUp | Access Your AI Productivity Tools"     
                />
              <link rel="canonical" href="https://www.gmind.ai/signup" />
                <title>Get Started</title>
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>
            {/* <div className=" w-full md:w-full">
                <Header isLoggedIn={false} onClickFaQ={() => {}} onClickFeature={() => {} } onClickPricing={() => {}} />
            </div> */}
            
            <div className="flex flex-col md:flex-row items-center justify-between mt-3">
                <SignUp />
                <div className="hidden md:block">
                    <ExampleCard1/>
                </div>
            </div>
            <div className="hidden md:block">
                <ToastContainer />
            </div>
        </div>
    );
}

