const skillOptions = [
    'Observation',
    'Hypothesis Testing',
    'Data Analysis',
    'Problem-Solving',
    'Research Skills',
];

const activityOptions = [
    'Lab Experiment',
    'Worksheet',
    'Interactive Simulation',
    'Field Trip',
    'Research Project',
];

const lessonOptions = [
    'Warm-Ups',
    'Introduction to Concepts',
    'Homework',
    'Guided Experiment',
    'Independent Research',
];

const sizeOptions = [
    'Individual',
    'Part',
    'Small Group',
    'Whole Class',
];

const durationOptions = [
    'Short (5-15 minutes)',
    'Medium (16-30 minutes)',
    'Long (more than 30 minutes)',
];

const availabilityOptions = [
    'Sensory Input',
    'Motor Skills',
    'Communication',
    'Social Skills',
    'Emotional Regulation',
];

const levelOption = [
    '',
    'Motor Skills',
    'Communication',
    'Social Skills',
    'Emotional Regulation',
];

const curriculumOptions = [
    'National Educational Research and Development Council (NERDC)',
    'Universal Basic education (UBE)',
    'National Council for Curriculum and Assessment (NaCCA)',
    'Core State Standards (CCSS)',
    'Next Generation Science Standards (NGSS)',
    'International Baccalaureate (IB) standards',
    'National Curriculum Framework (NCF)',
    'The British Curriculum',
    'The Montessori Curriculum',
    'The Universal Basic Education',
    'Others',
];

const standardOptions = [
    'Common Core State  Standard (CCSS)',
    'Next Generation Science Standards',
    'Advanced Placement (AP)',
    'Teachers Service Commission (TSC)',
    'National Council of Teachers of English    ',
];

const pedagogyOptions: string[] =[
    'Constructivist Pedagogy',
    'Inquiry-Based Pedagogy',
    'Project-Based Pedagogy',
    'Personalized Pedagogy',
    'Flipped Classroom Pedagogy',
    'Social Constructivist Pedagogy',
    'Experiential Pedagogy',
    'Gamification Pedagogy',
    'Blended Learning Pedagogy',
    'Montessori Pedagogy',
    'Reggio Emilia Pedagogy'
];

const pedagogicalStatements: Array<{[key: string]: string}> = [
    // Constructivist Pedagogy
    {
     'Constructivist Pedagogy':   `-Design a project that demonstrates your understanding of concept.
     -Work in groups to solve a real-world problem related to topic.,
     -Create a concept map to illustrate the relationships between ideas.
    `},
    // Inquiry-Based Pedagogy
    {
        "Inquiry-Based Pedagogy": `-Investigate the question: open-ended question related to topic.,
     -Design an experiment to test the hypothesis: hypothesis related to topic.,
     -Research and present on a topic related to subject.`
    },
    
    // Project-Based Pedagogy
    {
     "Project-Based Pedagogy": `-Develop a product that addresses the need: [real-world need related to topic].,
     -Create a public service announcement about [social issue related to topic].,
     -Design and implement a solution to the problem: [real-world problem related to topic].`,
    },
    // Personalized Pedagogy
    {
      "Personalized Pedagogy":  `-Set individual goals and create a plan to achieve them.,
     -Choose a topic related to [subject] and create a personalized project.,
     -Reflect on your learning and set targets for improvement.`
    },
    // Flipped Classroom Pedagogy
    {
    "Flipped Classroom Pedagogy": `-Watch the video and complete the assignment: [related to topic].,
     -Work on the activity and then discuss in class: [related to topic].,
     -Complete the online module and apply what you learned in class.`
    },
     // Social Constructivist Pedagogy
    {
        "Social Constructivist Pedagogy": `Collaborate on a group project to create a shared understanding of [concept].,
    Engage in a debate to explore different perspectives on [topic].,
    Participate in a role-play activity to practice social skills related to [subject].`
    },
    
    // Experiential Pedagogy
    {
        "Experiential Pedagogy": `Participate in a hands-on activity to explore [concept].,
    Conduct a field study to investigate [topic].,
    Engage in a simulation to practice real-world skills related to [subject].`
    },
    // Gamification Pedagogy
    {
        "Gamification Pedagogy":  `Complete a quest to earn points and badges in [topic].,
    Participate in a game-based activity to practice [skill].,
    Engage in a friendly competition to solve [problem].`
    },
    // Blended Learning Pedagogy
    {
        "Blended Learning Pedagogy": `Complete the online module and apply what you learned in class.,
    Work on the project both online and offline.,
    Use technology to collaborate with peers on a group project.`
    },
    // Montessori Pedagogy
    {
        "Montessori Pedagogy": `Explore the materials and discover the concept.,
    Work at your own pace to complete the activity.,
    Choose your own activity to learn about [topic].`
    },
    // Reggio Emilia Pedagogy
    {
        "Reggio Emilia Pedagogy":  `Explore the question: [open-ended question related to topic].,
    Work in a small group to investigate [topic].,
    Create a project that represents your learning.`
    }
   
];

const pedgogyDescription = (key: string): string => {
    let pedagogies = pedagogicalStatements;
    for (const pedagogy of pedagogies) {
        if (pedagogy.hasOwnProperty(key)) {
            return pedagogy[key];
        }
    }
    return "";
}

const questionTypeOptions = [
    '', 'Multi Choice Question', 'True/False', 'Fill in the blank', 'Short answer'
];

const gradeOptions = (): Array<string> => {
    let numberOfGrade = 12;
    let numberOfLevel = 5;
    let gradeValue = [];
    
    for ( let i = 0; i < numberOfGrade; i++){
        let suffix = '';
        if ( i + 1 == 1  ){
            suffix = 'st';
        }else if ( i + 1 == 2 ){
            suffix = 'nd';
        }else if ( i + 1 == 3 ){
            suffix = 'rd';
        }else {
            suffix = 'th';
        }

        gradeValue.push(`${i+1}${suffix} Grade`)
    }

    for ( let i = 0; i < numberOfLevel; i++){
        gradeValue.push(`${(i+1) * 100} Level`)
    }

    let otherOptions = ['Beginner', 'Intermediate', 'Advanced', 'Others'];

    gradeValue.push(...otherOptions);

    return gradeValue;
}

const socialMedias = [
    '',
    'Facebook',
    'Instagram',
    'LinkedIn',
    'Twitter',
    'TikTok',
    'Telegram',
    'WhatsApp',
];

const toneOptions = [
    '',
    'Humorous',
    'Engaging',
    'Business',
    'Excited',
    'Depressed',
    'Sarcastic',
    'Frightened',
    'Hopeful',
];

export {
    skillOptions,
    activityOptions,
    lessonOptions,
    sizeOptions,
    durationOptions,
    availabilityOptions,
    levelOption,
    curriculumOptions,
    standardOptions,
    pedagogicalStatements,
    pedagogyOptions,
    questionTypeOptions,
    pedgogyDescription,
    gradeOptions,
    socialMedias,
    toneOptions,
}