const ReportCardSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <rect width="40" height="40" rx="9" fill="none"/> */}
        {/* <rect width="40" height="40" rx="9" fill="white" fill-opacity="0.8"/> */}
        <path d="M17.6253 13.792H15.7087C14.6501 13.792 13.792 14.6501 13.792 15.7087V27.2087C13.792 28.2672 14.6501 29.1253 15.7087 29.1253H25.292C26.3505 29.1253 27.2087 28.2672 27.2087 27.2087V15.7087C27.2087 14.6501 26.3505 13.792 25.292 13.792H23.3753" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.6253 13.792H15.7087C14.6501 13.792 13.792 14.6501 13.792 15.7087V27.2087C13.792 28.2672 14.6501 29.1253 15.7087 29.1253H25.292C26.3505 29.1253 27.2087 28.2672 27.2087 27.2087V15.7087C27.2087 14.6501 26.3505 13.792 25.292 13.792H23.3753" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="17.625" y="11.875" width="5.75" height="3.83333" rx="1.91667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <rect x="17.625" y="11.875" width="5.75" height="3.83333" rx="1.91667" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        {/* <path d="M18.583 22.4167H22.4163" stroke="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
        <path d="M18.583 22.4167H22.4163" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        {/* <path d="M20.4997 20.5V24.3333" stroke="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
        <path d="M20.4997 20.5V24.3333" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}


export {
    ReportCardSvg
}