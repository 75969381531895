import { prompts } from "@src/utils/prompts";
import { Modal } from "antd";
import { Socket } from "socket.io-client";
import Quill from 'quill';
import { BASE_URL } from '../../../../../utils/constants';
import { toast } from "react-toastify";
import moment from "moment";


const baseurl = BASE_URL;


const onHumanizeAI = (socket: Socket | null, messageSent: string) => {
    let msgs = { role: 'user', content: messageSent };

    try {
        socket?.emit('data', {
            data: {
                messages: [
                    {
                        role: 'system',
                        content: prompts.essay + prompts.humanized,
                    },
                    msgs,
                ],
            },
        });
    } catch (error) {
        console.error(error);
    }
};

const onRegenerateConfirm = ({
    onConfirm,
}: { onConfirm: () => void }) => {
   
    Modal.confirm({
        title: 'Editing might be lost or replaced',
        // content: 'When you click on the okay, you are c',
        onOk() {
           onConfirm(); 
        },
        onCancel() {
            return;
        },
        okButtonProps: {
            style: { backgroundColor: '#28c76f', borderColor: '#28c76f', color: '#ffffff' },
        },
        okText: "Continue"
    });
}

const onTrialReminder = ( daysLeft: number, navigate: () => void ) => {
    Modal.confirm({
        title: `Your trial period will expire in ${daysLeft} days. Kindly subscribe to our package to continue enjoying our service`,
        // content: 'When you click on the okay, you are c',
        onOk() {
            if ( daysLeft == 0 ){
                navigate();
            }
        },
        onCancel() {
            return;
        },
        okButtonProps: {
            style: { backgroundColor: '#28c76f', borderColor: '#28c76f', color: '#ffffff' },
        },
        okText: "Ok"
    });
}

const checkIfTimeIsWithinRange = ( rangeInMinutes: number, dateToCheck: string ): boolean => {
    const currentDate = new Date();
    const inputDate = new Date(dateToCheck); 

// Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - inputDate.getTime();

    // Convert 30 minutes to milliseconds
    const rangeCheckInMilliseconds = rangeInMinutes * 60 * 1000;

    if ( timeDifference > rangeCheckInMilliseconds ) {
        return true
    }

    return false;
}

const convertHTMLToDelta = (html: string) => {
    const quill = new Quill(document.createElement('div'));
    quill.root.innerHTML = html;
    return quill.getContents();
};


const convertHTMLToPDF = async (content: string): Promise<void> => {
    const appUrl = baseurl.slice(0, -2);
    const response = await fetch(`${appUrl}html-to-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            content: `${content}`
        }),
      });
    
      if (response.status != 200) {
        toast.error('Failed to generate PDF');
        return;
      }

      const headers = response.headers;


      const titleHeader = headers.get('content-title');
    
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${titleHeader ?? `document-${moment().format("YYYY-MM-DD-HH-mm:ss A")}`}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Download successful');
}

const convertHTMLToWord = async (content: string): Promise<void> => {
    const appUrl = baseurl.slice(0, -2);
    const response = await fetch(`${appUrl}html-to-doc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });
    
      if (response.status != 200) {
        toast.error('Failed to generate Word');
        return;
      }

      const headers = response.headers;


      const titleHeader = headers.get('content-title');
    
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${ titleHeader ?? `document-${moment().format("YYYY-MM-DD-HH-mm:ss A")}`}.docx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Download successful');
}


 

export {
    onHumanizeAI,
    onRegenerateConfirm,
    onTrialReminder,
    checkIfTimeIsWithinRange,
    convertHTMLToDelta,
    convertHTMLToPDF,
    convertHTMLToWord,
}