import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import '@components/styles/app.css';
import { HelmetProvider } from 'react-helmet-async';
import { UserProvider } from './context/UseProvider';
import { DocumentProvider } from './context/DocumentContext';
import { ThemeProvider } from './theme_provider/theme';


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <HelmetProvider>
        
            <UserProvider>
                <DocumentProvider>
                <ThemeProvider>
                <App />
                </ThemeProvider>
                </DocumentProvider>
            </UserProvider>
            
        </HelmetProvider>
    </React.StrictMode>,
);
