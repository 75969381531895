// import { formatJSON } from '@/app/components/slidesForm/Step3';
// import useLocalStorage from '@/app/hooks/useLocalStorage';
// import { Slide } from '@/app/providers/store';
// import { BASE_URL, KEY } from '@/app/utils/constants';
// import { IUserData } from '@/app/utils/interfaces';
import { Slide } from '@src/providers/store';
import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';
const token = localStorage.getItem('token');
const rule = `
Generate an image that exactly matches the description of the image provided by the user
Ensure that image is an exact match of the image description as provided by the user
I repeat ensure the image is an exact match of the image description as provided by the user
Follow the description provided by the user, and ensure it meets all their requirements.
Never add a text to the image generated. I repeat in no circumstance should you add a text to the image generated.

Kindly follow to the following rules:
 - no-caption or image-only
 - no-text-overlay
 - no-image-text just plain-image
`;

const imageRule = `
   Please generate an image following these rules:  
1. **Style Precision**: Ensure the image style aligns exactly with the requested type:  
   - For **realistic images**, prioritize photorealism with accurate details reflective of the real world.  
   - For **scientific or biological topics** (e.g., genetics, agriculture, ecosystems), focus on high-detail, scientifically accurate elements (e.g., DNA strands, cells, plants, or farming tools). Avoid abstract or stylized interpretations unless specifically requested.  
   - For **abstract, anime, or avatar styles**, strictly follow the specified aesthetic.  

2. **Content Accuracy**:  
   - For topics involving **biology, agriculture, or science**, include appropriate and contextually accurate elements. Examples include realistic depictions of DNA structures, cells, plants, ecosystems, farm animals, tools, or machinery, depending on the topic.  
   - Represent details like textures, colors, and structures as they naturally appear.  

3. **Human Elements**:  
   - If human figures are included, ensure faces and body proportions are photorealistic and anatomically accurate. For specific professions (e.g., scientists, farmers), add appropriate attire and tools to match the context.  

4. **Text Handling**:  
   - Include text only when explicitly requested, and ensure it is correctly spelled, legible, and appropriately placed within the image.  

5. **Size and Format**:  
   - Always generate images in the specified size and proportions without distortion.  

6. **Clarity in Focus**:  
   - Avoid mixing styles unless explicitly stated. For example, do not incorporate abstract or anime elements into realistic or scientific themes.  
   - Ensure the primary focus (e.g., genetics, farming, ecosystems) remains clear and unambiguous.  

`;

export function formatJSON(jsonString: any) {
    try {
        const jsonObject = JSON.parse(jsonString);
        return JSON.stringify(jsonObject, null, 2);
    } catch (error) {
        console.error('Invalid JSON format:', error);
        return null;
    }
}

type Chats = { role: string; content: string };

let chats: Chats = { role: '', content: '' };
const isValidSlide = (slide: any): slide is Slide => {
    return (
        typeof slide === 'object' &&
        typeof slide.title === 'string' &&
        typeof slide.content === 'string' &&
        typeof slide.image === 'string'
    );
};
export const handleAutoGenerate = async (number: number, description: string): Promise<Slide[]> => {
    const sytemPrompt = `You are an AI-powered slide creation assistant designed to help users create engaging, informative, and visually appealing slides for various purposes, such as presentations, lectures, or educational content. Your goal is to generate slide content based on the user's input, ensuring that the slides are well-structured, concise, and effectively convey the intended message.

    Note: only generate this ${number} slides. Do not generate more or less than this number.

        


When a user provides you with the necessary information, your task is to understand their requirements and generate a set of slides that align with the specified criteria. Here's how you can approach this task:




1. Understand the user's requirements:
   - Ask for a clear and concise description of the topic or purpose of the slides.
   - Inquire about the target audience, their background knowledge, and the desired level of depth or complexity.
   - Determine the desired number of slides to be created.
   - Ask for any specific guidelines, branding requirements, or visual preferences the user may have.

2. Break down the topic into key points and subtopics:
   - Identify the main ideas, concepts, or messages that need to be conveyed through the slides.
   - Organize the content into a logical flow, ensuring a clear progression of ideas from one slide to another.
   - Determine the appropriate level of detail and depth for each slide based on the target audience and the overall purpose.

3. Create an outline for the slide deck:
   - Develop a structured outline that includes the main sections, subsections, and key points for each slide.
   - Ensure that the outline follows a coherent narrative and supports the overall objectives of the presentation.

4. Generate content for each slide:
   - Use the outline as a guide to create concise and engaging content for each slide.
   - Focus on one main idea or message per slide, using bullet points, short paragraphs, or visual aids to convey the information effectively.
   - Provide examples, analogies, or real-world applications to illustrate complex concepts and make the content more relatable to the audience.
   - Incorporate relevant data, statistics, or quotes to support the main points and add credibility to the presentation.

5. Suggest appropriate visual elements:
   - Recommend relevant images, diagrams, charts, or graphs that can enhance the understanding and visual appeal of each slide.
   - Ensure that the suggested visual elements are high-quality, properly labeled, and aligned with the content of the slide.
   - Provide guidance on the placement and sizing of visual elements to maintain a balanced and professional layout.

6. Apply effective design principles:
   - Use a consistent color scheme, font style, and formatting throughout the slide deck to maintain a cohesive and professional appearance.
   - Ensure that the text is legible, with appropriate font sizes and color contrast for easy readability.
   - Recommend appropriate slide transitions, animations, or interactive elements that enhance the flow and engagement of the presentation.

7. Review and refine the generated slides:
   - Proofread the content for clarity, accuracy, and consistency, making necessary revisions or improvements.
   - Check for any spelling, grammar, or punctuation errors and ensure that the language is concise and easy to understand.
   - Verify that the slides align with the user's requirements, branding guidelines, and overall objectives.

8. Provide the generated slides in the requested format:
   - Organize the slide content in a JSON array format, where each element represents a single slide.
   - Include the slide title, content (text and visual elements), and any relevant notes or annotations.
   - Ensure that the output is well-structured, properly formatted, and ready for the user to import or integrate into their preferred presentation software.

9. Offer additional support and recommendations:
   - Provide tips and best practices for delivering an effective presentation based on the generated slides.
   - Suggest ways to engage the audience, handle questions, and make the presentation more interactive and memorable.
   - Offer to make further revisions or adjustments based on the user's feedback or changing requirements.




Remember, your ultimate goal is to create high-quality, engaging, and informative slides that effectively communicate the user's message to their target audience. Strive to generate content that is clear, concise, visually appealing, and aligned with the user's objectives. Engage in a dialogue with the user to ensure that the generated slides meet their specific needs and expectations.`;
    const prompt = `Greetings AI! We require your expertise to create content for a presentation on "${description}" across ${number} slides. Each slide must follow this format within its respective field.
     
    Format:
        Objective: Educate learners about [Key Concepts] and [Learning Objectives]
        Slide Structure:
        Slide 1: Introduction to [Topic]
        Slide 2-3: Definition and explanation of [Key Concept 1]
        Slide 4-5: Analysis of [Key Concept 2]
        Slide 6-7: Evaluation of [Key Concept 3]
        Slide 8: Conclusion and summary
        Content Requirements:
        Clear and concise language
        Visual elements (images, charts, graphs) to illustrate key concepts
        Recent data and statistics to support points
        Innovative solutions and best practices
        Examples and case studies
        * Tone and Style:
        * Informative
        * Engaging
        * Objective
        * Clear and concise
        Format:
        * Each slide should have a clear heading and concise bullet points
        * Use clear and concise language, avoiding jargon and technical terms
        * make the content engaging and 
        * Ensure content is accurate, up-to-date, and supported by credible sources informative content that aligns with pedagogical principles and deep research on the topical issue
     
     Please provide the content in a JSON array structure, where each object contains a "title", "content","image"--(this would be empty string) property for seamless integration into our presentation. for further understanding, this is the inteface for each slide: export interface Slide {
        title: string;
        content: string;
        image: string;
      }
     Your contribution will significantly enhance the clarity and depth of our presentation.
     Please let your result be the json array only because i am directly caaling a .map function to display your results contents, 
     for example you usually make a response tat starts like this: "Sure, I understand your requirement. Here's the JSON array:" and this makes .map fail!.
     Also note:Do not put quotation marks or escape character in the output fields Thank you for your valuable input!

     Note: only generate this ${number} slides. Do not generate more or less than this number.
     
     `;

    try {
        let msgs = chats;
        msgs = { role: 'user', content: prompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: sytemPrompt,
                    },
                    msgs,
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        msgs = response.data.data.choices[0].message;
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;
        // const formattedData = formatJSON(generatedContent);
        const formattedData = JSON.parse(generatedContent) as Slide[];
        if (!Array.isArray(formattedData) || !formattedData.every(isValidSlide)) {
            throw new Error('Invalid slide data format');
        }
        if (!formattedData) {
            return [
                {
                    title: '',
                    content: '',
                    image: '',
                },
            ];
        }
        return formattedData;
    } catch (error: any) {
        return [];
    } finally {
    }
};

export const handleAutoGeneratePostGraduate = async (number: number, description: string): Promise<Slide[]> => {
    const sytemPrompt = `You are an AI-powered slide creation assistant designed to help users create engaging, informative, and visually appealing slides for various purposes, such as presentations, lectures, or educational content. Your goal is to generate slide content based on the user's input, ensuring that the slides are well-structured, concise, and effectively convey the intended message.

    Note: only generate this ${number} slides. Do not generate more or less than this number.

        


When a user provides you with the necessary information, your task is to understand their requirements and generate a set of slides that align with the specified criteria. Here's how you can approach this task:




1. Understand the user's requirements:
   - Ask for a clear and concise description of the topic or purpose of the slides.
   - Inquire about the target audience, their background knowledge, and the desired level of depth or complexity.
   - Determine the desired number of slides to be created.
   - Ask for any specific guidelines, branding requirements, or visual preferences the user may have.

2. Break down the topic into key points and subtopics:
   - Identify the main ideas, concepts, or messages that need to be conveyed through the slides.
   - Organize the content into a logical flow, ensuring a clear progression of ideas from one slide to another.
   - Determine the appropriate level of detail and depth for each slide based on the target audience and the overall purpose.

3. Create an outline for the slide deck:
   - Develop a structured outline that includes the main sections, subsections, and key points for each slide.
   - Ensure that the outline follows a coherent narrative and supports the overall objectives of the presentation.

4. Generate content for each slide:
   - Use the outline as a guide to create concise and engaging content for each slide.
   - Focus on one main idea or message per slide, using bullet points, short paragraphs, or visual aids to convey the information effectively.
   - Provide examples, analogies, or real-world applications to illustrate complex concepts and make the content more relatable to the audience.
   - Incorporate relevant data, statistics, or quotes to support the main points and add credibility to the presentation.

5. Suggest appropriate visual elements:
   - Recommend relevant images, diagrams, charts, or graphs that can enhance the understanding and visual appeal of each slide.
   - Ensure that the suggested visual elements are high-quality, properly labeled, and aligned with the content of the slide.
   - Provide guidance on the placement and sizing of visual elements to maintain a balanced and professional layout.

6. Apply effective design principles:
   - Use a consistent color scheme, font style, and formatting throughout the slide deck to maintain a cohesive and professional appearance.
   - Ensure that the text is legible, with appropriate font sizes and color contrast for easy readability.
   - Recommend appropriate slide transitions, animations, or interactive elements that enhance the flow and engagement of the presentation.

7. Review and refine the generated slides:
   - Proofread the content for clarity, accuracy, and consistency, making necessary revisions or improvements.
   - Check for any spelling, grammar, or punctuation errors and ensure that the language is concise and easy to understand.
   - Verify that the slides align with the user's requirements, branding guidelines, and overall objectives.

8. Provide the generated slides in the requested format:
   - Organize the slide content in a JSON array format, where each element represents a single slide.
   - Include the slide title, content (text and visual elements), and any relevant notes or annotations.
   - Ensure that the output is well-structured, properly formatted, and ready for the user to import or integrate into their preferred presentation software.

9. Offer additional support and recommendations:
   - Provide tips and best practices for delivering an effective presentation based on the generated slides.
   - Suggest ways to engage the audience, handle questions, and make the presentation more interactive and memorable.
   - Offer to make further revisions or adjustments based on the user's feedback or changing requirements.
   
.


Remember, your ultimate goal is to create high-quality, engaging, and informative slides that effectively communicate the user's message to their target audience. Strive to generate content that is clear, concise, visually appealing, and aligned with the user's objectives. Engage in a dialogue with the user to ensure that the generated slides meet their specific needs and expectations.`;
    const prompt = `Greetings AI! We require your expertise to create content for a presentation on "${description}" across ${number} slides. Each slide must follow this format within its respective field.
     
    Format:
        Objective: Educate learners about [Key Concepts] and [Learning Objectives]
        Slide Structure:
        Slide 1: Introduction to [Topic]
        Slide 2-3: Definition and explanation of [Key Concept 1]
        Slide 4-5: Analysis of [Key Concept 2]
        Slide 6-7: Evaluation of [Key Concept 3]
        Slide 8: Conclusion and summary
        Content Requirements:
        Clear and concise language
        Visual elements (images, charts, graphs) to illustrate key concepts
        Recent data and statistics to support points
        Innovative solutions and best practices
        Examples and case studies
        * Tone and Style:
        * Informative
        * Engaging
        * Objective
        * Clear and concise
        Format:
        * Each slide should have a clear heading and concise bullet points
        * Use clear and concise language, avoiding jargon and technical terms
        * make the content engaging and 
        * Ensure content is accurate, up-to-date, and supported by credible sources informative content that aligns with pedagogical principles and deep research on the topical issue
 

**Slide Formats**
1. **Text format**: { "title": "Slide Title", "content": "Slide Content" }
2. **Chart format**: { "title": "Slide Title", "chart": { "type": "bar", "labels": [...], "data": [...] } }
3. **Table format**: { "title": "Slide Title", "table": { "headers": [...], "rows": [...] } }

**Rules**
1. Each slide must be in only one of the above formats (not multiple at once).  
2. Do not generate any explanatory text.  
3. Only return clean, parseable JSON.  


**Note** Do not generate chart or table data seperately. it is either a content or chart or table in the same field.

**Important Notes**

- **Do not generate more than ${number} slides**. 
- Ensure that slides for **postgraduate presentations** include in-depth analysis, with both **charts and tables** when applicable.
- Make sure **JSON is clean and parseable**. 
- Do not include any explanatory text or prefixes like "Here's the result:".
     Your contribution will significantly enhance the clarity and depth of our presentation.
     Please let your result be the json array only because i am directly caaling a .map function to display your results contents, 
     for example you usually make a response tat starts like this: "Sure, I understand your requirement. Here's the JSON array:" and this makes .map fail!.
     Also note:Do not put quotation marks or escape character in the output fields Thank you for your valuable input!

     Note: only generate this ${number} slides. Do not generate more or less than this number.
     **Note** Do not generate chart or table data seperately. it is either a content or chart or table in the same field.
     
     `;

    try {
        let msgs = chats;
        msgs = { role: 'user', content: prompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: sytemPrompt,
                    },
                    msgs,
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        msgs = response.data.data.choices[0].message;
        const AIRESULT = response?.data?.data?.choices[0]?.message;
        const generatedContent = AIRESULT?.content;
        // const formattedData = formatJSON(generatedContent);
      
        const cleanJSON = AIRESULT.content;
     

        // Parse the clean JSON
        const formattedData = JSON.parse(cleanJSON) as Slide[];
        console.log('Formatted Data:', formattedData);
        if (!Array.isArray(formattedData)) {
            console.error('Response is not an array', formattedData);
            return [];
        }

        // Normalize each slide
        const slides = formattedData.map(slide => ({
            title: slide.title ?? '',
            content: slide.content ?? '',
            image: slide.image ?? ''
        }));

        console.log('Successfully generated slides:', slides);
        return formattedData;
    } catch (error: any) {
        return [];
    } finally {
    }
};
export const handleAutoGenerateForKids = async (
    number: number,
    description: string,
    level: string,
    grade: string,
): Promise<Slide[]> => {
    const isYoungKids = level === '1st to 3rd Grade';
    const systemPrompt = `You are an AI-powered slide creation assistant specializing in creating kid-friendly, fun, and engaging slides. Your goal is to generate content that captures young learners' attention while educating them effectively.

    - **Target Audience**: ${isYoungKids ? 'Young Kids (1st to 3rd Grade)' : 'Older Kids (4th to 8th Grade)'}
    - **Complexity**: ${isYoungKids ? 'Simple, bright, and interactive' : 'Age-appropriate with engaging visuals'}

    Note: only generate this ${number} slides. Do not generate more or less than this number.
    
    Guidelines:
    - Use simple and relatable language.
    - Include engaging visuals like cartoons, icons, or colorful diagrams.
    - Ensure each slide is concise and fun to read.
    - Adjust complexity based on age level.
    
    1. For young kids:
        - Use fun facts, rhymes, or simple analogies.
        - Highlight key points with vibrant colors and large fonts.
        - Provide interactive suggestions (e.g., "Try this at home!").
    
    2. For older kids:
        - Include age-appropriate details and relatable examples.
        - Use a mix of text, visuals, and simple data representations.
        - Encourage curiosity and discovery through questions or fun activities.

    Remember, the goal is to make learning fun and engaging for kids while being educational.`;

    const prompt = `Hey AI! Please help us create content for a presentation on "${description}" for ${grade} kids. We need ${number} slides formatted as follows:

    Format:
    - Objective: Engage kids and teach them about [Key Concepts]
    - Structure:
        Slide 1: Fun introduction to [Topic]
        Slide 2-3: Simple explanation of [Key Concept 1]
        Slide 4-5: Visual story about [Key Concept 2]
        Slide 6-7: Activity or game based on [Key Concept 3]
        Slide 8: Fun summary and a call-to-action

    Each slide must include:
    - A title
    - Clear, concise, and kid-friendly content
    - Suggestions for fun visuals or animations (e.g., emojis, cartoon drawings)
    - Empty image placeholders (as "image" property) for later customization

    Please provide the slides in a JSON array structure with exactly ${number} slides. Example:
    [{ "title": "Slide Title", "content": "Slide Content", "image": "" }, ...]
    
    Just output the JSON array without any introductory text or explanation!

    Note: only generate this ${number} slides. Do not generate more or less than this number.
    
    
    `;

    try {
        const msgs = { role: 'user', content: prompt };
        const response = await axios.post(
            `${BASE_URL}/knowledge-base`,
            {
                model: 'gpt-4',
                messages: [
                    {
                        role: 'system',
                        content: systemPrompt,
                    },
                    msgs,
                ],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        const AIRESULT = response?.data?.data?.choices[0]?.message?.content;
        const formattedData = JSON.parse(AIRESULT) as Slide[];

        if (!Array.isArray(formattedData) || formattedData.length !== number || !formattedData.every(isValidSlide)) {
            throw new Error('Invalid slide data format');
        }

        return formattedData;
    } catch (error: any) {
        console.error('Error generating slides:', error);
        return [];
    }
};

export function generateSonObjects(num: number): Slide[] {
    const sonObjects: Slide[] = [];

    for (let i = 0; i < num; i++) {
        const sonObject: Slide = {
            title: `Slide Title ${i + 1}`,
            content: 'content goes here..',
            image: '',
        };
        sonObjects.push(sonObject);
    }

    return sonObjects;
}

export const gradeOptions = (): Array<{ label: string; value: string }> => {
    let numberOfGrade = 12;
    let numberOfLevel = 5;
    let gradeValue = [];

    for (let i = 0; i < numberOfGrade; i++) {
        let suffix = '';
        if (i + 1 === 1) {
            suffix = 'st';
        } else if (i + 1 === 2) {
            suffix = 'nd';
        } else if (i + 1 === 3) {
            suffix = 'rd';
        } else {
            suffix = 'th';
        }
        gradeValue.push({ label: `${i + 1}${suffix} Grade`, value: `${i + 1}${suffix} Grade` });
    }

    for (let i = 0; i < numberOfLevel; i++) {
        gradeValue.push({ label: `${(i + 1) * 100} Level`, value: `${(i + 1) * 100} Level` });
    }

    let otherOptions = [
        // { label: 'Beginner', value: 'Beginner' },
        // { label: 'Intermediate', value: 'Intermediate' },
        // { label: 'Advanced', value: 'Advanced' },
        // { label: 'Others', value: 'Others' },
        { label: 'General Public', value: 'General Public' },
        { label: 'Post Graduate', value: 'Post Graduate' },
    ];

    gradeValue.push(...otherOptions);

    return gradeValue;
};

export const generateOpenAimage = async (prompt: any, size: string, slide = false) => {
    const baseurl = BASE_URL;
    try {
        console.log(size);
        const imageSize = size ?? '1024x1024';

        const response = await axios.post(
            `${baseurl}/gmind/image_maker`,
            {
                prompt: `${prompt}-Take note of these rules: ${slide ? rule : imageRule}`,
                size: imageSize,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        console.log(response);
        return response;
    } catch (error) {
        console.error('Error generating image:', error);
        // throw new Error("Failed to generate image");
    }
};

export const generateImagesList = async (prompt: any, size: string) => {
    try {
        const response = await generateOpenAimage(prompt, size);
        if (response?.data) {
            return response.data.data[0];
        }
        throw new Error('No image data received');
    } catch (error) {
        console.error('Error generating images:', error);
        throw new Error('Failed to generate images');
    }
};
