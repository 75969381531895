import React from 'react';
import { MyPhoneIcon, MyRedCircleIcon } from '@components/Svg';
import { SmileOutlined, AudioOutlined } from '@ant-design/icons';
import { Circle } from 'lucide-react';

interface RecordAudioCardProps {
    onRecord: () => void;
    isClicked: boolean;
}

const RecordAudioCard = ({ onRecord, isClicked }: RecordAudioCardProps) => {
    return (
        <button
            onClick={onRecord}
            className={`flex items-center gap-2 px-4 py-2 rounded-md transition-colors duration-200 border-2  
      ${isClicked ? 'dark:border-customOrange border-customOrange bg-transparent text-white dark:text-customOrange' : 'dark:border-white bg-transparent border-gray-600 text-gray-700 dark:text-white'}
      hover:bg-gray-200 dark:hover:bg-gray-700`}
        >
            <Circle className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} />

            <span className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`}>Record Audio</span>

            <AudioOutlined className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} />
        </button>
    );
};

export default RecordAudioCard;
