/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Profile from '@assets/Avatar.svg';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';
import LoadingComponent from '@src/components/LoadingComponent';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { ChatType, getHistory, handleAddHistory, handleDeleteAllHistory, handleDeleteHistory } from '../../endpoints';
import DashboardFrame from '@src/components/DashboardFrame';
// import { Markdown } from '../../components/Markdown';
import MarkdownDesign from '@src/components/Markdown';
import ResponseTextbox from '../../components/responseTextBox';
import {
    clickDomElement,
    convertStringToDelta,
    copyData,
    getUrlPath,
    openEditor,
    shareResponse,
    openCurriculumEditor
} from '@src/utils/app_functions';
import NewCurriculum from './components/new_curriculum_home';
import BackButton from '@src/components/BackButton';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import saveAs from 'file-saver';
import axios from 'axios';
import moment from 'moment';
import { pdfExporter } from 'quill-to-pdf';
import { deltaToMarkdown } from 'quill-delta-to-markdown';
import { Download } from '../../components/Share/Download';
import { Share } from '../../components/Share';
import right from '@assets/right_gray.svg';
import ContentTypingComponent from '../../components/content_typing';
import { BASE_URL, socketUrl } from '@src/utils/constants';
import { Chats } from '../../components/interface/interface';
import ReusableDialog from '../../components/comment_modal';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import './global.scss';
import NewEditorPage from '../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../essay/sun_editor copy';
import { useDebouncer } from '../../function/helper';
import { useUserContext } from '@src/context/UseProvider';
import { useDocumentContext } from '@src/context/DocumentContext';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const Curriculum = () => {
    const pageHistory = 'curriculum';
    const UrlRef = useRef<string>('');
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const DocumentId = useRef('');
    const Document = useRef<any>();
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const StreamId = useRef<any>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<() => void>(() => {});
    const historyCloseId = 'historyClose';
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });
    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        setHistoryType,
        setPageHistory,
        showingHistory,
        response,
        promptSent,
        getHistory,
        setPromptSent,
        setResponse,
        setShowingHistory,
    } = useOutletContext<any>();

    const baseurl = BASE_URL;

    const bottomRef = useRef<HTMLDivElement>(null);

 

    const handlePDF = async () => {
        const data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data);
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    };
    const handleWord = async () => {
        const dataToDownload = response;
        const responseData = await axios.post(
            `${baseurl}/document/markdown-to-doc`,

            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `${baseurl}/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    };

    const handleShare = async () => {
        // const data = Document.current?.data?.ops;
        // const to_markdown = deltaToMarkdown(data);

        if (UrlRef.current) {
            setShowShareModal(true);
            return;
        }
        const responseData = await axios.post(`${baseurl}/document/markdown-to-doc`, { content: response }, {});

        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const url = `${baseurl}/${d?.url}`;
            UrlRef.current = url;
            setShowShareModal(true);
        }
    };

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    // const setClickedHistory = useCallback(
    //     (id: string) => {
    //         let filterHistory: any = histories
    //             .flatMap((historyFormat: any) => historyFormat.histories)
    //             .filter((history: any) => {
    //                 const chatIdObj = history.find((h: any) => h.id === id);
    //                 return chatIdObj !== undefined;
    //             })
    //             .flat();

    //         // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
    //         filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
    //         const userPrompt = filterHistory.find((element: any) => element.role == 'user');
    //         const assistantResponse = filterHistory.find((element: any) => element.role == 'assistant');
    //         setPromptSent(userPrompt.content);
    //         setResponse(assistantResponse.content);
    //         setShowingHistory(true);
    //         // setChatList(filterHistory);
    //     },
    //     [histories],
    // );

    // useEffect(() => {
    //     getPageHistory();
    // }, []);

    useEffect(() => {
        const newSocket = io(socketUrl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const handleStreamEnd = async (prompt: string, data: ChatType) => { 
        const dataToSave = data;
        const docData = {
            content: response,
            doc_id:  docId,
            promptSent: prompt,
            promptType: promptSent,
        }
        await handleAddHistory(dataToSave, pageHistory, docData);
        if (docId) fetchDocument(docId ?? '', true);
        getHistory();
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: any) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);

            // if (streamId === StreamId.current) {
                StreamId.current = '';
                // setStreaming(false);

                setIsTyping(false);
                const uuid = uuidv4();
                setDocId(uuid);
                const user = JSON.parse(localStorage.getItem('user') || '');
                try {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                        owner_id: user?.id,
                    });
                } catch (error) {
                    socket?.emit('store-document', {
                        id: uuid,
                        title: promptSent,
                        value: assistant.content,
                    });
                }
                socket.emit('get-documents', user?.id);
            
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };
    
    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(promptSent, streamEndMessage);
        }
    }, [isTyping, response])

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const onGenerateHandler = async (messageSent: string, type: string) => {
        toast.info('Please sit tight, your beautiful content is on its way.');
        setShowEditorButton(false);
        setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: messageSent };
        console.log(msgs);
        // return;
        setChats(msgs);

        setMessage('');
        setNewMessage('');
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: type == 'activity' ? prompts.activity : prompts.curriculum,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                },
            });
        } catch (error) {
            setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleOpenDialog = () => {
        setDialogVisible(true);
    };

    const handleCloseDialog = () => {
        setDialogVisible(false);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        handleCloseDialog();
    };

    const onFinish = async (promptMessage: string, type: string) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage, type);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const regenerate = () => {
        onGenerateHandler(promptSent, page);
        setGenerate(true);
        // setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const openTextEditor = () => {
        const documentID = uuidv4();
        console.log(DocumentId.current);
        localStorage.setItem(
            'essay_content',
            JSON.stringify({
                title: topic,
                value: response,
                documentId: DocumentId.current ?? documentID,
            }),
        );
        navigate(`/dashboard/article/editor?document=${DocumentId.current ?? documentID}`);
    };

    const chatWithAI = async () => {
        const promptMessage = newMessage;
        setMessage(promptMessage);
        setPromptSent(promptMessage);

        // await onGenerateHandler(promptMessage);
        // setGenerate(true);
        // setGenerating(true);
        // const timeoutId = setTimeout(() => {
        //     setGenerating(false);
        // }, 3000);
    };
    const handleShareClick = () => {
        handleShare();
    };

    const handleCopyClick = () => {
        copyData(response);
    };

    const handleMessageClick = () => {
        handleOpenDialog();
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};
    const handleDownloadClick = () => {
        setShowDownload(true);
    };

    useEffect(() => {
        if (document && docId) {
            // Register the user to the document space
            socket?.emit('register_document_space', {
                documentId: docId,
                userId: userDetails?.userId,
            });

            // Listen for document changes
            socket?.on('receive-changes', (data: { data: string; documentId: string; userId: string }) => {
                console.log(data.userId);
                console.log('userDetails?.userId');

                // if (data.userId === userDetails?.userId) return;
                console.log('data.userId', data.data);
                setEditorData(data.data);

                setEditorContent(data.data);
            });

            // Listen for users on document
            socket?.on('user_on_document', (data: UserDocOn) => {
                setUserOnDoc(data);
            });

            // Cleanup listeners on component unmount or dependencies change
            return () => {
                socket?.off('receive-changes'); // Fix typo here
                socket?.off('user_on_document');
            };
        }

        // Cleanup in case no document exists
        return () => {
            socket?.off('receive-changes');
            socket?.off('user_on_document');
        };
    }, [socket, document, docId]);

    const promptOptionsData = [
        {
            name: 'Share',
            icon: ShareImg,
            onClick: handleShareClick,
        },
        {
            name: 'Copy',
            icon: Copy,
            onClick: handleCopyClick,
        },
        {
            name: 'Download',
            icon: DownloadImage,
            onClick: handleDownloadClick,
        },
    ];

    useEffect(() => {
        console.log('MyHistory', histories);
        getHistory();
    }, []);

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        setHistory(histories);
        setShowRightComponent(false);

        setMobileNavStyle(!isMobile ? { position: 'fixed' } : {});
        setShowHistory(!isMobile);
        setShowInput(false);
        setShowPagePath(page != '');
        setRightComponentPlaceholder('Curriculum');
        setCanNavigateHome(true);
        setShowHistoryOptions(page != '');
        setPageHistory('curriculum');
        setHistoryType('curriculum');
        setShowSidebar(true);
        setShowTop(true);
    }, [isMobile, page, histories]);

    return (
        <div ref={bottomRef} className=" h-screen overflow-auto">
            {!generate && !showingHistory ? (
                <NewCurriculum
                    onGenerate={(prompt, type) => onFinish(prompt, type)}
                    setTopic={(e) => setTopic(e)}
                    setPageName={(e) => setPage(e)}
                    setPageClickFn={(fn) => setPageClick(fn)}
                />
            ) : (
                <div className="w-full flex flex-col gap-0 dark:bg-[#212121] ">
                    <div className="w-full flex flex-row gap-0 ">
                        <div className="w-full flex flex-col gap-0 ">
                            <div>
                                {generating ? (
                                    <LoadingComponent isMobile={false} width={''} />
                                ) : (
                                    <>
                                        {/* <ResponseTextbox
                                            content={<MarkdownDesign className="">{response}</MarkdownDesign>}
                                            options={showEditorButton ? promptOptionsData : []}
                                            disLikeClick={handleDisLikeClick}
                                            messageClick={handleMessageClick}
                                            likeClick={handleLikeClick}
                                            regenerateClick={regenerate}
                                            profileClick={() => {}}
                                        /> */}

                                        <NewEditorPage
                                        editorInstanceRef={editorInstanceRef}
                                        regenerate={regenerate}
                                            content={
                                                <NewSunEditorComponent
                                                editorInstanceRef={editorInstanceRef}
                                                    // humanizeded_content={Document.current?.data.ops}
                                                    // hasAccess={hasAccess}
                                                    hasAccess={
                                                        !isTyping && (documentResponse?.role ?? '') !== 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    initial_content={response}
                                                    onChangeEditorContent={(e: string) => {
                                                        console.log('e', e);
                                                        setEditorContent(e);
                                                        const newtext = e;
                                                        console.log('newtext', newtext);

                                                        console.log('docId', docId);
                                                        console.log('userDetails?.userId', userDetails?.userId);

                                                        sendChanges(docId, userDetails?.userId, e);
                                                    }}
                                                                                               />
                                            }
                                            showTopNavBar={false}
                                            showInvite={true}
                                            isTyping={isTyping}
                                            Profile={Profile}
                                            promptSent={promptSent}
                                            showingHistory={showingHistory}
                                            setGenerate={setGenerate}
                                            setShowingHistory={setShowingHistory}
                                            generate={generate}
                                            setEditorContent={setEditorContent}
                                            editorContent={editorContent}
                                            translatedContent={translatedContent}
                                            setTranslatedContent={setTranslatedContent}
                                            humanizedContent={humanizedContent}
                                            setHumanizedContent={setHumanizedContent}
                                            setUserOnDoc={setUserOnDoc}
                                            userOnDoc={userOnDoc}
                                        />
                                    </>
                                )}
                            </div>
                            {/* <div className="sticky bottom-0 bg-custom-opacity">
                                <Input
                                    type="text"
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    value={newMessage}
                                    placeholder="Describe what you want"
                                    style={{
                                        height: '50px',
                                        borderRadius: '16px',
                                        border: '1px solid lightgrey',
                                        marginTop: '17px',
                                    }}
                                    suffix={
                                        <svg
                                            onClick={() => chatWithAI()}
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15 18L31.5 18"
                                                stroke="#E55109"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M31.5004 18.0004L13.1254 26.6254C12.9116 26.7049 12.6711 26.6524 12.5098 26.4911C12.3485 26.3298 12.296 26.0893 12.3754 25.8754L15.0004 18.0004L12.3754 10.1254C12.296 9.91158 12.3485 9.67107 12.5098 9.50977C12.6711 9.34846 12.9116 9.29598 13.1254 9.37542L31.5004 18.0004"
                                                stroke="#E55109"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                />
                                <div className="advise">
                                    Gmind can make mistakes. It's advisable to verify crucial information.
                                </div>
                            </div> */}

                            <ReusableDialog
                                visible={dialogVisible}
                                title="Add Comment"
                                inputPlaceholder="Enter your comment"
                                saveLabel="Save"
                                onCancel={handleCloseDialog}
                                onSave={handleSaveComment}
                            />
                            {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />}
                            {showDownload && (
                                <Download
                                    handlePDF={handlePDF}
                                    handleWord={handleWord}
                                    url={UrlRef.current}
                                    onClose={() => setShowDownload(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Curriculum;
