import { BASE_URL } from '@src/utils/constants';
import axios from 'axios';

export const GetUser = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/user`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(response);

        if (response.status != 200) {
            return {
                statusCode: response.data?.statusCode,
                message: response.data?.message,
                data: response.data || null,
            };
        }

        const { data } = response;
        return data;
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';
            
            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null, // You can optionally return the response data if needed
            };
        } else {
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};

export const GetUsers = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/user/users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const { data } = response;
        return data;
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';
            
            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null, // You can optionally return the response data if needed
            };
        } else {
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};

export const updateUserDetails = async (dataSent) => {
    const token = localStorage.getItem("token");
    if ( !token ) return;
    try {
        const response = await axios.post(
            `${BASE_URL}/user/update_details`,
            JSON.stringify(dataSent),
            {
              timeout: 12000,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              validateStatus: (status) => status >= 200 && status <= 505,
            }
          );

          if ( response.status != 200 ){
            return {
                statusCode: response.data?.statusCode,
                message: response.data?.message,
                data: response.data?.data || null, // You can optionally return the response data if needed
            };
          }

        const { data } = response;
        return data;
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';
            
            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null, // You can optionally return the response data if needed
            };
        } else {
            console.log(error);
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};



export const changePassword = async (dataSent) => {
    const token = localStorage.getItem("token");
    if ( !token ) return;
    try {
        const response = await axios.post(
            `${BASE_URL}/user/password/update`,
            JSON.stringify(dataSent),
            {
              timeout: 12000,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              validateStatus: (status) => status >= 200 && status <= 505,
            }
          );

          if ( response.status != 200 ){
            return {
                statusCode: response.data?.statusCode,
                message: response.data?.message,
                data: response.data?.data || null, // You can optionally return the response data if needed
            };
          }

        const { data } = response;
        return data;
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';
            
            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null, // You can optionally return the response data if needed
            };
        } else {
            console.log(error);
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};


export const requestDemo = async (dataSent) => {
    try {
        const response = await axios.post(
            `${BASE_URL}/user/request_demo`,
            JSON.stringify(dataSent),
            {
              timeout: 12000,
              headers: {
                "Content-Type": "application/json",
              },
              validateStatus: (status) => status >= 200 && status <= 505,
            }
          );

          if ( response.status != 200 ){
            return {
                statusCode: response.data?.statusCode,
                message: response.data?.message,
                data: response.data?.data || null,
            };
          }

        const { data } = response;
        return data;
    } catch (error) {
        // Check if error is an AxiosError
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 0;
            const message = error.message || 'Unknown error occurred';
            
            return {
                statusCode: statusCode,
                message: message,
                data: error.response?.data || null,
            };
        } else {
            console.log(error);
            return { statusCode: 0, message: 'An unexpected error occurred' };
        }
    }
};
