export const Icon1 = ({ isSelected }: { isSelected: boolean }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* First Path */}
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M12.6097 11.5292C12.6097 11.5292 12.6643 11.4925 12.7514 11.4299C14.0569 10.4799 14.875 9.1032 14.875 7.57053C14.875 4.7132 12.0204 2.39453 8.50142 2.39453C4.981 2.39453 2.125 4.7132 2.125 7.57053C2.125 10.4292 4.97958 12.6679 8.5 12.6679C8.80033 12.6679 9.29333 12.6492 9.979 12.6119C10.8729 13.1585 12.1777 13.6072 13.3195 13.6072C13.673 13.6072 13.8394 13.3339 13.6127 13.0552C13.2685 12.6579 12.7939 12.0212 12.6097 11.5285V11.5292Z" 
      stroke={isSelected ? "white" : "#001F73"} 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    {/* Other paths */}
  </svg>
);  export default Icon1;


export const Icon2 = ({ isSelected }: { isSelected: boolean }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect 
      x="3.54297" 
      y="2" 
      width="9.91667" 
      height="12" 
      rx="2" 
      stroke={isSelected ? "white" : "#28C76F"} 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <rect 
      x="3.54297" 
      y="2" 
      width="9.91667" 
      height="12" 
      rx="2" 
      stroke="white" 
      strokeOpacity="0.25" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 4.66536H10.625" 
      stroke={isSelected ? "white" : "#28C76F"} 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 4.66536H10.625" 
      stroke="white" 
      strokeOpacity="0.25" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 7.33333H10.625" 
      stroke={isSelected ? "white" : "#28C76F"} 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 7.33333H10.625" 
      stroke="white" 
      strokeOpacity="0.25" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 10.0013H9.20833" 
      stroke={isSelected ? "white" : "#28C76F"} 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
    <path 
      d="M6.375 10.0013H9.20833" 
      stroke="white" 
      strokeOpacity="0.25" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
    />
  </svg>

   
  );  



 export const Icon3 = ({ isSelected }: { isSelected: boolean }) => {
  const color = isSelected ? "white" : "#FF9F43"; // Change colors based on the isActive prop

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.83203"
        y="2.66797"
        width="4.25"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="9.91797"
        y="2.66797"
        width="4.25"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.83203"
        y="9.33203"
        width="4.25"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="9.91797"
        y="9.33203"
        width="4.25"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Icon4 = ({ isSelected }: { isSelected: boolean }) => {
    const color = isSelected ? "white" : "#FC5555"; 

 return (
 
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.125 12.668C4.48587 11.3346 5.66667 10.0013 5.66667 8.66797C5.66667 6.66797 4.95833 6.66797 4.25 6.66797C3.54167 6.66797 2.81067 7.3913 2.83333 8.66797C2.85742 10.0333 4.00775 10.586 4.60417 11.3346C5.66667 12.668 6.375 13.0013 7.08333 12.0013C7.55579 11.3346 7.90996 10.7793 8.14583 10.3346C8.85417 11.89 9.79837 12.668 10.9792 12.668H12.75"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 12.668C4.48587 11.3346 5.66667 10.0013 5.66667 8.66797C5.66667 6.66797 4.95833 6.66797 4.25 6.66797C3.54167 6.66797 2.81067 7.3913 2.83333 8.66797C2.85742 10.0333 4.00775 10.586 4.60417 11.3346C5.66667 12.668 6.375 13.0013 7.08333 12.0013C7.55579 11.3346 7.90996 10.7793 8.14583 10.3346C8.85417 11.89 9.79837 12.668 10.9792 12.668H12.75"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1654 11.3333V3.33333C14.1654 2.586 13.5427 2 12.7487 2C11.9547 2 11.332 2.586 11.332 3.33333V11.3333L12.7487 12.6667L14.1654 11.3333Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1654 11.3333V3.33333C14.1654 2.586 13.5427 2 12.7487 2C11.9547 2 11.332 2.586 11.332 3.33333V11.3333L12.7487 12.6667L14.1654 11.3333Z"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.332 4.66536H14.1654"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.332 4.66536H14.1654"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);}



export const Icon5 = ({
    isSelected,
    color = isSelected ? "white" : "#7B61FF",
}: {
    isSelected: boolean;
    color?: string;
}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6241 4.0013H10.6311"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6241 4.0013H10.6311"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.125"
            y="2"
            width="12.75"
            height="9.33333"
            rx="3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.125"
            y="2"
            width="12.75"
            height="9.33333"
            rx="3"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.66794L4.95833 6.00127C5.61581 5.40583 6.42586 5.40583 7.08333 6.00127L9.91667 8.66794"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.66794L4.95833 6.00127C5.61581 5.40583 6.42586 5.40583 7.08333 6.00127L9.91667 8.66794"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.20703 7.99866L10.6237 6.66533C11.2812 6.06989 12.0912 6.06989 12.7487 6.66533L14.8737 8.66533"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.20703 7.99866L10.6237 6.66533C11.2812 6.06989 12.0912 6.06989 12.7487 6.66533L14.8737 8.66533"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66703 14.0013H5.67411"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.66703 14.0013H5.67411"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.49906 14.0013H8.50615"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.49906 14.0013H8.50615"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.335 14.0013H11.3421"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.335 14.0013H11.3421"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


export const Icon6  = ({
   isSelected,
    color = isSelected ? "white" : "#C2B013",
}: {
    isSelected: boolean;
    color?: string;
}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.125 8.0013H4.95833L7.08333 13.3346L9.91667 2.66797L12.0417 8.0013H14.875"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.125 8.0013H4.95833L7.08333 13.3346L9.91667 2.66797L12.0417 8.0013H14.875"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);



export const Icon7 = ({
    isSelected,
    color = isSelected ? "white" : "#22A9D4",
}: {
    isSelected: boolean;
    color?: string;

}) => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="2.83203"
            y="2.66797"
            width="4.25"
            height="3.33333"
            rx="1.66667"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="2.66797"
            width="4.25"
            height="3.33333"
            rx="1.66667"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="8.66797"
            width="4.25"
            height="4.66667"
            rx="2"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.83203"
            y="8.66797"
            width="4.25"
            height="4.66667"
            rx="2"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="9.91797"
            y="2.66797"
            width="4.25"
            height="10.6667"
            rx="2"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="9.91797"
            y="2.66797"
            width="4.25"
            height="10.6667"
            rx="2"
            stroke="white"
            strokeOpacity="0.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);





export const Icon8 = ({  isSelected}:{isSelected:boolean}) => {
    // Define colors based on the isActive prop
    const strokeColor = isSelected ? 'white' : '#333237'; // Change these colors as needed
  

    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           
        >
            <path
                d="M7.79167 12.6654H3.54167C2.75926 12.6654 2.125 12.0684 2.125 11.332V4.66536C2.125 3.92898 2.75926 3.33203 3.54167 3.33203H13.4583C14.2407 3.33203 14.875 3.92898 14.875 4.66536V7.33203"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.79167 12.6654H3.54167C2.75926 12.6654 2.125 12.0684 2.125 11.332V4.66536C2.125 3.92898 2.75926 3.33203 3.54167 3.33203H13.4583C14.2407 3.33203 14.875 3.92898 14.875 4.66536V7.33203"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="9.33203"
                width="4.95833"
                height="3.33333"
                rx="1"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="9.91797"
                y="9.33203"
                width="4.95833"
                height="3.33333"
                rx="1"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


export const Icon9 = ({ isSelected }: { isSelected: boolean }) => {
    // Define colors based on the isSelected prop
    const strokeColorMain = isSelected ? 'white' : 'black';
    const strokeOpacity = isSelected ? 0.25 : 0.25;

    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.625 6.66577L13.85 5.14844C14.0696 5.0452 14.3302 5.05625 14.539 5.17764C14.7477 5.29903 14.8749 5.51348 14.875 5.74444V10.2538C14.8749 10.4847 14.7477 10.6992 14.539 10.8206C14.3302 10.942 14.0696 10.953 13.85 10.8498L10.625 9.33244V6.66577Z"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.625 6.66577L13.85 5.14844C14.0696 5.0452 14.3302 5.05625 14.539 5.17764C14.7477 5.29903 14.8749 5.51348 14.875 5.74444V10.2538C14.8749 10.4847 14.7477 10.6992 14.539 10.8206C14.3302 10.942 14.0696 10.953 13.85 10.8498L10.625 9.33244V6.66577Z"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2.125"
                y="4"
                width="8.5"
                height="8"
                rx="1.41667"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="2.125"
                y="4"
                width="8.5"
                height="8"
                rx="1.41667"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.96094 7.9974H7.79427"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.96094 7.9974H7.79427"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.3776 6.67188V9.33854"
                stroke={strokeColorMain}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.3776 6.67188V9.33854"
                stroke="black"
                strokeOpacity={strokeOpacity}
                strokeWidth="1.0625"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};




