/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { NewContentProps, newGenerationHomeDataProps } from '../interface';
import BackButton from '@src/components/BackButton';
import { newGenerationHomeData } from '../constants';
import chevronRight from '@icons/chevron-right.svg';
import WorkSheetGenerator from '../sub_pages/worksheet_generator';
import ClassLetter from '../sub_pages/class_letter';
import TextReWriter from '../sub_pages/text_rewriter';
import Report from '../sub_pages/reports';
import { useNavigate, useOutletContext } from 'react-router';
import QuoteOfTheDay from '../sub_pages/quote';
import HooksGenerator from '../sub_pages/hooks_generator';
import ExitTicketGenerator from '../sub_pages/exit_ticket_generator';
import SlideMaker from '../../slide';
import useFormStore from '@src/providers/formStore';

import { Import } from 'lucide-react';
import './global.scss';
import TextSummarizer from '../sub_pages/text_summarizer';
import { ThemeContext } from '@src/theme_provider/theme';
import "./global.scss";
import { RightOutlined } from '@ant-design/icons';

const NewGenerateLetter = ({
    setShowSidebar,
    onGenerate,
    setPageName,
    currentIndex = 0,
    setTopic,
    setPageNumber,
}: NewContentProps) => {
    const [currentPage, setCurrentPage] = useState<number>(currentIndex);
    const [contentOptions, setContentOptions] = useState<newGenerationHomeDataProps[]>(newGenerationHomeData);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const resetSlide = useFormStore((state) => state.goToStep);
    const { savedTheme, toggleTheme } = useContext(ThemeContext);
    const { subPage, setSubPage,showHistoryModal, setResponse } = useOutletContext<any>();

    useEffect(()=>{
        if(currentPage === 0){setSubPage('')}
    },[subPage])

    const setSubPageName = (index: number) => {
        switch (index) {
            case 0:
                setSubPage('');
                break;
            case 1:
                setSubPage('Text Rewriter');
                break;
            case 2:
                setSubPage('Worksheet Generator');
                break;
            case 3:
                setSubPage('Quote of the Day');
                break;
            case 4:
                setSubPage('Report Card Comment');
                break;
            case 5:
                setSubPage('Class Newsletter');
                break;
            case 6:
                setSubPage('Lesson Hook');
                break;
            case 7:
                setSubPage('Exit Ticket');
                break;
            case 8:
                setSubPage('Slide Maker');
                break;
            case 9:
                setSubPage('Assesment Generator');
                break;
           
            default:
                console.log('Invalid page');
                setSubPage('');
        }
        console.log('SubPage', subPage);
    };



    useEffect(() => {
        if (user.plan && user.plan?.restrictions) {
            const canUseLetter = user.plan.restrictions.letterWriter;
            const canUseArticleWritter = user.plan.restrictions.articleWriter;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else if (user.freeTrialAvailable) {
            const canUseLetter = true;
            const canUseArticleWritter = true;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else {
            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer') return false;
                if (e.label === 'article') return false;

                return true;
            });

            setContentOptions(contentOptions);
        }
    }, [window.location.pathname]);

    return (
        <div
            className={`w-full flex flex-col h-auto md:h-screen  overflow-y-scroll  ${currentPage > 0 ? 'pt-5 pb-20' : 'pt-7 pb-2'} px-[2rem] gap-y-2`}
        >
            {currentPage > 0 && currentPage !== 8 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(null);
                        setPageName('');
                        setResponse('');
                        console.log('hdjdd');
                        resetSlide(0);
                        setSubPage('');
                    }}
                />
            )}
            {currentPage == 1 && <TextReWriter onGenerate={(e, t) => onGenerate(e, t)} setTopic={(e) => {}} />}
            {currentPage == 2 && (
                <WorkSheetGenerator setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 3 && (
                <QuoteOfTheDay setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 4 && <ClassLetter setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />}
            {currentPage == 5 && <Report setTopic={(e) => setTopic(e)} onGenerate={(e, t, p) => onGenerate(e, t, p)} />}
            {currentPage == 6 && (
                <HooksGenerator setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 7 && (
                <ExitTicketGenerator setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 8 && <SlideMaker setCurrentPage={setCurrentPage} />}
            
            {currentPage == 0 && (
                <div className="w-full flex flex-col  items-center justify-center">
                    <h2 className="self-start md:self-center dark:text-white font-medium text-[2rem] md:text-[2.3rem] text-[var(--gmind-black)] text-center">
                        Generate Different Class Resources
                    </h2>
                    <p className="mx-0 md:mx-20 self-start dark:text-gray-300 md:self-center text-[1.125rem] md:text-[0.8rem] text-[var(--gmind-white60)] mb-2 text-center">
                        {' '}
                        Explore standardized content tailored to your needs! Click to unlock resources, carefully
                        curated to align with academic standards, tailored to meet your unique needs and goals
                    </p>

                    <div className="flex flex-wrap justify-center items-center  gap-x-2 my-3 w-full mb-20">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPageName(item.pageName);
                                    setShowSidebar(true);
                                    setSubPage(item.pageName);
                                    if (item.naviagatePage) {
                                        navigate(`/dashboard/${item.label}`);
                                        return;
                                    }
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                }}
                                className={`w-full ${showHistoryModal? 'lg:w-[calc(43.33%-8px)]':'lg:w-[calc(33.33%-8px)] md:w-[65%] lg:w-[55%]'} dark:bg-transparent dark:shadow-lg dark:hover:shadow-gray-300 flex flex-row items-center h-[6.5rem] px-2.5 py-2.5 mb-3 bg-white shadow shadow-md rounded-lg 
                 cursor-pointer hover:shadow-2xl`}
                                style={{
                                    border: savedTheme==='dark'?'2px solid white':  '1px solid var(--gmind-border-black8)',
                                }}
                            >
                                <div className="w-full flex items-center justify-between gap-x-2">
                                    <div className="flex gap-x-2 items-center">
                                        {item.pageName.toLowerCase() === 'report' ? (
                                            <div className="flex flex-col items-center justify-center w-[3rem] h-[2.5rem] bg-[var(--gmind-black)] dark:bg-transparent rounded-[0.5rem]">
                                                {item.imgElement ?? (
                                                    <img
                                                        className="w-[3rem] md:w-auto h-[3rem]"
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className={`${item.pageIndex == 10 ? 'w-[35%]': 'w-auto'}`}>
                                                {index <= 4 ? (
                                                    <img
                                                        className={`w-[3rem] md:w-auto h-auto`}
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                ) : (
                                                    <div
                                                        className={`flex flex-col items-center justify-center ${item.pageIndex == 10 ? 'w-full' : 'w-[3rem]' }  h-[2.5rem] ${index == 5 ? 'bg-[#28C76F20] dark:bg-transparent' : item.pageIndex == 10 ? 'bg-black': 'bg-[#43B0FF20]'} rounded-[0.5rem]`}
                                                    >
                                                        {item.imgElement ?? (
                                                            <img
                                                                className="w-[3rem] md:w-auto h-[1.5rem]"
                                                                src={item.img}
                                                                alt={item.head}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="flex flex-col gap-y-1">
                                            <h5 className="font-bold dark:text-white text-[0.7rem]  md:text-[0.9rem] text-[var(--gmind-light-black)]">
                                                {item.head}
                                            </h5>
                                            <p className="font-normal dark:text-gray-300 text-[0.6rem] md:text-[0.6rem] text-[var(--gmind-white60)]">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    <RightOutlined className="text-[var(--gmind-black)] dark:text-white text-[0.7rem]" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewGenerateLetter;
