import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Button, Form, Input, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import { useNavigate } from 'react-router';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import TextArea from 'antd/es/input/TextArea';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import { LessonMaterialProps } from '../interface';
import { gradeOptions } from '../../curriculum/constants';
import "./style.scss";


const ExitTicketGenerator = ({ onGenerate, setTopic  }: LessonMaterialProps) => {
    const [response, setResponse] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [celebrations, setCelebrations] = useState('');
    const [announcements, setAnnouncements] = useState('');
    const [additionalContent, setAdditionalContent] = useState('');

    let grades = gradeOptions();


    const onFinish = (values: any) => {
        let promptMessage = '';

        const subject = values.subject;
        const topic = values.topic;
        const objective = values.objective;

        // check if all data is passed
        if (!subject || !topic || !objective ) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = `
            Subject: ${subject}
            Topic: ${topic}
            Objective: ${objective}
        `;

        promptMessage = `Generate an exit ticket for my students with the following information: ${informationData}`;

        onGenerate(promptMessage, false);
    };

   

    const handleMicClick = (setter: React.Dispatch<React.SetStateAction<string>>) => {
      
    };

 

    // Other useEffects and functions...

    return (
        <Form onFinish={onFinish} className="flex flex-col py-4 px-10 space-y-4" layout="vertical">
            <h1 className="text-xl font-bold dark:text-white">Exit Ticket</h1>
            <p className='dark:text-gray-300'>Develop effective exit strategies to evaluate students learning</p>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <Form.Item required={true} label="Subject would you like to create a ticket for:" name="subject" className='w-full ' >
                    <div className="flex items-start border border-gray-700 rounded">
                        {/* <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                            <button
                                type="button"
                                onClick={() => handleMicClick(setCelebrations)}
                                className="p-2 bg-none border-none text-gray-500 hover:text-green-500 transition-colors duration-200"
                            >
                                <AudioOutlined />
                            </button>
                            <PaperClipOutlined />
                        </div> */}
                        <Input
                            // rows={2}
                            className="p-2  w-full focus:outline-none"
                            placeholder="Enter a subject you would like to create a hook for"
                            // value={celebrations}
                            style={{ outline: 'none', boxShadow: 'none' }}
                        />
                    </div>
                </Form.Item>

                <Form.Item required={true} label="Topic you'll be teaching?:" name="topic" className='w-full '>
                    <div className="flex items-start border dark:border-white border-gray-700 rounded">
                        {/* <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                            <button
                                type="button"
                                onClick={() => handleMicClick(setAnnouncements)}
                                className="p-2 bg-none border-none text-gray-500 hover:text-green-500 transition-colors duration-200"
                            >
                                <AudioOutlined />
                            </button>
                            <PaperClipOutlined />
                        </div> */}
                        <Input
                            // rows={2}
                            className="p-2 rounded-none border-none w-full focus:outline-none"
                            placeholder="Enter a topic"
                            style={{ outline: 'none', boxShadow: 'none' }}
                        />
                    </div>
                </Form.Item>
            </div>
            


            <Form.Item label="Learning objectives of your lesson?: " required={true} name="objective" className='w-full md:w-[47%]'>
                <div className="flex items-start border dark:border-white border-gray-700 rounded">
                    {/* <div className="flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <button
                            type="button"
                            onClick={() => handleMicClick(setAdditionalContent)}
                            className="p-2 bg-none border-none text-gray-500 hover:text-green-500 transition-colors duration-200"
                        >
                            <AudioOutlined />
                        </button>
                        <PaperClipOutlined />
                    </div> */}
                    <TextArea
                        // value={additionalContent}
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter learning objective"
                        style={{ outline: 'none', boxShadow: 'none' }}
                    />
                </div>
            </Form.Item>

            <Form.Item>
                <button 
                    type="submit"
                    className="no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </Form.Item>
        </Form>
    );
};

export default ExitTicketGenerator;
