// Define the interface for an instructional model
export  interface InstructionalModel {
    instruction: string;
    description: string;
    keyStrategies: string;
    applicability: string;
}
  
  // Array of instructional models
const instructionalModels: InstructionalModel[] = [
    {
      instruction: "Direct Instruction",
      description: "Teacher-centered explicit teaching",
      keyStrategies: "Lecture, demonstration, guided practice",
      applicability: "K12",
    },
    {
      instruction: "Project-Based Learning",
      description: "Student-centered inquiry-based",
      keyStrategies: "Real-world projects, collaboration, problem-solving",
      applicability: "K12, College",
    },
    {
      instruction: "Flipped Classroom",
      description: "Reverses traditional lecture-homework format",
      keyStrategies: "Pre-recorded lectures, in-class activities",
      applicability: "K12, College",
    },
    {
      instruction: "Personalized Learning",
      description: "Tailors instruction to individual needs",
      keyStrategies: "Flexible pacing, adaptive technology",
      applicability: "K12",
    },
    {
      instruction: "Inquiry-Based Learning",
      description: "Encourages student investigation and discovery",
      keyStrategies: "Open-ended questions, exploration",
      applicability: "K12, College",
    },
    {
      instruction: "Collaborative Learning",
      description: "Students work together to achieve a common goal",
      keyStrategies: "Group projects, peer-to-peer learning",
      applicability: "K12, College",
    },
    {
      instruction: "Technology Integration",
      description: "Incorporates technology to enhance instruction",
      keyStrategies: "Digital tools, multimedia resources",
      applicability: "K12, College",
    },
    {
      instruction: "Differentiated Instruction",
      description: "Adapts instruction to meet diverse learning needs",
      keyStrategies: "Varied assessments, learning centers",
      applicability: "K12",
    },
    {
      instruction: "Problem-Based Learning",
      description: "Presents real-world scenarios for students to solve",
      keyStrategies: "Case studies, group work, critical thinking",
      applicability: "College",
    },
    {
      instruction: "Blended Learning",
      description: "Combines traditional and online instruction",
      keyStrategies: "Flexible learning paths, online resources",
      applicability: "K12, College",
    },
    {
      instruction: "Lecture-Discussion",
      description: "Combines traditional lecture with student discussion",
      keyStrategies: "Lecture, group discussion, debate",
      applicability: "College",
    },
    {
      instruction: "Experiential Learning",
      description: "Hands-on experience and application",
      keyStrategies: "Internships, service learning, fieldwork",
      applicability: "College",
    },
    {
      instruction: "Flexible Learning",
      description: "Adapts instruction to meet diverse learning needs",
      keyStrategies: "Online, hybrid, and face-to-face options",
      applicability: "College",
    },
    {
      instruction: "Active Learning",
      description: "Engages students in active participation and engagement",
      keyStrategies: "Discussions, debates, role-playing, simulations",
      applicability: "K12, College",
    },
    {
      instruction: "Self-Directed Learning",
      description: "Encourages students to take ownership of their learning",
      keyStrategies: "Independent study, research, project-based learning",
      applicability: "College",
    },
    {
      instruction: "Five-Step Model",
      description: "Engages students in a cyclical process of learning",
      keyStrategies: "Engage, explore, explain, elaborate, evaluate",
      applicability: "K12, College",
    },
    {
      instruction: "Five E Model",
      description: "Incorporates engagement, exploration, explanation, elaboration, and evaluation",
      keyStrategies: "Student-centered, inquiry-based",
      applicability: "K12, College",
    },
    {
      instruction: "Workshop Model",
      description: "Student-centered hands-on learning",
      keyStrategies: "Rotations, stations, project-based learning",
      applicability: "K12",
    },
    {
      instruction: "Concept Attainment Model",
      description: "Encourages students to discover concepts through examples",
      keyStrategies: "Inductive reasoning, concept formation",
      applicability: "K12, College",
    },
    {
      instruction: "Universal Design Learning (UDL)",
      description: "Incorporates multiple means of representation, expression, and engagement",
      keyStrategies: "Flexible, adaptable, inclusive",
      applicability: "K12, College",
    },
    {
      instruction: "Madeline Hunter Model",
      description: "Emphasizes lesson planning and instructional strategies",
      keyStrategies: "Anticipatory set, instruction, guided practice",
      applicability: "K12",
    },
    {
      instruction: "Differentiated Model",
      description: "Adapts instruction to meet diverse learning needs",
      keyStrategies: "Learning centers, flexible pacing, varied assessments",
      applicability: "K12",
    },
    {
      instruction: "Seminar Model",
      description: "Student-led discussions and presentations",
      keyStrategies: "Facilitated learning, critical thinking",
      applicability: "College",
    },
    {
      instruction: "Collaborative Model",
      description: "Teacher-student and student-student collaboration",
      keyStrategies: "Shared learning goals, teamwork",
      applicability: "K12, College",
    },
    {
      instruction: "Case Study Model",
      description: "Real-world scenarios for analysis and problem-solving",
      keyStrategies: "Critical thinking, application",
      applicability: "College",
    },
    {
      instruction: "Hybrid/Blended Model",
      description: "Combines face-to-face and online instruction",
      keyStrategies: "Flexible learning paths, online resources",
      applicability: "K12, College",
    },
    {
      instruction: "Discussion Model",
      description: "Encourages student participation and dialogue",
      keyStrategies: "Facilitated learning, critical thinking",
      applicability: "K12, College",
    },
    {
      instruction: "Gamification Instruction",
      description: `Complete a quest to earn points and badges in topic.,
      Participate in a game-based activity to practice skill.,
      Engage in a friendly competition to solve problem`,
      keyStrategies: "Lecture, demonstration, guided practice",
      applicability: "K12",
    },
];
  
// Export the instructional models array
export default instructionalModels;