import { useEffect, useState } from "react";
import { Modal, Form, Input } from 'antd';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";
import { toast } from "react-toastify";
import { requestDemo } from "@src/core/endpoints/user";


interface requestDemoModalProps {
    isVisible: boolean;
    onCancel: () => void;
}

const RequestDemoModal = ({isVisible, onCancel}: requestDemoModalProps ) => {
    const [isModalVisible, setIsModalVisible] = useState(isVisible);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const canProceed = () : boolean => {
        return form.getFieldValue('name') && form.getFieldValue('organization') && form.getFieldValue('position') && form.getFieldValue('request_details')
    }

    useEffect(() => {
        setIsModalVisible(isVisible);
    }, [isVisible])

    const onRequestDemo = async () => {
        try {
            if ( !canProceed() ){
                toast.error('Kindly enter all fields');
                return;
            }

            setLoading(true);
            const dataToSend = {
                'name': form.getFieldValue('name'),
                'organization': form.getFieldValue('organization'),
                'position': form.getFieldValue('position'),
                'details': form.getFieldValue('request_details'),
            }
            
            const updated = await requestDemo(dataToSend);

            if ( updated.statusCode != 1 ){
                toast.error(updated.message ?? 'Error updating details');
            }else{
                toast.success('Request sent successfully');
                setIsModalVisible(false);
            }

        } catch (error) {
            
        }finally{
            setLoading(false);
        }

    }

    return (
        <div>
          <Modal
            open={isModalVisible}
            onCancel={() => { onCancel();}}
            closable={true}
            maskClosable={false}
            width={500}
            footer={[
                <CustomButton disabled={loading} variant={'outline'} key="ok" className={`${ canProceed() ? 'bg-[var(--gmind-orange)] text-white' : 'bg-[#A5A3AE30] text-[#333237]' }`} onClick={onRequestDemo}>
                    Update
                </CustomButton>
            ]}
            className="centered-footer-modal"
          >
            <div className="p-5 flex flex-col gap-y-4">
                <h2 className="text-black font-bold text-[1.5rem] text-center" >Request Demo</h2>
                <Form
                    form={form}
                    layout="vertical" 
                    className="flex flex-col gap-y-2 gap-x-3 w-full" 
                    onFinish={() => {}} 
                >
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'name'}
                        label= {
                            <label className="text-black">Name</label>
                        }
                    >       
                        <input
                            className='w-full'
                            placeholder={'Enter your name'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                        />
                        
                    </Form.Item>
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'organization'}
                        label= {
                            <label className="text-black">Organization</label>
                        }
                    >       
                        <input
                            className='w-full'
                            placeholder={'Enter your organization name'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                        />
                        
                    </Form.Item>
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'position'}
                        label= {
                            <label className="text-black">Position</label>
                        }
                    >       
                        <input
                            className='w-full'
                            placeholder={'kindly fill your position'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                        />
                        
                    </Form.Item>
                    <Form.Item
                        className={`w-full md:w-full mb-1`}
                        name={'request_details'}
                        label= {
                            <label className="text-black">Request Details</label>
                        }
                    >       
                        <textarea
                            className='w-full p-2 border border-gray-500 focus:outline-none'
                            placeholder={'kindly fill your request details'} 
                            style={{
                                border: '1px solid #E5E8F1',
                                borderColor: '#E5E8F1 !important',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                                outline: 'none', 
                                boxShadow: 'none'
                            }}
                            rows={4}
                        />
                        
                    </Form.Item>  
                </Form>
            </div>
            
          </Modal>
        </div>
      );
}


export default RequestDemoModal;