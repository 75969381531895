"use client";
import { useEffect, useRef, useState } from "react";
import { PricingCard, PricingSwitch } from "../components/pricing";
import { plans } from "../constants";
import { CurrencyDropDown } from "../components/currencyDropdown";
import Footer from "../../components/footer";
import { getRegion, getUrlPath } from "@src/utils/app_functions";
import Loading from "@src/components/Loading";
import { Helmet } from "react-helmet-async";
import RequestPricing from "@src/pages/dashboard/pages/dashboard_pricing/components/request_pricing";
import PromoHeader from "../components/promo_price";
import Header from "@src/pages/acquisition/components/header";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { pricingData } from "../../strutured_data/structured_data";


function calculatePercentage(price: number): number {
  const percentage = 0.70; // 70%
  return price * percentage;
}

export default function PricingHome() {
  const [pricingPeriod, setPricingPeriod] = useState<
    "monthly" | "quarterly" | "yearly"
  >("monthly");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [visible, setVisible] = useState(false);
  const [isPromo, setIsPromo] = useState(true);
  const location = useLocation();
    const [isRoot, setIsRoot] = useState<boolean>(location.pathname === '/');
    const [isFeatures, setIsFeatures] = useState<boolean>(location.pathname === '/features');
    const faqRef = useRef<HTMLDivElement | null>(null);
    const featuresRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(true);
    const [showFrame, setShowFrame] = useState<boolean>(false);
    const [editorView, setEditorView] = useState<boolean>(false);
    const path = getUrlPath().pagename
    const [isHome, setIsHome] = useState(false);
    const navigate = useNavigate();

    const {
      setShowTop,
  } = useOutletContext<any>();

  useEffect(() => {
    if ( localStorage.getItem('token') ){
      setShowTop(true)
    }else{
      setShowTop(false)
    }
  }, [window.location.pathname]);

  useEffect(() => {
    getRegion().then((data) => {
      setSelectedCurrency(data.location)
    });
  }, []);
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
};

  const handleCurrencyChange = (value: string) => {
    setSelectedCurrency(value);
  };

  const togglePricingPeriod = (value: string) => {
    switch (value) {
      case "0":
        setPricingPeriod("monthly");
        break;
      case "1":
        setPricingPeriod("quarterly");
        break;
      case "2":
        setPricingPeriod("yearly");
        break;
      default:
        setPricingPeriod("monthly");
    }
  };

  return (
    selectedCurrency == '' ? <Loading />:
    <div className="flex flex-col gap-y-2">
      <Helmet>
          <script type="application/ld+json">{JSON.stringify(pricingData)}</script>
          <meta name="google-site-verification"
          content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
          <meta name="description" content="Choose a plan that fits your needs and access powerful content creation, research, and business features. Boost efficiency with affordable options tailored for professionals" />
          <title>GMind AI Pricing | Affordable Plans for Every Professional</title>
          <link rel="canonical" href="https://gmind.ai/pricing" />
          <meta name="keywords" content="gmind, ai, chat" />
          <meta
            name="title"
            content="GMind AI Pricing | Affordable Plans for Every Professional"     
          />
      </Helmet>
      <div className="mr-5 self-end flex flex-wrap sm:flex-nowrap justify-center sm:space-x-5 items-end pt-3 sm:pt-0">
        {/* <CurrencyDropDown onValueChange={handleCurrencyChange} /> */}


      </div>
      <div className={`w-full bg-[#001F73]`}  
        style={
          {display: localStorage.getItem('token') ? 'none' : 'flex'}
        }>
          <Header
              onClickFaQ={() => navigate('/')}
              onClickFeature={() =>
                  isFeatures ? scrollToSection(featuresRef) : navigate('/features')
              }
              onClickPricing={() =>
                  navigate('/pricing')
              }
              isLoggedIn={false}
              isPromo={false}
              showHeader={true}
          />
      </div>

      {!visible && <PromoHeader closeClick={()=> setVisible(true)} showClose={true} />}
      <div className="bg-pricing-hero pb-0 w-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center max-w-[21.5rem] sm:max-w-[31.5rem] text-center space-y-1 py-[0.1rem]">
          <p className="text-[1.8rem] font-medium text-primary">Affordable pricing</p>
          <h3 className="text-[2rem] font-medium text-primary">
            Choose the best GMIND AI plan
          </h3>
          <p className="text-[1rem] text-primary-light">
            We offer three comprehensive packages to cater to your needs:
            <br /> Basic, Premium, and Supported/Partners.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap sm:flex-nowrap justify-center sm:space-x-8 items-end pt-8 sm:pt-0">
        <PricingSwitch onSwitch={togglePricingPeriod} />
      </div>
      <div className="py-14 px-5 sm:px-0 flex flex-col">
        <section className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-5">
          {plans.map((plan, index) => {
            return ( selectedCurrency == "usd" && index > 0 || selectedCurrency == "ngn" ) && (
              <PricingCard
                key={plan.title}
                {...plan}
                pricingPeriod={pricingPeriod}
                selectedCurrency={selectedCurrency}
                isPromo={isPromo}

              />
            );
          })}
        </section>

        <div className="flex flex-row justify-center pt-10">
        <RequestPricing/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
