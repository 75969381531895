import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';

interface DialogProps {
    visible: boolean;
    title: string;
    inputPlaceholder: string;
    saveLabel: string;
    onCancel: () => void;
    onSave: (value: string) => void;
}

const ReusableDialog = ({ visible, title, inputPlaceholder, saveLabel, onCancel, onSave }: DialogProps) => {
    const [value, setValue] = useState('');

    const handleSave = () => {
        onSave(value);
        setValue('');
    };

    return (
        <div style={{ margin: 'auto' }}>
            <Modal
                title={title}
                open={visible}
                onCancel={onCancel}
                footer={null}
                width={294}
                style={{ top: 423, left: 5001, borderRadius: 6, padding: '24px 16px', gap: 8 }}
            >
                <Input.TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={inputPlaceholder}
                    rows={4}
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '23px' }}>
                    <Button type="primary" style={{ backgroundColor: '#E55109' }} onClick={handleSave}>
                        {saveLabel}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ReusableDialog;
