import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { gradeOptions } from '../../curriculum/constants';
import "./style.scss";

const WorksheetGenerator = ({ onGenerate, setTopic }: LessonMaterialProps) => {
    let grades = gradeOptions();
    const onFinish = (values: any) => {
        let promptMessage = '';

        const gradeLevel = values.gradeLevel;
        const pointScale = values.pointScale;

        // check if all data is passed
        if (!gradeLevel || !pointScale  ) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = `
           Grade Level: ${gradeLevel}
           Topic: ${pointScale}
        `;

        promptMessage = `Create structured worksheet with this following information: ${informationData}`;

        onGenerate(promptMessage, false);
    };

    return (
        <Form className="flex flex-col py-4 px-10 dark:text-white space-y-4" onFinish={onFinish} layout="vertical">
            <h1 className="text-xl font-bold dark:text-white">Worksheet Generator</h1>
            <p className='dark:text-gray-300'>Generate worksheet based on any topic or text.</p>

            <Form.Item label="Grade Level" name="gradeLevel" className='w-full md:w-[60%]' >
                <Select placeholder="Select grade level">
                    {
                        grades.map((item, index) => (
                            <Select.Option value={item}>{item}</Select.Option>
                        ))
                    }
                    {/* Add more options as needed */}
                </Select>
            </Form.Item>

            <Form.Item label="Topic or text" name="pointScale" className='w-full md:w-[60%]'>
                <div className="flex items-start border dark:border-white border-gray-700 rounded">
                    {/* <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined />

                        <PaperClipOutlined />
                    </div> */}
                    <TextArea
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter topic to generate worksheet for"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                    />
                </div>
            </Form.Item>

            {/* <Form.Item label="Standard / Objective" name="standardObjective" className='w-full md:w-[60%]' >
                <div className="flex items-start border border-gray-300 rounded">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined />

                        <PaperClipOutlined />
                    </div>
                    <TextArea
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter standard/objective"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                    />
                </div>
            </Form.Item>

            <Form.Item label="Assignment Description" name="assignmentDescription" className='w-full md:w-[60%]'>
                <div className="flex items-start border border-gray-300 rounded">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined />

                        <PaperClipOutlined />
                    </div>
                    <TextArea
                        rows={4}
                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter assignment description"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow
                    />
                </div>
            </Form.Item> */}

            <Form.Item>
                <button
                    type="submit"
                    className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </Form.Item>
        </Form>
    );
};

export default WorksheetGenerator;
