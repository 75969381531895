const Skeleton = () => {
    return (
        <section className="skeleton flex flex-col gap-3 mt-3 cursor-wait">
            <div className="h-3 rounded-lg bg-gray-200"></div>
            <div className="h-3 rounded-lg w-[90%] bg-gray-100"></div>
            <div className="h-3 rounded-lg w-[60%] bg-gray-50"></div>
        </section>
    );
};

export default Skeleton;