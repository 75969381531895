const CopySVG = ( {isDark, onClick}: {isDark:boolean; onClick: () => void;} ) => {
    return (
        <svg onClick={() => onClick()} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.66406" y="5.33594" width="8.5" height="8" rx="2" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3359 5.33073V3.9974C11.3359 3.26102 10.7017 2.66406 9.91927 2.66406H4.2526C3.4702 2.66406 2.83594 3.26102 2.83594 3.9974V9.33073C2.83594 10.0671 3.4702 10.6641 4.2526 10.6641H5.66927" stroke={isDark ? "white" :"black"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}


export default CopySVG;