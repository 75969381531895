import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { onGenerateWithAI } from '../../curriculum/functions';

const { TextArea } = Input;

interface BlogWriterLoadingStates {
    title: boolean;
    objective: boolean;
    targetAudience: boolean;
    toneStyle: boolean;
    keyTopics: boolean;
    introduction: boolean;
    contentStructure: boolean;
    mainPoints: boolean;
    callToAction: boolean;
    conclusion: boolean;
    multimediaElements: boolean;
}

interface BlogWriterFormValues {
    title: string;
    objective: string;
    targetAudience: string;
    toneStyle: string;
    keyTopics: string;
    introduction: string;
    contentStructure: string;
    mainPoints: string;
    callToAction: string;
    conclusion: string;
    multimediaElements: string;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
    onClickButton,
    checkIfCanGenerate,
}: {
    placeholder: string;
    uniqueKey: keyof BlogWriterLoadingStates;
    loadingStates: BlogWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<BlogWriterLoadingStates>>;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickButton: () => void;
    checkIfCanGenerate: () => boolean;
}) => {
    const handleButtonClick = () => {
        const canGenerate = checkIfCanGenerate();
        if (!canGenerate) return;
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        onClickButton();
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const BlogWriter = ({ onGenerate }: { onGenerate: (e: string) => void; }) => {
    const [form] = Form.useForm();
    const [pageOneValues, setPageOneValues] = useState<{ [key: string]: string }>({
        title: '',
        objective: '',
        targetAudience: '',
        toneStyle: '',
        keyTopics: '',
    });

    const socketKey = [
        'targetAudience',
        'toneStyle',
        'keyTopics',
        'introduction',
        'contentStructure',
        'mainPoints',
        'callToAction',
        'conclusion',
        'multimediaElements',
    ];
    const baseurl = BASE_URL;

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});

    const [loadingStates, setLoadingStates] = useState<BlogWriterLoadingStates>({
        title: false,
        objective: false,
        targetAudience: false,
        toneStyle: false,
        keyTopics: false,
        introduction: false,
        contentStructure: false,
        mainPoints: false,
        callToAction: false,
        conclusion: false,
        multimediaElements: false,
    });

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach((item) => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach((item) => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                const previousValue = form.getFieldValue(item);
                form.setFieldValue(item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = (values: BlogWriterFormValues) => {
        const { title, objective, targetAudience, toneStyle, keyTopics } = pageOneValues;
        const { introduction, contentStructure, mainPoints, callToAction, conclusion, multimediaElements } = values;

        if (
            !title ||
            !objective ||
            !targetAudience ||
            !toneStyle ||
            !keyTopics ||
            !introduction ||
            !contentStructure ||
            !mainPoints ||
            !callToAction ||
            !conclusion ||
            !multimediaElements
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const promptMessage = `
            Title: ${title}
            Objective: ${objective}
            Target Audience: ${targetAudience}
            Tone and Style: ${toneStyle}
            Key Topics and Keywords: ${keyTopics}
            Introduction and Hook: ${introduction}
            Content Structure: ${contentStructure}
            Main Points and Supporting Details: ${mainPoints}
            Call to Action: ${callToAction}
            Conclusion: ${conclusion}
            Multimedia Elements: ${multimediaElements}
        `;

        onGenerate(promptMessage);
    };

    const handleNext = () => {
        if (currentPage < 2) {
            form.validateFields().then(() => {
                setCurrentPage(currentPage + 1);
            });
            setPageOneValues({
                title: form.getFieldValue('title'),
                objective: form.getFieldValue('objective'),
                targetAudience: form.getFieldValue('targetAudience'),
                toneStyle: form.getFieldValue('toneStyle'),
                keyTopics: form.getFieldValue('keyTopics'),
            });
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                title: '',
                objective: '',
                targetAudience: '',
                toneStyle: '',
                keyTopics: '',
                introduction: '',
                contentStructure: '',
                mainPoints: '',
                callToAction: '',
                conclusion: '',
                multimediaElements: '',
            }}
        >
            <h1 className="text-xl dark:text-white font-bold font-Poppins mb-4 mt-4">Blog Writer</h1>
            <p className="text-md  dark:text-gray-300 font-Poppins mb-4">Create compelling blog posts with the help of this AI tool.</p>

            {currentPage === 1 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title is required' }]}>
                        <Input className='dark:placeholder-white-400' placeholder="Enter the title of the blog post" />
                    </Form.Item>
                    <Form.Item
                        label="Objective"
                        name="objective"
                        rules={[{ required: true, message: 'Objective is required' }]}
                    >
                        <Input placeholder="Describe the purpose of the blog post" />
                    </Form.Item>
                    <Form.Item
                        label="Target Audience"
                        name="targetAudience"
                        rules={[{ required: true, message: 'Target Audience is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Identify and tailor the content to your audience"
                            uniqueKey="targetAudience"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('targetAudience')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('targetAudience', e.target.value)} // Update form value on change
                            checkIfCanGenerate={() => {
                                const title = form.getFieldValue('title');
                                if (!title) {
                                    toast.error('Please enter a title before generating.');
                                    return false;
                                }
                                const objective = form.getFieldValue('objective');
                                if (!objective) {
                                    toast.error('Please enter an objective before generating.');
                                    return false;
                                }
                                return true;
                            }}
                            onClickButton={() => {
                                form.setFieldValue('targetAudience', '');
                                const targetAudience = `
                                Title:${form.getFieldValue('title')}
                                Objective:${form.getFieldValue('objective')}
                                `;
                                const promptMessage = `Generate a description for target audience based on the following details: ${targetAudience}. Kindly ensure to follow the details provided`;
                                const socket = sockets['targetAudience'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tone and Style"
                        name="toneStyle"
                        rules={[{ required: true, message: 'Tone and Style are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Choose an appropriate tone and style"
                            uniqueKey="toneStyle"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('toneStyle')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('toneStyle', e.target.value)} // Update form value on change
                            checkIfCanGenerate={() => {
                                const title = form.getFieldValue('title');
                                if (!title) {
                                    toast.error('Please enter a title before generating.');
                                    return false;
                                }
                                const objective = form.getFieldValue('objective');
                                if (!objective) {
                                    toast.error('Please enter an objective before generating.');
                                    return false;
                                }
                                const targetAudience = form.getFieldValue('targetAudience');
                                if (!targetAudience) {
                                    toast.error('Please enter a target audience before generating.');
                                    return false;
                                }
                                return true;
                            }}
                            onClickButton={() => {
                                form.setFieldValue('toneStyle', '');
                                const title = form.getFieldValue('title');
                                const objective = form.getFieldValue('objective');
                                const targetAudience = form.getFieldValue('targetAudience');
                                const toneStyle = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                `;
                                const promptMessage = `Generate a description for tone and style based on the following details: ${toneStyle}. Kindly ensure to follow the details provided`;
                                const socket = sockets['toneStyle'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Key Topics and Keywords"
                        name="keyTopics"
                        rules={[{ required: true, message: 'Key Topics are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Highlight the main topics and keywords"
                            uniqueKey="keyTopics"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('keyTopics')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('keyTopics', e.target.value)} // Update form value on change
                            checkIfCanGenerate={() => {
                                const title = form.getFieldValue('title');
                                if (!title) {
                                    toast.error('Please enter a title before generating.');
                                    return false;
                                }
                                const objective = form.getFieldValue('objective');
                                if (!objective) {
                                    toast.error('Please enter an objective before generating.');
                                    return false;
                                }
                                const targetAudience = form.getFieldValue('targetAudience');
                                if (!targetAudience) {
                                    toast.error('Please enter a target audience before generating.');
                                    return false;
                                }
                                const toneStyle = form.getFieldValue('toneStyle');
                                if (!toneStyle) {
                                    toast.error('Please enter a tone style before generating.');
                                    return false;
                                }
                                return true;
                            }}
                            onClickButton={() => {
                                const title = form.getFieldValue('title');
                                const objective = form.getFieldValue('objective');
                                const targetAudience = form.getFieldValue('targetAudience');
                                const toneStyle = form.getFieldValue('toneStyle');
                                const keyTopics = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                
                                `;
                                const promptMessage = `Generate a list of key topics and keywords based on the following details: ${keyTopics}. Kindly ensure to follow the details provided`;
                                const socket = sockets['keyTopics'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                </div>
            )}

            {currentPage === 2 && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Form.Item
                        label="Introduction and Hook"
                        name="introduction"
                        rules={[{ required: true, message: 'Introduction is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Write an engaging introduction"
                            uniqueKey="introduction"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('introduction')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('introduction', e.target.value)} // Update form value on change
                            checkIfCanGenerate={()=>{
                                return true;
                            }}
                            onClickButton={()=>{
                                form.setFieldValue('introduction','')

                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];

                                const introduction = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                `
                                const promptMessage = `Generate an introduction for a blog post based on the following details: ${introduction}. Kindly ensure to follow the details provided`;
                                const socket = sockets['introduction'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Content Structure"
                        name="contentStructure"
                        rules={[{ required: true, message: 'Content Structure is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Organize the post into a logical flow"
                            uniqueKey="contentStructure"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('contentStructure')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('contentStructure', e.target.value)} // Update form value on change
                            checkIfCanGenerate={()=>{
                                return true;
                            }}

                            onClickButton={()=>{
                                form.setFieldValue('contentStructure','')
                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];
                                const introduction = form.getFieldValue('introduction');
                                const contentStructure = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                Introduction:${introduction}
                                
                                `
                                const promptMessage = `Generate a content structure for a blog post based on the following details: ${contentStructure}. Kindly ensure to follow the details provided`;
                                const socket = sockets['contentStructure'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Main Points and Supporting Details"
                        name="mainPoints"
                        rules={[{ required: true, message: 'Main Points are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Develop core arguments with supporting details"
                            uniqueKey="mainPoints"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('mainPoints')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('mainPoints', e.target.value)} // Update form value on change
                            checkIfCanGenerate={()=>{
                                return true;
                            }}
                            onClickButton={()=>{
                                form.setFieldValue('mainPoints','')
                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];
                                const introduction = form.getFieldValue('introduction');
                                const contentStructure = form.getFieldValue('contentStructure');
                                const mainPoints = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                Introduction:${introduction}
                                Content Structure:${contentStructure}
                                `;
                                const promptMessage = `Generate main points and supporting details for a blog post based on the following details: ${mainPoints}. Kindly ensure to follow the details provided`;
                                const socket = sockets['mainPoints'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Call to Action (CTA)"
                        name="callToAction"
                        rules={[{ required: true, message: 'Call to Action is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Encourage the reader to take action"
                            uniqueKey="callToAction"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('callToAction')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('callToAction', e.target.value)} // Update form value on change
                            checkIfCanGenerate={()=>{
                                return true;
                            }}
                            onClickButton={()=>{
                                form.setFieldValue('callToAction','')
                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];
                                const introduction = form.getFieldValue('introduction');
                                const contentStructure = form.getFieldValue('contentStructure');
                                const mainPoints = form.getFieldValue('mainPoints');
                                const callToAction = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                Introduction:${introduction}
                                Content Structure:${contentStructure}
                                Main Points:${mainPoints}
                                `
                                const promptMessage = `Generate a call to action for a blog post based on the following details: ${callToAction}. Kindly ensure to follow the details provided`;
                                const socket = sockets['callToAction'];
                                onGenerateWithAI(socket, promptMessage);

                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Conclusion"
                        name="conclusion"
                        rules={[{ required: true, message: 'Conclusion is required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Summarize key points and close the article"
                            uniqueKey="conclusion"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('conclusion')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('conclusion', e.target.value)} // Update form value on change
                            checkIfCanGenerate={()=>{
                                return true;
                            }}
                            onClickButton={()=>{
                                form.setFieldValue('conclusion','')
                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];
                                const introduction = form.getFieldValue('introduction');
                                const contentStructure = form.getFieldValue('contentStructure');
                                const mainPoints = form.getFieldValue('mainPoints');
                                const callToAction = form.getFieldValue('callToAction');
                                const conclusion = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                Introduction:${introduction}
                                Content Structure:${contentStructure}
                                Main Points:${mainPoints}
                                Call to Action:${callToAction}
                                `
                                const promptMessage = `Generate a conclusion for a blog post based on the following details: ${conclusion}. Kindly ensure to follow the details provided`;
                                const socket = sockets['conclusion'];
                                onGenerateWithAI(socket, promptMessage);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Multimedia Elements"
                        name="multimediaElements"
                        rules={[{ required: true, message: 'Multimedia Elements are required' }]}
                    >
                        <CustomTextAreaWithButton
                            placeholder="Include relevant images, videos, or graphics"
                            uniqueKey="multimediaElements"
                            loadingStates={loadingStates}
                            setLoadingStates={setLoadingStates}
                            value={form.getFieldValue('multimediaElements')} // Bind value to form field
                            onChange={(e) => form.setFieldValue('multimediaElements', e.target.value)} 
                            checkIfCanGenerate={()=>{
                                return true;
                            }}
                            onClickButton={()=>{
                                form.setFieldValue('multimediaElements','')
                                const title = pageOneValues['title'];
                                const objective = pageOneValues['objective'];
                                const targetAudience = pageOneValues['targetAudience'];
                                const toneStyle = pageOneValues['toneStyle'];
                                const keyTopics = pageOneValues['keyTopics'];
                                const introduction = form.getFieldValue('introduction');
                                const contentStructure = form.getFieldValue('contentStructure');
                                const mainPoints = form.getFieldValue('mainPoints');
                                const callToAction = form.getFieldValue('callToAction');
                                const conclusion = form.getFieldValue('conclusion');
                                const multimediaElements = `
                                Title:${title}
                                Objective:${objective}
                                Target Audience:${targetAudience}
                                Tone Style:${toneStyle}
                                Key Topics:${keyTopics}
                                Introduction:${introduction}
                                Content Structure:${contentStructure}
                                Main Points:${mainPoints}
                                Call to Action:${callToAction}
                                Conclusion:${conclusion}
                            
                                `

                                const promptMessage = `Generate multimedia elements for a blog post based on the following details: ${multimediaElements}. Kindly ensure to follow the details provided`;
                                const socket = sockets['multimediaElements'];
                                onGenerateWithAI(socket, promptMessage);


                            }}
                        />
                    </Form.Item>
                </div>
            )}

            <div className="flex justify-between">
                {currentPage > 1 && (
                    <button
                        className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                        onClick={handlePrevious}
                    >
                        Previous
                    </button>
                )}
                {currentPage < 2 ? (
                    <button className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2" onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                        Generate
                    </button>
                )}
            </div>
        </Form>
    );
};

export default BlogWriter;
