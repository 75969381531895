import { Form, Input } from "antd";
import { LessonMaterialProps } from "../interface";
import { toast } from "react-toastify";

const LessonMaterials = ({ onGenerate, setTopic }: LessonMaterialProps) => {

    const onFinish = (values: any) => {
        let promptMessage = '';
        
        const choose = values.choose;
        const description = values.description;
        const purpose = values.purpose;
        const information = values.information;
        const contentFeeling = values.feeling;
        const contentAudience = values.audience;

        // check if all data is passed
        if ( !description || !purpose || !contentAudience) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = information ? `\nAdditional Information:${information}` : '';


        promptMessage = `Create a Lesson Material delivered by ${choose} with this additional information: Field: ${purpose}\nTopic:${description}\nContent Feeling:${contentFeeling}\nContent Target Audience:${contentAudience}${informationData}`;
        

        onGenerate(promptMessage, false);
        
    };

    return (
        <div className="w-full flex flex-col gap-0 h-auto max-h-full ">
            <div className="w-full flex flex-row gap-0 ">
                <div className="w-full flex flex-col gap-0  px-3 mb:px-10 mb-10">
                    <div className="gap-y-2 flex flex-col">
                    <div className="text-[2rem] md:text-[2.75rem] dark:text-white curriculum--title">Learning Materials</div>
                    <div className="text-[1.125rem] md:text-[0.875rem] dark:text-gray-300 mb-2  text-gray-600 mb-3 curriculum--subtitle">
                    Provide us the information requested below to get your learning material ready.
                    </div>
                    </div>
                    <Form layout="vertical" className='flex flex-col md:flex-row flex-wrap gap-x-5' onFinish={onFinish}>
                        {/* <Form.Item
                            className='w-full md:w-[48%]'
                            name={'choose'}
                            label={
                                <label className="curriculum--label"> Who is delivering the content?</label>
                            }
                            rules={[
                                {
                                    message: 'field is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className='w-full'
                                placeholder="e.g., Student, Teacher, Expert, Business Leader, Narrator"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item> */}

                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'purpose'}
                            label={
                                <label className="curriculum--label">
                                    {' '}
                                    What is the subject of the content?
                                </label>
                            }
                            rules={[
                                {
                                    message: 'purpose is required',
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                className='w-full placeholder-gray-700 '
                                placeholder="e.g., Science, History, Literature, Art, Business"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                    
                                }}
                                type="text"
                            />
                        </Form.Item>
                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'description'}
                            label={<label className="curriculum--label">What is the specific topic?</label>}
                            rules={[
                                {
                                    message: 'description is required',
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                className='w-full placeholder-gray-700'
                                onChange={(e) => setTopic(e.target.value)}
                                
                                placeholder="e.g., climate change, World War II, social media marketing"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>

                        {/* <Form.Item
                            className='w-full md:w-[48%]'
                            name={'feeling'}
                            label={
                                <label className="curriculum--label">
                                    What feeling do you want the content to evoke?
                                </label>
                            }
                            rules={[
                                {
                                    message: 'content feeling is required',
                                    required: false,
                                },
                            ]}
                        >
                            <input
                                className='w-full'
                                placeholder="e.g., Formal, Informal, Academic, Humorous, Informative, Inspirational"
                                style={{
                                    border: '1px solid #DBDADE',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item> */}

                        <Form.Item
                            className='w-full md:w-[48%] '
                            name={'information'}
                            label={<label className="curriculum--label"> Additional Information (optional)</label>}
                            rules={[
                                {
                                    message: 'field is required',
                                    required: false,
                                },
                            ]}
                        >
                            <Input.TextArea
                                className='w-full placeholder-gray-700'
                                placeholder="e.g Make it comprehensive"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    height: '64px',
                                    padding: '7px 10px',
                                }}
                                autoSize={{minRows: 3}}
                            />
                        </Form.Item>
                        <Form.Item
                            className='w-full md:w-[48%]'
                            name={'audience'}
                            label={<label className="curriculum--label">Who is the target audience?</label>}
                            rules={[
                                {
                                    message: 'content target audience is required',
                                    required: false,
                                },
                            ]}
                        >
                            <Input
                                className='w-full placeholder-gray-700'
                                placeholder="e.g., Elementary School, High School, College Students, General Public"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                                type="text"
                            />
                        </Form.Item>
                        <button type="submit" className="text-white transcribe">
                            Generate
                        </button>
                    </Form>
                </div>
            </div>
        </div> 
    );
}


export default LessonMaterials;