/* eslint-disable @typescript-eslint/no-unused-vars */
import RecordAudioCard from './components/RecordAudioCard';
import UploadRecord from './components/UploadRecord';
import UploadBox from './components/UploadBox';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import RecordAudioBox from './components/RecordAudioBox';
import menu from '@assets/transcriber/menu.svg';
import history from '@assets/transcriber/history.svg';
import logo from '@assets/gmind_logo_blue.png';
import DashboardFrame from '@src/components/DashboardFrame';
import Sidebar from '@src/components/Sidebar';
import { useNavigate } from 'react-router';
import History from '../../components/history';
import BackButton from '@src/components/BackButton';

type ShowElement = {
    showHistory: boolean;
    showTop: boolean;
    showSidebar: boolean;
};

type ShowMobileElement = {
    showHistory: boolean;
    showSidebar: boolean;
};



const Transcriber = ({currentPage}:{currentPage: Dispatch<SetStateAction<number>>}) => {
    const [isRecording, setIsRecording] = useState<number>(0);
    const [histories, setHistories] = useState([]);
    const [showMobileElements, setShowMobileElement] = useState<ShowMobileElement>({
        showHistory: false,
        showSidebar: false,
    });
    const [showElement, setShowElement] = useState<ShowElement>({
        showHistory: false,
        showSidebar: false,
        showTop: false,
    });
    const [isMobile, setIsMobile] = useState(true);
    const [isUpload, setIsUpload] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [generate, setGenerate] = useState(false);

    
    const handleRecording = () => {
        setIsUpload(false);
        setIsUploaded(false);
        setIsClicked(true);
    };

    const handleUpload = () => {
        setIsUpload(true);
        setIsClicked(false);
        setIsUploaded(true);
    };

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setShowElement((c) => ({ ...c, showHistory: true, showSidebar: true, showTop: true }));
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const navigate = useNavigate();

    const handleDeleteContentHistory = async (id: string) => {
        // await handleDeleteHistory(id, pageHistory);
        // await getPageHistory();
        // toast.success("Chat deleted successfully");
    };

    return (
        <div className={`${generate? '':'pt-5 pb-20 px-[2rem]'}`}>

        <div>
         {!generate &&   <BackButton onclick={()=>{
                if(isRecording === 0){
                    currentPage(0)
                }
                else{
                    setIsRecording(0)
                }
            }}/>}
            {isMobile && (
                <History
                    isMobile={isMobile}
                    onClose={() => {
                        console.log('close');
                        setShowMobileElement((c) => ({ ...c, showHistory: false }));
                    }}
                    data={[]}
                    className="transition-all w-64 z-[1000]"
                    click={(v) => {}}
                    style={{
                        transform: showMobileElements.showHistory ? 'translateX(0)' : 'translateX(20rem)',
                        position: isMobile ? 'fixed' : 'relative',
                        top: '0',
                        right: '0',
                        width: '16rem',
                        bottom: '0',
                    }}
                />
            )}
            {isRecording === 0 && (
                <div
                    style={isMobile ? { padding: '0 0.6rem', paddingBottom: 0 } : {}}
                    className="w-full flex justify-between gap-0 "
                >
                    <div className="w-full pl-[91px] py-[80px] px-[91-x] max-md:p-4">
                        <div
                            className="text-[2.75rem] dark:text-white"
                            style={{ fontFamily: "'Inter', sans-serif", fontWeight: 500 }}
                        >
                            Transcriber
                        </div>
                        <div className="text-sm font-[Inter,sans-serif] font-normal text-gray-400 dark:text-white">
                            To get started select whether you want to record an audio or upload from your device
                        </div>

                        <div className="mt-8 flex gap-x-4 max-md:flex-col max-md:gap-y-5">
                            <RecordAudioCard isClicked={isClicked} onRecord={handleRecording} />{' '}
                            <UploadRecord isClicked={isUploaded} onUpload={handleUpload} />
                        </div>
                        <button
                            onClick={() => (isUpload ? setIsRecording(2) : setIsRecording(1) )}
                            style={{
                                color: 'white',
                                backgroundColor: '#E55109',
                                fontFamily: "'Inter', sans-serif",
                                fontSize: '0.8125rem',
                                fontWeight: 400,
                                outline: 'none',
                                height: '2.5rem',
                                width: '8.65rem',
                                borderRadius: '0.375rem',
                                marginTop: '1.9375rem',
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            {isRecording === 1 && (
                <div className="w-full flex justify-between gap-0 bg-transparent">
                    <div className="w-full " style={isMobile ? { padding: '0 0rem' } : { padding:generate? '0px 0px': '40px 5rem' }}>
                        <RecordAudioBox back={() => setIsRecording(0)} generate={generate}  setGenerate={setGenerate}/>
                    </div>
                </div>
            )}

            {isRecording === 2 && (
                <div className="w-full flex justify-between gap-0 bg-transparent">
                    <div style={isMobile ? { padding: '0 0rem' } : { padding: '40px 5rem' }} className="w-full">
                        <UploadBox isMobile={isMobile} back={() => setIsRecording(0)} />
                    </div>
                </div>
            )}
        </div>
        </div>
        
    );
};
const MobileNavbar = ({ showSidebar, showHistory }: { showSidebar: () => void; showHistory: () => void }) => {
    return (
        <section className="p-6 flex items-center justify-between">
            <button onClick={showSidebar} type="button">
                <img src={menu} alt="menu" />
            </button>
            <div>
                <img src={logo} alt="" />
            </div>
            <button onClick={showHistory} type="button">
                <img src={history} alt="history" />
            </button>
        </section>
    );
};

export default Transcriber;
