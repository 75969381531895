import React, { useEffect, useState } from 'react';
import {
    Button,
    ColorPicker,
    Input,
    Modal,
    Progress,
    Radio,
    Select,
    Tag,
    Tooltip,
    Upload,
    UploadProps,
    message,
    notification,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
    CheckCheckIcon,
    ChevronLeft,
    Copy,
    Download,
    MessageCircleMore,
    Repeat,
    Share2,
    ThumbsDown,
    ThumbsUp,
} from 'lucide-react';
import pptxgen from 'pptxgenjs';
// import toast from "react-hot-toast";
import { Toaster, toast } from 'sonner';
import usePowerPointStore from '@src/providers/store';
import useFormStore from '@src/providers/formStore';
import { generateOpenAimage } from '../../helper';
import { uploadToCloudinary } from '../../upload';
import { Spinner } from './first-form';
import BackgroundTemplateDisplayer from '../BackgroundTemplateDisplayer';
import PptViewer from './preview-slide';
import axios from 'axios';
import storage from '@src/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
const { TextArea } = Input;
import './global.scss';
import { fontFamilyOptions } from './fonts';
const { Option } = Select;

type Props = {
    slides: any;
    setShowPreview: any;
};
export const cloud_name = 'dobksjz6x';
export const upload_preset = 'ml_default';

function ThirdForm({ slides, setShowPreview }: Props) {
    const [previewUrl, setPreviewUrl] = useState<string>('');

    const background = usePowerPointStore((state) => state.background);
    const setBackground = usePowerPointStore((state) => state.setBackground);
    const previousStep = useFormStore((state) => state.previousStep);
    const [selectedOption, setSelectedOption] = useState('ai');
    const [aiCommand, setAiCommand] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [fntColor, setfntColor] = useState('#ffffff');
    const [loading, setIsLoading] = useState<boolean>(false);
    const [loadingp, setIsLoadingp] = useState<boolean>(false);

    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showCheck, setShowCheck] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [fontSize, setFontSize] = useState<number>(24);
    const [contentFontSize, setContentFontSize] = useState<number>(16);
    const [fontFamily, setFontFamily] = useState<string>('Arial');

    const fontSizeOptions = [12, 14, 16, 18, 20, 24, 28];

    const handleUploadChange = (info: any) => {
        toast.info('Uploading....');
        const { status } = info.file;
        if (status === 'done') {
            const image_url = info.file.response.secure_url;
            //   message.success(`${info.file.name} file uploaded successfully.`);
            toast.success(`${info.file.name} file uploaded successfully.`);
            setUploadUrl(image_url);
            setBackground(image_url);
        } else if (status === 'error') {
            toast.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadProps: UploadProps = {
        name: 'file',
        action: `https://api.cloudinary.com/v1_1/${cloud_name}/upload`,
        headers: {
            'X-Requested-With': '',
        },
        data: {
            upload_preset: upload_preset,
        },
        onChange: handleUploadChange,
    };

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
        setBackground('');
        if (value !== 'upload') {
            setUploadUrl('');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setShowProgress(true);
            handleGenerateAIImage();
        }
    };

    const handleGenerateAIImage = async () => {
        setShowProgress(true);
        try {
            setProgress(0);

            const timer = setInterval(() => {
                if (progress < 100) {
                    setProgress(progress + 10);
                } else {
                    clearInterval(timer);
                }
            }, 1500);

            const aiImageUrl = await generateOpenAimage(aiCommand, '1024x1024');
            const url = await uploadToCloudinary(aiImageUrl?.data.data[0].b64_json);

            setBackground(url);
            setTimeout(() => {
                clearInterval(timer);
                setProgress(100);
                setShowProgress(false);
                setShowCheck(true);
            }, 4000);
            if (aiImageUrl) {
                toast.success('image genarated sucessfully');
            }
        } catch (error) {
            console.error('Error generating AI image:', error);
            setProgress(100);
            setShowProgress(false);
            setShowCheck(false);
        }
    };

    useEffect(() => {
        console.log(slides);
    }, [slides]);

    function Back() {
        setShowPreview(false);
    }
    //   async function generatePresentation(slidesData: any) {
    //     const pptx = new pptxgen();
    //     const ImagePath = background || "/pptx/p1.jpg";

    //     for (const slide of slidesData) {
    //       const { title, content, image } = slide;
    //       const slideLayout = pptx.addSlide();

    //       slideLayout.background = {
    //         path: ImagePath,
    //         sizing: { type: "cover", w: "100%", h: "100%" },
    //       };

    //       slideLayout.addText(title, {
    //         x: 0.5,
    //         y: 0.5,
    //         w: "90%",
    //         h: 1.0,
    //         align: "center",
    //         color: fntColor,
    //         fontSize: 24,
    //         bold: true,
    //       });

    //       slideLayout.addText(content, {
    //         x: 0.5,
    //         y: 1.5,
    //         w: "90%",
    //         h: 2.0,
    //         align: "left",
    //         color: fntColor,
    //         fontSize: 18,
    //       });

    //       if (image) {
    //         slideLayout.addImage({
    //           path: image,
    //           x: "50%",
    //           y: 3.0,
    //           w: 4.0,
    //           h: 4.0,
    //         });
    //       }
    //     }

    //     await pptx.writeFile({ fileName: "AiGeneratedSlides" });

    //   }

    function rgbToHex(r: number, g: number, b: number): string {
        return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    }
    async function generatePresentation(slidesData: any) {
        const rgbColor = fntColor;
        const [r, g, b] = rgbColor.match(/\d+/g)?.map(Number) || [0, 0, 0];
        const hexColor = rgbToHex(r, g, b);
        const pptx = new pptxgen();
        const ImagePath = background || '/def.jpg';

        for (const slide of slidesData) {
            const { title, details, previewImage, uploadedImage } = slide;
            const slideLayout = pptx.addSlide();

            slideLayout.background = {
                path: ImagePath,

                // sizing: { type: 'cover', w: '100%', h: '100%' },
            };

            slideLayout.addText(title, {
                x: 0.5,
                y: 0.5,
                w: '45%',
                h: 1.0,
                align: 'left',
                color: hexColor,
                fontSize: fontSize,
                bold: true,
                fontFace:fontFamily,
            });

            slideLayout.addText(details, {
                x: 0.5,
                y: 1.5,
                w: '45%',
                h: 3.0,
                align: 'left',
                color: hexColor,
                fontSize: contentFontSize,
                fontFace:fontFamily,
            });

            if (uploadedImage || previewImage) {
                slideLayout.addImage({
                    path: uploadedImage || previewImage,
                    x: '55%',
                    y: 0.5,
                    w: '35%',
                    h: '75%',
                });
            }
        }

        await pptx.writeFile({ fileName: 'GmindGeneratedSlides' });

        // save to history here 
    }

    function formatTextForPptx(text: string): Array<{ text: string; options: any }> {
        const formattedText: Array<{ text: string; options: any }> = [];

        // Split text into lines
        const lines = text.split(/\n/);
        for (const line of lines) {
            if (line.startsWith('- ')) {
                // Bullet points
                formattedText.push({
                    text: line.replace('- ', '').trim(),
                    options: { bullet: true, fontSize: 16 },
                });
            } else if (line.startsWith('**') && line.endsWith('**')) {
                // Bold text
                formattedText.push({
                    text: line.replace(/\*\*/g, '').trim(),
                    options: { bold: true, fontSize: 18 },
                });
            } else {
                // Plain text
                formattedText.push({
                    text: line.trim(),
                    options: { fontSize: 16 },
                });
            }
        }

        return formattedText;
    }

    async function preview(slidesData: any) {
        const rgbColor = fntColor;
        const [r, g, b] = rgbColor.match(/\d+/g)?.map(Number) || [0, 0, 0];
        const hexColor = rgbToHex(r, g, b);
        const pptx = new pptxgen();
        const ImagePath = background || '/def.jpg';

        for (const slide of slidesData) {
            const { title, details, previewImage, uploadedImage } = slide;
            const content = formatTextForPptx(details);
            const slideLayout = pptx.addSlide();

            slideLayout.background = {
                path: ImagePath,
            };

            slideLayout.addText(title, {
                x: 0.5,
                y: 0.5,
                w: '45%',
                h: 1.0,
                align: 'left',
                color: hexColor,
                fontSize: fontSize,
                bold: true,
                fontFace:fontFamily,
            });

            slideLayout.addText(content, {
                x: 0.5,
                y: 1.5,
                w: '45%',
                h: 3.0,
                align: 'left',
                color: hexColor,
                fontSize: contentFontSize ?? '',
                fontFace:fontFamily,
            });

            if (uploadedImage || previewImage) {
                slideLayout.addImage({
                    path: uploadedImage || previewImage,
                    x: '55%',
                    y: 0.5,
                    w: '35%',
                    h: '75%',
                });
            }
        }

        const arrayBuffer = await pptx.write();
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        });
        const fileName = `presentation_${Date.now()}.pptx`;
        const fileRef = ref(storage, `presentations/${fileName}`);

        try {
            const uploadTask = uploadBytesResumable(fileRef, blob);
            await new Promise<void>((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    null,
                    (error) => {
                        console.error('Error uploading file:', error);
                        reject(error);
                    },
                    async () => {
                        resolve();
                    },
                );
            });
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('url here:' + url);
            return url;
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    }

    async function ViewPreview(slides: any) {
        setIsLoadingp(true);
        try {
            let parsedData = [];
            if (Array.isArray(slides)) {
                parsedData = slides;
            } else {
                parsedData = JSON.parse(slides);
            }

            if (!parsedData) {
                notification.error({ message: 'We are unable to process your file' });
                return;
            }

            const previewUrl = await preview(parsedData);
            if (previewUrl) {
                setPreviewUrl(previewUrl);
                toast.success('Preview generated');
            } else {
                notification.error({ message: 'Failed to generate preview' });
            }
        } catch (error: any) {
            notification.error({ message: error.message });
        } finally {
            setIsLoadingp(false);
        }
    }
    const handleOk = async () => {
        try {
            let parsedData = [];
            if (Array.isArray(slides)) {
                parsedData = slides;
            } else {
                parsedData = JSON.parse(slides);
            }

            if (!parsedData) {
                notification.error({ message: 'We are unable to process your file' });
                return;
            }

            console.log('parsedData:', parsedData);
            await ViewPreview(parsedData);
        } catch (error) {}
        console.log(previewUrl);
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        setTimeout(async () => {
            try {
                let parsedData = [];
                if (Array.isArray(slides)) {
                    parsedData = slides;
                } else {
                    parsedData = JSON.parse(slides);
                }

                if (!parsedData) {
                    notification.error({ message: 'We are unable to process your file' });
                    return;
                }
                await generatePresentation(parsedData);
                toast.success('Voila, your file is downloaded, check the beautiful creation');
            } catch (error: any) {
                notification.error({ message: error.message });
            } finally {
                setIsLoading(false);
                // setShowModal(true);
            }
        }, 3000);
    };
    const handleColorChange = (value: any, hex: string) => {
        console.log('Selected color:', value, hex);
        setfntColor(hex);
    };

    return (
        <div className="text-[#232323] font-medium mt-4 text-[36px] max-w-[50%] max-sm:max-w-full overflow-auto">
            <Toaster richColors />

            <h1 className="text-gray-800 dark:text-white">Generate Slides</h1>

            <Tooltip title="Click the small box to change font color">
                <small className="text-gray-700 dark:text-gray-300  text-[14px] font-medium">
                    Click here to choose your preferred font color
                </small>
                <div className="flex items-center gap-2">
                    <ColorPicker
                        defaultValue="#ffffff"
                        onChange={handleColorChange}
                        className="rounded-lg  border border-gray-300 dark:border-gray-600 p-2 cursor-pointer"
                    />
                </div>
            </Tooltip>

            <small className="text-gray-700 dark:text-gray-300  text-[14px] font-medium">Title font size</small>
            <div className="flex items-center gap-2">
            <Select
            className="w-full"
            value={fontSize}
            placeholder="Select Font Size"
            onChange={(value) => setFontSize(value)}
            style={{ marginBottom: '0px' }}
        >
            {fontSizeOptions.map((size, index) => (
                <Option key={index} value={size}>
                    {size}
                </Option>
            ))}
        </Select>
            </div>
            <small className="text-gray-700 dark:text-gray-300  text-[14px] font-medium"> Content font size</small>
            <div className="flex items-center gap-2">
            <Select
            className="w-full"
            value={contentFontSize}
            placeholder="Select Font Size"
            onChange={(value) => setContentFontSize(value)}
            style={{ marginBottom: '0px' }}
        >
            {fontSizeOptions.map((size, index) => (
                <Option key={index} value={size}>
                    {size}
                </Option>
            ))}
        </Select>
            </div>

            <small className="text-gray-700 dark:text-gray-300  text-[14px] font-medium">Font family</small>
            <div className="flex items-center gap-2 mb-3">
                <Select
                    placeholder="Select a font family"
                    style={{ width: '100%' }}
                    options={fontFamilyOptions.map((family) => ({ value: family, label: family }))}
                    onChange={(value) => setFontFamily(value)}
                />
            </div>

            <p className="text-gray-500 dark:text-gray-300 font-normal mt-4 text-[14px] mb-4">
                Select the perfect slide template to elevate your background design!
            </p>
            <Radio.Group
                className="text-[14px] flex text-gray-500 dark:text-gray-300 !mb-4 gap-4"
                value={selectedOption}
                onChange={(e) => handleRadioChange(e.target.value)}
            >
                {[
                    { value: 'ai', label: 'AI-Generated Template' },
                    { value: 'default', label: 'Default Template' },
                    { value: 'upload', label: 'Upload Template' },
                ].map((option) => (
                    <div
                        key={option.value}
                        className={`flex items-center justify-center space-x-2 border border-gray-400 p-2 rounded-md w-full ${
                            selectedOption === option.value ? 'bg-[#E55109] text-white border-none' : ''
                        }`}
                    >
                        <Radio
                            className={`${selectedOption === option.value ? 'text-white border-white' : ''}`}
                            value={option.value}
                            id={`radio-${option.value}`}
                        />
                        <label className="text-center" htmlFor={`radio-${option.value}`}>
                            {option.label}
                        </label>
                    </div>
                ))}
            </Radio.Group>

            {selectedOption === 'ai' && (
                <div>
                    <label className="text-gray-500 dark:text-gray-300 text-[14px]" htmlFor="ai-command">
                        Enter command for AI
                    </label>
                    <div className="flex items-center text-center justify-center gap-2">
                        <Input
                            className="focus:border-[#E55109] dark:focus:border-[#E55109] active:border-[#E55109] dark:bg-[#333] dark:text-white"
                            id="ai-command"
                            placeholder={`Enter description for AI for Slide Background, click enter to generate ↵`}
                            value={aiCommand}
                            onChange={(e) => setAiCommand(e.target.value)}
                            onKeyDown={(e) => handleKeyPress(e)}
                        />
                        <Tag onClick={() => handleGenerateAIImage()} className="text-[12px] cursor-pointer">
                            Enter
                        </Tag>
                    </div>
                    {showProgress && <Progress percent={progress} className="dark:text-white" />}
                    {showCheck && (
                        <div className="flex text-[12px] py-4">
                            <CheckCheckIcon style={{ color: 'green' }} />
                            Generated image
                        </div>
                    )}
                    {background && <img src={background} className="h-18 w-20" alt={`Slide ${+1}`} />}
                </div>
            )}

            {selectedOption === 'default' && <BackgroundTemplateDisplayer />}

            {selectedOption === 'upload' && (
                <Upload
                    {...uploadProps}
                    listType="picture-card"
                    className="avatar-uploader mt-10"
                    showUploadList={false}
                >
                    {background ? (
                        <img src={background} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        <Button icon={<UploadOutlined />}></Button>
                    )}
                </Upload>
            )}

            <div className="flex gap-6 items-center w-full  mb-36">
                <button
                    className="bg-none border border-gray-400 dark:border-white text-gray-800 dark:text-white mt-6 hover:bg-gray-600 dark:hover:bg-gray-600 text-sm py-2 px-3 rounded-md"
                    onClick={Back}
                >
                    Back to Edit
                </button>
                <button
                    className="bg-[#E55109] text-white mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-3 rounded-md"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {!loading ? (
                        'Generate your Slides'
                    ) : (
                        <div className="flex items-center text-sm">
                            <Spinner />
                            <small>Generating your slides'</small>
                        </div>
                    )}
                </button>
                <button
                    className="bg-gray-400 dark:bg-transparent dark:border dark:border-white text-white mt-6  hover:bg-gray-600 text-sm py-2 px-3 rounded-md"
                    onClick={() => handleOk()}
                    disabled={loading}
                >
                    {!loadingp ? (
                        'Preview Slides'
                    ) : (
                        <div className="flex items-center text-sm text-center">
                            <Spinner />
                        </div>
                    )}
                </button>
            </div>

            <Modal
                title="G-mind Generated PowerPoint Preview"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width="80%"
                height="80%"
            >
                <div className="flex justify-between text-center items-center gap-4 p-4 mt-4 bg-white ">
                    <div className="flex gap-2">
                        <button className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC]  rounded-[12px]">
                            <Share2 size="14px" color="#E55109" />
                            <small>Share</small>
                        </button>
                        <button className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC]  rounded-[12px]">
                            <Copy size="14px" color="#E55109" />
                            <small>Copy</small>
                        </button>
                        <button className="flex gap-2 text-center items-center text-[14px] p-1 px-2 bg-[#F9FAFC]  rounded-[12px]">
                            <Download size="14px" color="#E55109" />
                            <small>Download</small>
                        </button>
                    </div>
                    <div className="flex gap-2">
                        <Repeat color="#E55109" className="hover:cursor-pointer" size="14px" />
                        <MessageCircleMore color="#E55109" className="hover:cursor-pointer" size="14px" />
                        <ThumbsUp color="#E55109" className="hover:cursor-pointer" size="14px" />
                        <ThumbsDown color="#E55109" className="hover:cursor-pointer" size="14px" />
                    </div>
                </div>

                <iframe
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(previewUrl)}&embedded=true`}
                    title="G-mind Generated PowerPoint Preview"
                    width="100%"
                    height="500px"
                    style={{ border: 'none' }}
                />
            </Modal>
        </div>
    );
}

export default ThirdForm;