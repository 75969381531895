import logo from '@assets/gmind_white.png';
import facebook from '@socials/Facebook.svg';
import youtube from '@socials/Youtube.svg';
import instagram from '@socials/Instragram.svg';
import linkedin from '@socials/LinkedIn.svg';
import mail from '@icons/mail.svg';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import "./style.css"
type socialsType = {
    icon: string;
    link: string;
};

type contactType = {
    icon: string;
    onClick: (e: any) => void;
    details: string;
};
export const Footer = () => {
    const socials: socialsType[] = [
        {
            icon: facebook,
            link: 'https://www.facebook.com',
        },
        {
            icon: youtube,
            link: 'https://www.youtube.com/@GMindAI',
        },
        {
            icon: instagram,
            link: 'https://www.instagram.com/gmind.ai/',
        },
        {
            icon: linkedin,
            link: 'https://www.linkedin.com',
        },
    ];

    const contactSection: contactType[] = [
        {
            icon: mail,
            onClick: (e: string) => {
                window.location.href = `mailto:${e}`;
            },
            details: 'info@gmind.AI',
        },
        {
            icon: mail,
            onClick: (e: string) => {
                window.location.href = `mailto:${e}`;
            },
            details: 'support@gmind.AI',
        },
    ];

    const navigate = useNavigate();

    return (
        <section className="bg-[var(--gmind-footer)] footer_new font-popping max-md:grid-cols-1 p-8 py-14 min-h-[50vh] grid grid-cols-5 w-full text-sm gap-10 text-white font-normal">
            <div className="space-y-4 w-full col-span-2 max-md:col-span-2 max-md:space-y-10 ">
                <img onClick={() => { scrollTo(0,0); navigate("/");}} src={logo} alt="" className="max-md:mx-auto w-[9rem] cursor-pointer" />
                <p className="font-normal w-[80%] text-[0.9rem] max-md:hidden">
                    GMind AI, standing for Gotocourse Mind AI, is a state-of-the-art educational technology developed by
                    Gotocourse. This AI model is designed to create robust and comprehensive educational content for
                    teachers and schools.
                </p>
                <div className="md:hidden w-full">
                    <button
                        className="bg-brandOrange p-2 px-4 block w-fit mx-auto py-3 font-medium rounded-lg"
                        type="button"
                    >
                        Get Started
                    </button>
                </div>
                <p className="max-md:hidden lg:mt-[3rem!important] text-xs">Copyright © 2023 GotoCourse. All rights reserved.</p>
                <div className="flex items-center gap-2 max-md:justify-center">
                    {socials.map(({ icon, link }) => (
                        <span className="w-fit h-fit rounded-lg">
                            <Link to={link}>
                                <img
                                    role="button"
                                    onClick={() => {
                                        navigate(link);
                                    }}
                                    src={icon}
                                    alt=""
                                />
                            </Link>
                        </span>
                    ))}
                </div>
            </div>

            <div className="flex gap-6 max-md:flex-col">
                {/* <div className="flex flex-col gap-10 max-md:flex-row max-md:justify-center font-[300]">
                    <span
                        role="button"
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                        Home
                    </span>
                    <span
                        role="button"
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                        FAQs
                    </span>
                    <span
                        role="button"
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                        Features
                    </span>
                    <span
                        role="button"
                        onClick={() => {
                            navigate(`/`);
                        }}
                    >
                        Affiliate
                    </span>
                </div> */}
                <div className="flex flex-col  items-start gap-5 md:gap-4 md:flex-col md:justify-start text-nowrap font-[300]">
                    <span
                        role="button"
                        onClick={() => {
                            scrollTo(0, 0);

                            navigate('/cookies');
                        }}
                    >
                        Cookie Policy
                    </span>
                    <span
                        role="button"
                        onClick={() => {
                            scrollTo(0, 0);
                            navigate('/terms-of-service', { preventScrollReset: false });
                        }}
                    >
                        Terms of Service
                    </span>
                    <span
                        role="button"
                        onClick={() => {
                            scrollTo(0, 0);

                            navigate('/privacy-policy');
                        }}
                    >
                        Privacy Policy
                    </span>
                </div>
            </div>

            <div className="space-y-8 w-full items-start md:flex md:flex-col md:items-start">
                <span className="font-medium max-md:font-normal max-md:text-center">Get Support</span>

                <div>
                    {contactSection.map(({ icon, onClick, details }) => (
                        <button className="flex items-center gap-2" type="button" onClick={ () => onClick(details)}>
                            <img className="" src={icon} alt="icon" />
                            <span className="">{details}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className="max-md:hidden">
                <button className="bg-brandOrange p-2 px-4 font-medium rounded-lg" type="button">
                    Get Started
                </button>
            </div>
        </section>
    );
};
