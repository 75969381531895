/* eslint-disable @typescript-eslint/no-unused-vars */
import ExampleCard from "@src/components/example_card";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import ForgotPassword from "@src/components/ForgotPassword";

export default function ForgotPage() {    
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-5 py-5 px-5 md:px-20">
            <div className="flex flex-col md:flex-row gap-x-5 items-center justify-between">
              <ForgotPassword /> 
              <ExampleCard />
            </div>
            <ToastContainer />
        </div>
    )
}