import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext({ savedTheme: 'dark', toggleTheme: () => {}, isMobile: false, isLargeTab: false });


export const ThemeProvider = ({ children }: {children: any;}) => {

    const [savedTheme, setSavedTheme] = useState(localStorage.getItem('theme') || 'dark');
    const [isMobile, setIsMobile] = useState(false);
    const [isLargeTab, setIsLargeTab] = useState(false);

    const checkIfLargeTab = () => {
        // Check if the window's width is exactly 1024px
        setIsLargeTab(window.innerWidth === 1024);
    };

    useEffect(() => {
        // Perform the initial check on component mount
        checkIfLargeTab();

        // Add resize event listener to monitor width changes
        window.addEventListener('resize', checkIfLargeTab);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIfLargeTab);
    }, []);

    useEffect(() => {
        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [savedTheme]);

    useEffect(() => {
        const checkScreenSize = () => {
            const isTabletOrMobile = window.innerWidth <= 1024;
            setIsMobile(isTabletOrMobile);
        };

        const resizeObserver = new ResizeObserver(() => {
            checkScreenSize();
        });

        resizeObserver.observe(document.body);
        checkScreenSize();

        return () => resizeObserver.disconnect();
    }, []);

    const toggleTheme = () => {
        const newTheme = savedTheme === 'light' ? 'dark' : 'light';
        setSavedTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <ThemeContext.Provider value={{ savedTheme, toggleTheme, isMobile, isLargeTab }}>
            {children}
        </ThemeContext.Provider>
    );
};
