/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useContext, useEffect, useState } from 'react';
import cancel from '@assets/cancel.svg';
import search from '@assets/search.svg';
import type { ChatType, HistoryTypeFormat } from '@src/core/interfaces/chat';
import { dateFormat, extractAssistantData, handleRoute } from '@src/utils/app_functions';
import { dictionary, reverseDictionary } from '@src/utils/constants';
import right from '@assets/right_gray.svg';
import deleteImage from '@icons/delete.svg';
import { IoMdMore, IoMdOpen } from 'react-icons/io';
import { Dropdown, Menu, MenuProps } from 'antd';
import { CloseCircleOutlined, EllipsisOutlined, OpenAIOutlined, RightOutlined } from '@ant-design/icons';
import Collapse from '../svgs/collapse';
import { ThemeContext } from '@src/theme_provider/theme';
import { Share as Shared } from 'lucide-react';
import { Share } from './Share';

/* eslint-disable @typescript-eslint/no-unused-vars */

interface HistoryType {
    click: (v: string) => void;
    data: HistoryTypeFormat[];
    name?: string;
    className?: string;
    date?: string;
    style?: React.CSSProperties;
    onClose?: () => void;
    hidden?: boolean;
    topChild?: ReactNode;
    closeId?: string;
    onDeleteHistory: (id: string) => void;
    onDeleteAllHistory: () => void;
}

interface openSidebarValues {
    id: string;
    value: boolean;
}

const HistoryModal = ({
    click,
    data,
    name = 'History',
    className,
    onClose,
    date,
    style,
    hidden,
    topChild,
    closeId,
    onDeleteHistory,
    onDeleteAllHistory,
}: HistoryType) => {
    const [collapse, setCollapse] = useState(!hidden);
    const [Name, setName] = useState(name);
    const [paths, setPaths] = useState<any[]>([]);
    const [key, setKey] = useState(0);
    const [histories, setHistories] = useState<HistoryTypeFormat[]>([]);
    const [optionOpened, setOpenOption] = useState<Map<string, boolean>>(new Map());
    const { savedTheme, toggleTheme } = useContext(ThemeContext);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [isVertical, setIsVertical] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpenChange = (flag: boolean) => {
        setOpen(flag);
      };

    useEffect(() => {
        const sortedHistories = [...data].sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            return dateB - dateA; // Sort in descending order
        });
        setHistories(sortedHistories);
    }, [data]);

    useEffect(() => {
        setHistories(data);
    }, [data]);

    useEffect(() => {
        generateAllOptionValue();
    }, [histories]);

    const getSideBarValue = (id: string): boolean => {
        return optionOpened.get(id) || false;
    };

    const setSideBarValue = (id: string) => {
        setOpenOption((prevOptionOpened) => {
            const newOptionOpened = new Map(prevOptionOpened);
            newOptionOpened.set(id, !newOptionOpened.get(id));
            return newOptionOpened;
        });
    };

    const generateAllOptionValue = () => {
        const openSideValues = new Map<string, boolean>();
        histories.forEach((element) => {
            element.histories.forEach((values) => {
                const historyData = extractAssistantData(values);
                if (historyData) {
                    openSideValues.set(historyData.id, false);
                }
            });
        });

        setOpenOption(openSideValues);
    };

    const refresh = () => {
        setKey((prevKey) => prevKey + 1);
        // setHistories(data);
    };

    const onSearch = (input: string) => {
        if (input == '') {
            setHistories(data);
            return;
        }

        const filtered = data
            .map((historyFormat) => {
                const filteredHistories = historyFormat.histories.filter((chatArray) =>
                    chatArray.some((chat) => chat.content.includes(input)),
                );

                return {
                    ...historyFormat,
                    histories: filteredHistories,
                };
            })
            .filter((historyFormat) => historyFormat.histories.length > 0);

        setHistories(filtered);
    };

    useEffect(() => {
        setCollapse(!hidden);
    }, [hidden]);

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/').slice(2);
        const formatPaths = split.map((path: string) => dictionary[path]);
        setPaths(formatPaths);
        console.log(formatPaths);
    }, [window.location.pathname]);

    function handleClick(id: string) {
        setCollapse(false);
        onClose && onClose();
        click(id);
    }

    const Data: ChatType[][] = [
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '1' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '2' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '3' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '4' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '5' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '6' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '7' },
        ],
    ];

    const menu = (id: string): MenuProps['items'] => [
        {
            key: '1',
            label: (
                <div
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick(id ?? '');
                    setOpen(false);
                    
                }}

                    className="flex items-center gap-2"
                >
                    <IoMdOpen className="h-4 w-4" />
                    <span>Open</span>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        onDeleteHistory(id);
                        refresh();
                        setOpen(false);
                    }}
                    className="flex items-center gap-2"
                >
                    <img className="w-4 h-4" src={deleteImage} alt="delete" />
                    <span className="text-[var(--gmind-orange)]">Delete</span>
                </div>
            ),
        },
      
    ];

    const handleEllipsisClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent parent handlers from triggering
        setIsVertical(!isVertical);
    };

    return (
        <section
            key={key}
            style={
                collapse
                    ? { userSelect: 'none', opacity: '0', zIndex: '-1', ...style }
                    : { userSelect: 'auto', opacity: '1', ...style }
            }
            className={
                'bg-[#eeeee4]  dark:bg-[#212121] transition-all fixed border-l border-white left-auto inset-0 w-[75%] lg:w-[25%]  h-screen flex flex-col gap-6 z-[1000] xl:z-[100] py-5 px-5 ' +
                className
            }
        >
            <div className="flex items-center justify-between">
                <div>
                    {paths.map((p, i) => (
                        <div key={i} className="flex gap-1 items-center">
                            <p
                                style={paths.length === i + 1 ? { fontWeight: '600' } : { fontWeight: '300' }}
                                role="button"
                                className=" m-0 text-[1rem]  text-nowrap dark:text-white"
                                onClick={() => {
                                    const new_path = paths
                                        .slice(0, i + 1)
                                        .map((c) => reverseDictionary[c])
                                        .join('/');
                                    localStorage.removeItem('activeChat');
                                    handleRoute(`/dashboard/${new_path}`);
                                }}
                            >
                                {p} History
                            </p>
                        </div>
                    ))}
                </div>

                <button
                    id={closeId ?? ''}
                    className="ms-auto block dark:text-white"
                    onClick={() => {
                        setCollapse(!collapse);
                        onClose && onClose();
                    }}
                    type="button"
                >
                    <Collapse className="max-md:hidden w-[1.5rem]" isDarkMode={savedTheme === 'dark'} />
                </button>
            </div>
            <div className="  w-full flex flex-col gap-6">
                <div className="flex  flex-col gap-2">
                    <span className="border relative hover:border-gray-700 dark:hover:border-gray-200 dark:border-gray-300   border-gray-500 transition-all rounded-md p-2">
                        <input
                            onChange={(e) => {
                                onSearch(e.target.value);
                            }}
                            className="text-xs w-[60vh] font-normal dark:text-white dark:placeholder-500 pe-6"
                            type="text"
                            placeholder="Search by date or topic"
                        />
                        <img className="w-4 absolute right-3 top-[50%] translate-y-[-50%]" src={search} alt="search" />
                    </span>

                    <p
                        onClick={onDeleteAllHistory}
                        className="text-[0.75rem] text-[var(--gmind-orange)] underline flex items-end justify-end font-normal mr-5 dark:text-white"
                    >
                        Clear All
                    </p>
                </div>
                <div className="space-y-5 h-[70vh] overflow-y-auto ">
                    {histories.map((sortedHistory: HistoryTypeFormat, index) => {
                        return (
                            <div key={index} className="flex flex-col gap-y-2 p-3">
                                <h3 className="ml-5 font-optimistic text-black dark:text-white text-[0.9rem] font-bold">
                                    {sortedHistory.date}
                                </h3>
                                <div className="flex flex-col-reverse gap-y-2">
                                    {sortedHistory.histories?.map((history: ChatType[]) => {
                                        // // const { content: assistantContent } = history[1];
                                        // const { content: userContent } = history[0];
                                        // // const { id } = history[history.length - 1];
                                        // // const { date } = history[history.length - 1];

                                        let historyData = extractAssistantData(history);

                                        // console.log(historyData);

                                        if (!historyData) return <></>;

                                        let date = historyData?.date;
                                        let id = historyData?.id;
                                        let assistantContent = historyData?.content;

                                        return (
                                            <section
                                                role="button"
                                                key={id}
                                                className="flex flex-col items-center rounded-lg  hover:border hover:border-gray-500   justify-between gap-x-3 "
                                            >
                                                <div
                                                    className="w-full  transition-all flex items-center justify-between py-3 border border-gray-300 rounded p-5 "
                                                    onClick={() => {
                                                        // console.log(history);
                                                        handleClick(id ?? '');
                                                    }}
                                                >
                                                    <div className=" w-full flex flex-col gap-2">
                                                        <div className="flex flex-row justify-between">
                                                            <span className=" text-[0.9rem] text-gray-500 dark:text-gray-300 font-medium">
                                                                {assistantContent
                                                                    ? assistantContent?.length > 80
                                                                        ? assistantContent?.slice(0, 80) + '...'
                                                                        : assistantContent
                                                                    : ''}
                                                            </span>

                                                            <Dropdown
                                                                overlayClassName="menu"
                                                                placement='top'
                                                                trigger={['click']}
                                                                menu={{ items: menu(id) }}
                                                                onOpenChange={handleOpenChange}
                                                            >
                                                                <div
                                                                    onClick={handleEllipsisClick}
                                                                    className="cursor-pointer"
                                                                >
                                                                    {isVertical ? (
                                                                        <IoMdMore size={24}  className='dark:text-white' />
                                                                    ) : (
                                                                        <IoMdMore size={24}  className='dark:text-white' />
                                                                    )}
                                                                </div>
                                                            </Dropdown>
                                                        </div>
                                                        <div className=" w-full flex flex-row justify-between">
                                                            <div className="flex flex-row gap-x-2 gap-y-0 items-center">
                                                                <span className="text-[0.6rem] text-brandOrange dark:text-white">
                                                                    {
                                                                        dateFormat({
                                                                            dateSent: date ?? '14:23 12-8-2023',
                                                                        }).split(' ')[1]
                                                                    }
                                                                </span>
                                                                <span className="text-[0.6rem] text-brandOrange dark:text-white">
                                                                    {
                                                                        dateFormat({
                                                                            dateSent: date ?? '14:23 12-8-2023',
                                                                        }).split(' ')[0]
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/* {showShareModal && <Share url={UrlRef.current} onClose={() => setShowShareModal(false)} />} */}
            </div>
        </section>
    );
};

export default HistoryModal;
