/* eslint-disable @typescript-eslint/no-unused-vars */
import Login from "@src/pages/acquisition/login/components/Login";
import Header from "../components/header";
import { useNavigate } from "react-router";
import ExampleCard from "@src/components/example_card";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { loginStructuredData } from "../strutured_data/structured_data";

export default function LoginPage() {
    const navigatePage = useNavigate();
    
    return (
        <div className="w-full flex flex-col h-screen bg-white gap-y-5 py-5 px-5 md:px-20">
            <Helmet>
              <script type="application/ld+json">{JSON.stringify(loginStructuredData)}</script>
              <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
              <meta 
                name="description" 
                content="Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional." 
              />
              <meta
                name="title"
                content="GMind AI Login | Access Your AI Productivity Tools"     
              />
              <title>GMind AI Login | Access Your AI Productivity Tools</title>
              <link rel="canonical" href="https://gmind.ai/login" />
              <meta name="keywords" content="gmind, ai, chat, gmind, gmindai" />z
            </Helmet>
            <div className="flex flex-col md:flex-row items-center justify-between">
              <Login 
                handleForgetPasssword={() => navigatePage("/forgot_password")}
                handleCreateAccount={() => navigatePage("/signup")}
              /> 
              <ExampleCard />
            </div>
            <ToastContainer />
        </div>
    )
}
