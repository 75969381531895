/* eslint-disable prefer-const */
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';
import AIGenerate from '@icons/generate.svg';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { onGenerateWithAI } from '../../curriculum/functions';

const { TextArea } = Input;

interface NewsWriterLoadingStates {
    headline: boolean;
    newsCategory: boolean;
    introduction: boolean;
    body: boolean;
    conclusion: boolean;
    audienceTargeting: boolean;
    languageTone: boolean;
    seoOptimization: boolean;
}

interface NewsWriterFormValues {
    headline: string;
    newsCategory: string;
    introduction: string;
    body: string;
    conclusion: string;
    audienceTargeting: string;
    languageTone: string;
    seoOptimization: string;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value, // Add value prop
    onChange, // Add onChange prop
    checkIfCanGenerate,
    onClickButton,
}: {
    placeholder: string;
    uniqueKey: keyof NewsWriterLoadingStates;
    loadingStates: NewsWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<NewsWriterLoadingStates>>;
    value?: string; // Optional value prop
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Optional onChange prop
    checkIfCanGenerate: () => boolean;
    onClickButton: () => void;
}) => {
    const handleButtonClick = () => {
        let canGenerate = checkIfCanGenerate();
        if (!canGenerate) return;
        setLoadingStates((prevState) => ({
            ...prevState,
            [uniqueKey]: true,
        }));

        onClickButton();
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-500 w-full focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            {!loadingStates[uniqueKey] ? (
                <div
                    onClick={handleButtonClick}
                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                >
                    <img src={AIGenerate} alt="" />
                    <span>Use Gmind AI</span>
                </div>
            ) : (
                <div className="absolute top-[65px] right-[20px] ">
                    <CircularProgress size={15} className="w-1 h-1" />
                </div>
            )}
        </div>
    );
};

const NewsWriter = ({ onGenerate }: { onGenerate: (e: string) => void; }) => {
    const [form] = Form.useForm();
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [loadingStates, setLoadingStates] = useState<NewsWriterLoadingStates>({
        headline: false,
        newsCategory: false,
        introduction: false,
        body: false,
        conclusion: false,
        audienceTargeting: false,
        languageTone: false,
        seoOptimization: false,
    });
    const socketKey = ['introduction', 'body', 'conclusion', 'audienceTargeting', 'languageTone', 'seoOptimization'];
    const [pageOneValues, setPageOneValues] = useState<{ [key: string]: string }>({
        headline: '',
        newsCategory: '',
        introduction: '',
        body: '',
        conclusion: '',
        audienceTargeting: '',
        languageTone: '',
        seoOptimization: '',
    });
    const baseurl = BASE_URL;

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach((item) => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach((item) => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                let previousValue = form.getFieldValue(item);
                form.setFieldValue(item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = (values: NewsWriterFormValues) => {

        const {
            headline,
            newsCategory,
            introduction,
            body,

        }= pageOneValues;
        const {
           
            conclusion,
            audienceTargeting,
            languageTone,
            seoOptimization,
        } = values;

        

        if (
            !headline ||
            !newsCategory ||
            !introduction ||
            !body ||
            !conclusion ||
            !audienceTargeting ||
            !languageTone ||
            !seoOptimization
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const informationData = `
      Headline: ${headline}
      News Category: ${newsCategory}
      Introduction: ${introduction}
      Body: ${body}
      Conclusion: ${conclusion}
      Audience Targeting: ${audienceTargeting}
      Language and Tone: ${languageTone}
      SEO Optimization: ${seoOptimization}
    `;

        const promptMessage = `Generate a copy based on the following details: ${informationData}. Kindly ensure to follow the details provided`;

        onGenerate(promptMessage);
    };

    const nextPage = () => {
        setCurrentPage(2);
    };

    const previousPage = () => {
        setCurrentPage(1);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
                headline: '',
                newsCategory: '',
                introduction: '',
                body: '',
                conclusion: '',
                audienceTargeting: '',
                languageTone: '',
                seoOptimization: '',
            }}
        >
            <h1 className="text-xl dark:text-white font-bold font-Poppins mb-4 mt-4">News Writer</h1>
            <p className="text-md  dark:text-gray-300 font-Poppins mb-4">
                Create comprehensive and engaging news articles with the help of this tool.
            </p>

            {currentPage === 1 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Headline Generation"
                                name="headline"
                                rules={[{ required: true, message: 'Headline is required' }]}
                            >
                                <Input placeholder="Enter headline" />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="News Categorization"
                                name="newsCategory"
                                rules={[{ required: true, message: 'News Category is required' }]}
                            >
                                <Input placeholder="Enter news category" />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Introduction"
                                name="introduction"
                                rules={[{ required: true, message: 'Introduction is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate an introduction"
                                    uniqueKey="introduction"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('introduction')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('introduction', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={() => {
                                        const headline = form.getFieldValue('headline');
                                        if (!headline) {
                                            toast.error('Please fill in the introduction field before generating.');
                                            return false;
                                        }

                                        const newsCategory = form.getFieldValue('newsCategory');
                                        if (!newsCategory) {
                                            toast.error('Please fill in the introduction field before generating.');
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onClickButton={() => {
                                        form.setFieldValue('introduction', '');
                                        const introduction = `
                                        Headline: ${form.getFieldValue('headline')}
                                        newsCategory: ${form.getFieldValue('newsCategory')}

                                        `;
                                        const promptMessage = `Generate an introduction based on the following details: ${introduction}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['introduction'];
                                        onGenerateWithAI(socket, promptMessage);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Body"
                                name="body"
                                rules={[{ required: true, message: 'Body is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate the body"
                                    uniqueKey="body"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('body')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('body', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={()=>{
                                        const headline = form.getFieldValue('headline');
                                        if (!headline) {
                                            toast.error('Please fill in the introduction field before generating.');
                                            return false;
                                        }
                                        const newsCategory = form.getFieldValue('newsCategory');
                                        if (!newsCategory) {
                                            toast.error('Please fill in the introduction field before generating.');
                                            return false;
                                        }
                                        const introduction = form.getFieldValue('introduction');
                                        if (!introduction) {
                                            toast.error('Please fill in the introduction field before generating.');
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onClickButton= {()=>{
                                        form.setFieldValue('body', '');
                                        const body = `
                                        Headline: ${form.getFieldValue('headline')}
                                        newsCategory: ${form.getFieldValue('newsCategory')}
                                        introduction: ${form.getFieldValue('introduction')}`
                                        const promptMessage = `Generate a body based on the following details: ${body}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['body'];
                                        onGenerateWithAI(socket, promptMessage);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex mt-2">
                        <button
                            onClick={() =>{
                                form.validateFields().then(() => {
                                    setCurrentPage(currentPage + 1);
                                })

                                setPageOneValues({
                                    headline: form.getFieldValue('headline'),
                                    newsCategory: form.getFieldValue('newsCategory'),
                                    introduction: form.getFieldValue('introduction'),
                                    body: form.getFieldValue('body'),
                                });
                            
                            
                            }
                            }
                            className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2"
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {currentPage === 2 && (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                label="Conclusion"
                                name="conclusion"
                                rules={[{ required: true, message: 'Conclusion is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate the conclusion"
                                    uniqueKey="conclusion"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('conclusion')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('conclusion', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={()=>{
                                        return true;
                                    }}
                                    onClickButton={()=>{
                                        form.setFieldValue('conclusion', '');
                                        const conclusion = `
                                        Headline: ${pageOneValues['headline']}
                                        newsCategory: ${pageOneValues['newsCategory']}
                                        introduction: ${pageOneValues['introduction']}
                                        body: ${pageOneValues['body']}
                                        `;
                                        const promptMessage = `Generate a conclusion based on the following details: ${conclusion}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['conclusion'];
                                        onGenerateWithAI(socket, promptMessage);

                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="Audience Targeting"
                                name="audienceTargeting"
                                rules={[{ required: true, message: 'Audience Targeting is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate audience targeting"
                                    uniqueKey="audienceTargeting"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('audienceTargeting')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('audienceTargeting', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={()=>{
                                        return true;
                                    }}
                                    onClickButton={()=>{
                                        form.setFieldValue('audienceTargeting', '');
                                        const audienceTargeting = `
                                        Headline: ${pageOneValues['headline']}
                                        newsCategory: ${pageOneValues['newsCategory']}
                                        introduction: ${pageOneValues['introduction']}
                                        body: ${pageOneValues['body']}
                                        conclusion: ${form.getFieldValue('conclusion')}
                                        `;
                                        const promptMessage = `Generate audience targeting based on the following details: ${audienceTargeting}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['audienceTargeting'];
                                        onGenerateWithAI(socket, promptMessage);
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                label="Language and Tone"
                                name="languageTone"
                                rules={[{ required: true, message: 'Language and Tone are required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate language and tone"
                                    uniqueKey="languageTone"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('languageTone')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('languageTone', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={()=>{
                                        return true;
                                    }}
                                    onClickButton={()=>{
                                        form.setFieldValue('languageTone', '');
                                        const languageTone = `
                                        Headline: ${pageOneValues['headline']}
                                        newsCategory: ${pageOneValues['newsCategory']}
                                        introduction: ${pageOneValues['introduction']}
                                        body: ${pageOneValues['body']}
                                        conclusion: ${form.getFieldValue('conclusion')}
                                        audienceTargeting: ${form.getFieldValue('audienceTargeting')}
                                        `;
                                        const promptMessage = `Generate language and tone based on the following details: ${languageTone}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['languageTone'];
                                        onGenerateWithAI(socket, promptMessage);
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Form.Item
                                label="SEO Optimization"
                                name="seoOptimization"
                                rules={[{ required: true, message: 'SEO Optimization is required' }]}
                            >
                                <CustomTextAreaWithButton
                                    placeholder="Let Gmind AI generate SEO optimization"
                                    uniqueKey="seoOptimization"
                                    loadingStates={loadingStates}
                                    setLoadingStates={setLoadingStates}
                                    value={form.getFieldValue('seoOptimization')} // Bind value to form field
                                    onChange={(e) => form.setFieldValue('seoOptimization', e.target.value)} // Update form value on change
                                    checkIfCanGenerate={()=>{
                                        return true;
                                    }}
                                    onClickButton={()=>{
                                        form.setFieldValue('seoOptimization', '');
                                        const seoOptimization = `
                                        Headline: ${pageOneValues['headline']}
                                        newsCategory: ${pageOneValues['newsCategory']}
                                        introduction: ${pageOneValues['introduction']}
                                        body: ${pageOneValues['body']}
                                        conclusion: ${form.getFieldValue('conclusion')}
                                        audienceTargeting: ${form.getFieldValue('audienceTargeting')}
                                        languageTone: ${form.getFieldValue('languageTone')}
                                        `;
                                        const promptMessage = `Generate SEO optimization based on the following details: ${seoOptimization}. Kindly ensure to follow the details provided`;
                                        const socket = sockets['seoOptimization'];
                                        onGenerateWithAI(socket, promptMessage);
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="flex justify-between mt-4">
                        <button
                            onClick={previousPage}
                            className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                        >
                            Previous
                        </button>
                        <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                            Generate
                        </button>
                    </div>
                </>
            )}
        </Form>
    );
};

export default NewsWriter;
