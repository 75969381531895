
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loading from './components/Loading/index.tsx';
import BusinessResources from './pages/dashboard/pages/business_resources/index.tsx';

import Curriculum from './pages/dashboard/pages/curriculum/index.tsx';
import MediaResources from './pages/dashboard/pages/media_resources/index.tsx';
import SchoolLetters from './pages/dashboard/pages/school_letters copy/index.tsx';
import Generate from './pages/dashboard/pages/generate/index.tsx';
import LoginPage from './pages/acquisition/login';
import SignUpPage from './pages/acquisition/signup';
import ForgotPage from './pages/acquisition/forgot_password';
import Company from './pages/acquisition/company/Company';
import Features2 from './pages/acquisition/features/features2';
import Features3 from './pages/acquisition/features/features3.tsx';
import Features4 from './pages/acquisition/features/features4.tsx';


const StatusComponent = React.lazy(() => import('./pages/acquisition/components/status/index.tsx'));
const Root = React.lazy(() => import('./pages/dashboard/pages/root'));
const Chat = React.lazy(() => import('./pages/dashboard/pages/chat'));
const SmartSearchPage = React.lazy(() => import('./pages/dashboard/pages/chat/smart_search.tsx'));
const NotFoundPage = React.lazy(() => import('./pages/404'));
const Slide = React.lazy(() => import('./pages/dashboard/pages/slide/index.tsx'));
const Transcriber = React.lazy(() => import('./pages/dashboard/pages/transcriber'));
const EditorPage = React.lazy(() => import('./pages/dashboard/pages/essay/editor/index.tsx'));
const Essay = React.lazy(() => import('./pages/dashboard/pages/essay'));
const Research = React.lazy(() => import('./pages/dashboard/pages/research/index.tsx'));
const Image = React.lazy(() => import('./pages/dashboard/image/index.tsx'));
const Assessment = React.lazy(() => import('./pages/dashboard/pages/assessment_2/index.tsx'));
const NewEssayPage = React.lazy(() => import('./pages/dashboard/pages/essay/new_index.tsx'));
const ComingSoonPage = React.lazy(() => import('./pages/dashboard/pages/coming_soon/index.tsx'));
const Speech = React.lazy(() => import('./pages/dashboard/pages/speech/index.tsx'));
const PricingHome = React.lazy(() => import('./pages/acquisition/pricing/home/page.tsx'));
const DashboardPricing = React.lazy(() => import('./pages/dashboard/pages/dashboard_pricing/index.tsx'));
const Layout = React.lazy(() => import('./pages/acquisition/layout/Layout.tsx'));
const Cookies = React.lazy(() => import('./pages/acquisition/policies/cookies/index.tsx'));
const TermsOfService = React.lazy(() => import('./pages/acquisition/policies/tos/index.tsx'));
const Privacy = React.lazy(() => import('./pages/acquisition/policies/privacy/index.tsx'));
const ImageEditor = React.lazy(() => import('./pages/dashboard/pages/image_editor/index.tsx'));
const SuccessPage = React.lazy(() => import('./pages/dashboard/pages/payment_successful/index.tsx'));
const ProfileScreen = React.lazy(() => import('./pages/dashboard/pages/profile/index.tsx'));
const SchoolAdmin = React.lazy(() => import('./pages/dashboard/pages/school_admin/dashboard/index.tsx'));
const Teachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/teachers/index.tsx'));
const AddTeachers = React.lazy(() => import('./pages/dashboard/pages/school_admin/add_teacher/index.tsx'));
const ContactUsPage = React.lazy(() => import('./pages/dashboard/pages/school_admin/contact_us/index.tsx'));
const SchoolProfileScreen = React.lazy(() => import('./pages/dashboard/pages/school_admin/profile/index.tsx'));
const MaintenancePage = React.lazy(() => import('./pages/acquisition/maintanance/maintainance.tsx'));

const Affiliate = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/index.tsx'));
const Referrals = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/referrals.tsx'));
const Transactions = React.lazy(() => import('./pages/dashboard/pages/affiliate/dashboard/transactions.tsx'));
const DashboardFrame = React.lazy(() => import('./components/DashboardFrame.tsx'));
const DataVisualizer = React.lazy(() => import('./pages/dashboard/pages/data_visualizer/index.tsx'));


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={'/dashboard'} element={
        <Suspense fallback={<div><Loading/></div>}>
          <DashboardFrame />
        </Suspense>
      } errorElement={
        <Suspense fallback={<div><Loading/></div>}>
          <NotFoundPage />
        </Suspense>
      }>
        <Route path="chat" element={<Suspense fallback={<div><Loading/></div>}><Chat /></Suspense>} />
        <Route path="smart_chat" element={<Suspense fallback={<div><Loading/></div>}><SmartSearchPage /></Suspense>} />
        <Route path="generate_content" element={<Suspense fallback={<div><Loading/></div>}><Generate /></Suspense>} />
        <Route path="assessment" element={<Suspense fallback={<div><Loading/></div>}><Assessment /></Suspense>} />
        <Route path="article" element={<Suspense fallback={<div><Loading/></div>}><NewEssayPage /></Suspense>} />
        <Route path="article/editor" element={<Suspense fallback={<div><Loading/></div>}><EditorPage /></Suspense>} />
        <Route path="curriculum" element={<Suspense fallback={<div><Loading/></div>}><Curriculum /></Suspense>} />
        <Route path="speech_synthesizer" element={<Suspense fallback={<div><Loading/></div>}><Speech /></Suspense>} />
        <Route path="video_mode" element={<Suspense fallback={<div><Loading/></div>}><ComingSoonPage /></Suspense>} />
        <Route path="image_maker" element={<Suspense fallback={<div><Loading/></div>}><Image /></Suspense>} />
        <Route path="image_maker/edit" element={<Suspense fallback={<div><Loading/></div>}><ImageEditor /></Suspense>} />
        <Route path="research" element={<Suspense fallback={<div><Loading/></div>}><Research /></Suspense>} />
        <Route path="class_resources" element={<Suspense fallback={<div><Loading/></div>}><SchoolLetters /></Suspense>} />
        <Route path="media_resources" element={<Suspense fallback={<div><Loading/></div>}><MediaResources /></Suspense>} />
        <Route path="business_resources" element={<Suspense fallback={<div><Loading/></div>}><BusinessResources /></Suspense>} />
        <Route path="school-admin" element={<Suspense fallback={<div><Loading/></div>}><SchoolAdmin /></Suspense>} />
        <Route path="school-profile" element={<Suspense fallback={<div><Loading/></div>}><SchoolProfileScreen /></Suspense>} />
        <Route path="school-admin/school-teacher" element={<Suspense fallback={<div><Loading/></div>}><Teachers /></Suspense>} />
        <Route path="school-admin/school-teacher/add-teacher" element={<Suspense fallback={<div><Loading/></div>}><AddTeachers /></Suspense>} />
        <Route path="pricing" element={<Suspense fallback={<div><Loading/></div>}><DashboardPricing /></Suspense>} />
        <Route path="payment-success" element={<Suspense fallback={<div><Loading/></div>}><SuccessPage /></Suspense>} />
        <Route path="profile" element={<Suspense fallback={<div><Loading/></div>}><ProfileScreen /></Suspense>} />
        <Route path="visualizer" element={<Suspense fallback={<div><Loading/></div>}><DataVisualizer /></Suspense>} />
      </Route>,
      <Route path="" element={
        <Suspense fallback={<div><Loading/></div>}>
          <Layout isLoggedIn={true} />
        </Suspense>
      } errorElement={
        <Suspense fallback={<div><Loading/></div>}>
          <NotFoundPage />
        </Suspense>
      }>
        <Route path="features" element={<Suspense fallback={<div><Loading/></div>}><Layout isLoggedIn={true} /></Suspense>} />
        <Route path="company" element={<Company isLoggedIn={ localStorage.getItem('token') ? true: false } />} errorElement={<NotFoundPage />} />
        <Route path="creation" element={<Features2 isLoggedIn={ localStorage.getItem('token') ? true: false } />} errorElement={<NotFoundPage />} />
        <Route path="assistant" element={<Features3 isLoggedIn={ localStorage.getItem('token') ? true: false } />} errorElement={<NotFoundPage />} />
        <Route path="researchers" element={<Features4 isLoggedIn={ localStorage.getItem('token') ? true: false }/>} errorElement={<NotFoundPage />} />
        <Route path="cookies" element={<Suspense fallback={<div><Loading/></div>}><Cookies /></Suspense>} />
        <Route path="terms-of-service" element={<Suspense fallback={<div><Loading/></div>}><TermsOfService /></Suspense>} />
        <Route path="privacy-policy" element={<Suspense fallback={<div><Loading/></div>}><Privacy /></Suspense>} />
        <Route path="pricing" element={<Suspense fallback={<div><Loading/></div>}><PricingHome /></Suspense>} />
        <Route path="status" element={<Suspense fallback={<div><Loading/></div>}><StatusComponent /></Suspense>} />
        <Route path="login" element={<LoginPage />} errorElement={<NotFoundPage />} />
        <Route path="forgot_password" element={<ForgotPage />} errorElement={<NotFoundPage />} />
        <Route path="signup" element={<SignUpPage />} errorElement={<NotFoundPage />} />
      </Route>,

      <Route path={'/affiliate'} element={
        <Suspense fallback={<div><Loading/></div>}>
          <DashboardFrame />
        </Suspense>
      } errorElement={
        <Suspense fallback={<div><Loading/></div>}>
          <NotFoundPage />
        </Suspense>
      }>
        <Route path="dashboard" element={<Suspense fallback={<div><Loading/></div>}><Affiliate /></Suspense>} />
        <Route path="referrals" element={<Suspense fallback={<div><Loading/></div>}><Referrals /></Suspense>} />
        <Route path="transactions" element={<Suspense fallback={<div><Loading/></div>}><Transactions /></Suspense>} />
      </Route>
    </>
  ),
);

export default router;
