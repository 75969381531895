import { newGenerationHomeDataProps, TranslatorLangProps } from "../interface";
import webAnalytics from '@icons/web-analytics.png';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import article from '@icons/article.svg';
import analyzing from '@icons/analyzing.png';
import socialMedia from '@icons/social_media.svg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from "@src/utils/app_functions";
import { PromptOptionsType } from "@src/core/interfaces/general";
import transcribe from '@assets/essay/transcription.png';
import translate from '@icons/translation.png';
import summary from '@icons/summary.png';


const newVisualizerData: Array<newGenerationHomeDataProps> = [
    {
        img: webAnalytics,
        head: 'Data Visualizer Tool',
        text: 'Collect data summaries and generate insightful charts for your project.',
        pageIndex: 1,
        pageName: "Data Visualizer Tool",
        label: 'data_visualizer_tool',
    },
    {
        img: analyzing,
        head: 'Data Analyzer Tool',
        text: 'Analyze and interpret data to provide detailed insights and visualizations.',
        pageIndex: 2,
        pageName: "Data Analyzer Tool",
        label: 'data_analyzer_tool',
    },
];

const contentPromptOptionsData = ({response, onSuccess, urlRef, showDownload}: {
    response:string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () => handleShare({
            response,
            onSuccess,
            urlRef,
        }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({response, showDownload}: {
    response:string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const analysisOptions: TranslatorLangProps[] = [
    {
        name: "Analyze Data",
        value: "analyze"
    },
    {
        name: "Visualize Chart",
        value: "visualize"
    }
];

const chartOptions: TranslatorLangProps[] = [
    {
        name: "Bar Chart",
        value: "bar chart"
    },
    {
        name: "Line Chart",
        value: "line chart"
    },
    {
        name: "Pie Chart",
        value: "pie chart"
    },
    {
        name: "Doughnut Chart",
        value: "doughnut chart"
    },
    {
        name: "Area Chart",
        value: "area chart"
    },
    {
        name: "Scatter Plot",
        value: "scatter plot"
    },
    // {
    //     name: "Radar Chart",
    //     value: "radar chart"
    // },
    // {
    //     name: "Bubble Chart",
    //     value: "bubble chart"
    // },
    {
        name: "Heatmap",
        value: "heatmap"
    },
    // {
    //     name: "Candlestick Chart",
    //     value: "candlestick chart"
    // }
];




export {
    newVisualizerData,
    contentPromptOptionsData,
    contentPromptOptionsData2,
    chartOptions,
    analysisOptions,
}