import { getUrlPath } from "@src/utils/app_functions"

const landingPageStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "GMind AI: AI Productivity For Everyone",
    "url": "https://gmind.ai/",
    "description": "Boost productivity and streamline your workflow with GMind AI, the ultimate AI-powered productivity tool. Automate daily tasks, optimize workflows, and enhance efficiency with smart automation.",
    "keywords": ["gmind", "ai", "chat", "gmindai", "teachers ai", "productivity ai"],
    "publisher": {
      "@type": "Organization",
      "name": "GMind",
      "url": "https://gmind.ai/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://gmind.ai/gmind_white.png",
        "width": 512,
        "height": 512
      }
    },
    "author": {
      "@type": "Organization",
      "name": "GMind"
    },
}

const dashboardData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI: AI Productivity For Everyone",
  "url": `https://gmind.ai/dashboard/${getUrlPath().pagename}` ,
  "description": "Create high-quality content, boost productivity and enhance work efficiency with this simple-to-use AI tool—GMind AI: the future at your fingertips.",
  "keywords": ["gmind", "ai", "chat", "gmind", "gmindai", "productivity"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}

const creationData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Creations",
  "url": "https://gmind.ai/creation",
  "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/gmind_white.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}

const assistantData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Assistant",
    "url": "https://gmind.ai/assistant",
    "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
    "keywords": ["gmind", "ai", "chat"],
    "publisher": {
      "@type": "Organization",
      "name": "GMind",
      "url": "https://gmind.ai/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://gmind.ai/assets/logo.png",
        "width": 512,
        "height": 512
      }
    },
    "author": {
      "@type": "Organization",
      "name": "GMind"
    },
}

const researchersData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Researchers",
  "url": "https://gmind.ai/researchers",
  "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}

const featuresData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Features",
  "url": "https://gmind.ai/features",
  "description": "Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored to your needs.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}


const loginStructuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI Login | Access Your AI Productivity Tools",
  "url": "https://gmind.ai/login",
  "description": "Log in to GMind AI and access a full suite of productivity tools. Manage your projects, streamline workflows, and boost efficiency with AI-driven solutions for every professional.",
  "keywords": ["gmind", "ai", "chat", "gmind", "gmindai"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
  "potentialAction": {
    "@type": "LoginAction",
    "target": "https://gmind.ai/login",
    "name": "Login"
  },
}

const pricingData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI Pricing | Affordable Plans for Every Professional",
  "url": "https://gmind.ai/pricing",
  "description": "Explore GMind AI’s flexible pricing plans designed for educators, creators, and business professionals. Discover affordable options to access powerful AI tools for productivity and workflow management.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}

const signupStructuredData = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Sign Up",
  "url": "https://gmind.ai/signup",
  "description": "Best AI for educators, save time planning, creating teaching materials, and get support for teaching practice.",
  "keywords": ["gmind", "ai", "chat"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
  "potentialAction": {
    "@type": "RegisterAction",
    "target": {
      "@type": "EntryPoint",
      "urlTemplate": "https://gmind.ai/signup",
      "actionPlatform": [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/IOSPlatform",
        "http://schema.org/AndroidPlatform"
      ]
    },
    "name": "Sign Up for GMind AI"
  }
}

const searchPage = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "GMind AI Search",
  "url": `https://gmind.ai/dashboard/${getUrlPath().pagename}`,
  "description": "Find quick in-depth answers to your question. Explore the internet's wide array of information all within your fingertips with our powerful inbuilt search engine.",
  "keywords": ["gmind", "ai", "chat", "gmindai"],
  "publisher": {
    "@type": "Organization",
    "name": "GMind",
    "url": "https://gmind.ai/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://gmind.ai/assets/logo.png",
      "width": 512,
      "height": 512
    }
  },
  "author": {
    "@type": "Organization",
    "name": "GMind"
  },
}



export {
  landingPageStructuredData,
  dashboardData,
  creationData,
  assistantData,
  researchersData,
  featuresData,
  loginStructuredData,
  pricingData,
  signupStructuredData,
  searchPage
}