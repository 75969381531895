/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col } from 'antd';
import { CircularProgress } from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import AIGenerate from '@icons/generate.svg';
import { BASE_URL } from '@src/utils/constants';
import { io, Socket } from 'socket.io-client';
import { toast } from 'react-toastify';
import { onGenerateWithAI } from '../../curriculum/functions';

interface JingleWriterLoadingStates {
    title: boolean;
    objective: boolean;
    mainMessage: boolean;
    specialOffer: boolean;
    targetAudience: boolean;
    brandIdentity: boolean;
    length: boolean;
    adTone: boolean; // Changed from emotionAndTone to match usage
    callToAction: boolean;
    lyrics: boolean;
    sloganIntegration: boolean;
    additional: boolean;
}

const CustomTextAreaWithButton = ({
    placeholder,
    uniqueKey,
    loadingStates,
    setLoadingStates,
    value,
    onChange,
    onClickButton,
    canShowButton = true,
}: {
    placeholder: string;
    uniqueKey: keyof JingleWriterLoadingStates;
    loadingStates: JingleWriterLoadingStates;
    setLoadingStates: React.Dispatch<React.SetStateAction<JingleWriterLoadingStates>>;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickButton: () => boolean;
    canShowButton?: boolean
}) => {
    const handleButtonClick = () => {
        const response = onClickButton();
        if  ( response ){
            setLoadingStates((prevState) => ({
                ...prevState,
                [uniqueKey]: true,
            }));
        }
    };

    return (
        <div className="relative">
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="p-2 border-gray-500 focus:outline-none"
                style={{ outline: 'none', boxShadow: 'none' }}
                value={value}
                onChange={onChange}
            />
            <>
                {
                    canShowButton && (
                        <>
                            {!loadingStates[uniqueKey] ? (
                                <div
                                    onClick={handleButtonClick}
                                    className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                >
                                    <img src={AIGenerate} alt="AI Generate" />
                                    <span>Use Gmind AI</span>
                                </div>
                            ) : (
                                <div className="absolute top-[65px] right-[20px]">
                                    <CircularProgress size={15} className="w-1 h-1" />
                                </div>
                            )}
                        </>
                    )
                }
            </>
            
        </div>
    );
};

const JingleScriptForm = ({ onGenerate}: { onGenerate: (e: string) => void; }) => {
    const [page, setPage] = useState(1);
    const [loadingStates, setLoadingStates] = useState<JingleWriterLoadingStates>({
        title: false,
        mainMessage: false,
        specialOffer: false,
        targetAudience: false,
        brandIdentity: false,
        length: false,
        adTone: false,
        callToAction: false,
        lyrics: false,
        sloganIntegration: false,
        objective: false,
        additional: false,
    });

    const [pageOneValues, setPageOneValues] = useState<{[key: string]: string}>({
        'product': '',
        'audience': '',
        'duration': '',
        'mainMessage': '',
        'specialOffer': '',
    });

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null}>({});
    const baseurl = BASE_URL;

    const socketKey = [
        'mainMessage', 
        'specialOffer', 
        'brandIdentity', 
        'adTone', 
        'callToAction', 
        'additional',
        'sloganIntegration',
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        socketKey.forEach(item => {
            newSockets[item] = io(baseurl.slice(0, -2));
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach(socket => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        socketKey.forEach(item => {
            const socket = sockets[item];
            if (!socket) return;

            const handleData = (data: string) => {
                const previousValue = form.getFieldValue(item);
                form.setFieldValue( item, previousValue + data);
            };

            const handleStreamEnd = () => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    [item]: false,
                }));
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        console.log('Form values:', values);
        const {
            product,
            audience,
            duration,
            mainMessage,
            specialOffer,
        } = pageOneValues;

        const {
            brandIdentity,
            sloganIntegration,
            adTone,
            callToAction,
            additional,
        } = values;

        if (
            !product ||
            !audience ||
            !duration ||
            !mainMessage ||
            !specialOffer ||
            !brandIdentity ||
            !sloganIntegration ||
            !adTone
        ) {
            toast.error('Please fill all the required fields before generating.');
            return;
        }

        const informationData = `
            Script focus: ${product}
            \nMain Message: ${mainMessage}
            \nUnique Selling Proposition : ${specialOffer}
            \nAudience Demographics: ${audience}
            \nBrand Identity: ${brandIdentity}
            \nSlogan or Catchphrase: ${length}
            \nAd Tone and Style: ${adTone}
            ${ callToAction ? `\nCall To Action ${callToAction}` : '' }
            ${ additional ? `\nAdditional Features: ${additional}`: '' }
        `;

        const promptMessage = `Generate a well-engaging audio script with the following details: ${informationData}`;
        onGenerate(promptMessage);
    };

    const handleNext = () => {
        if (page < 2) {
            form.validateFields().then(() => {
                setPageOneValues({
                    'product': form.getFieldValue('product'),
                    'audience': form.getFieldValue('audience'),
                    'duration': form.getFieldValue('duration'),
                    'mainMessage': form.getFieldValue('mainMessage'),
                    'specialOffer': form.getFieldValue('specialOffer'),
                })
                setPage(page + 1);
            });
        }
    };

    const handlePrevious = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>

            <h1 className="text-xl dark:text-white font-bold font-Poppins mb-4 mt-4">AudioScript Pro</h1>
            <p className="text-md dark:text-gray-300 font-Poppins mb-4">A powerful tool for generating, editing, and managing high-quality audio scripts for podcasts, voiceovers, audiobooks, and other audio content.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {page === 1 && (
                    <>
                        <Form.Item
                            label="Script focus"
                            name="product"
                            rules={[{ required: true, message: 'Script focus is required' }]}
                        >
                            <Input placeholder="Enter the product service or description" />
                        </Form.Item>
                        <Form.Item
                            label="Audience Demographics"
                            name="audience"
                            rules={[{ required: true, message: 'Audience Demographics is required' }]}
                        >
                            <Input placeholder="Enter your target audience demographics"/>
                        </Form.Item>
                      
                        <Form.Item
                            label="Core Message"
                            name="mainMessage"
                            rules={[{ required: true, message: 'Core message is required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Highlight the core message of your script"
                                uniqueKey="mainMessage"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('mainMessage')}
                                onChange={(e) => form.setFieldsValue({ mainMessage: e.target.value })}
                                canShowButton={false}
                                onClickButton={() => {
                                    form.setFieldValue('mainMessage', '')
                                    const product = form.getFieldValue('product');
                                    if ( !product ){
                                        toast.error('Enter audio script project or service description to use this feature');
                                        return false;
                                    }

                                    const audience = form.getFieldValue('audience');
                                    if ( !audience ){
                                        toast.error('Enter audio script audience to use this feature');
                                        return false;
                                    }


                                    const prompt = `Generate the main message with not more than 300 characters for this audio script ${product} with the target audience ${audience}`;
                                    const socket = sockets["mainMessage"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Special Message/Offer" name="specialOffer">
                            <CustomTextAreaWithButton
                                placeholder="Mention any special offers or promotions"
                                uniqueKey="specialOffer"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('specialOffer')}
                                onChange={(e) => form.setFieldsValue({ specialOffer: e.target.value })}
                                canShowButton={false}
                                onClickButton={() => {
                                    form.setFieldValue('specialOffer', '')
                                    const product = form.getFieldValue('product');
                                    if ( !product ){
                                        toast.error('Enter audio script project or service description to use this feature');
                                        return false;
                                    }

                                    const audience = form.getFieldValue('audience');
                                    if ( !audience ){
                                        toast.error('Enter audio script audience to use this feature');
                                        return false;
                                    }


                                    const prompt = `Generate a special offer with not more than 300 characters for this audio script ${product} with the target audience ${audience}`;
                                    const socket = sockets["specialOffer"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Duration"
                            name="duration"
                            rules={[{ required: true, message: 'Duration is required' }]}
                        >
                            <Input placeholder="Enter your duration of the script"/>
                        </Form.Item>
                    </>
                )}

                {page === 2 && (
                    <>
                        <Form.Item
                            label="Brand Identity"
                            name="brandIdentity"
                            rules={[{ required: true, message: 'Brand Identity is required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Describe the brand’s personality, tone, and messaging style (e.g., friendly, professional, innovative)."
                                uniqueKey="brandIdentity"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('brandIdentity')}
                                onChange={(e) => form.setFieldsValue({ brandIdentity: e.target.value })}
                                onClickButton={() => {
                                    form.setFieldValue('brandIdentity', '')
                                    const product = pageOneValues['product'];
                                    const audience = pageOneValues['audience']
                                    const mainMessage = pageOneValues['mainMessage']
                                    const duration = pageOneValues['duration']
                                    const specialOffer = pageOneValues['specialOffer']

                                    const details = `
                                        Script focus: ${product}\n
                                        Duration: ${duration}\n
                                        Main Message: ${mainMessage}\n
                                        Special Message: ${specialOffer}\n
                                        Audience Demographics: ${audience}\n
                                    `;

                                    const prompt = `Generate a brand identity with not more than 500 characters for this audio script with these details:\n${details}`;
                                    const socket = sockets["brandIdentity"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Slogan or Catchphrase"
                            name="sloganIntegration"
                            rules={[{ required: true, message: 'Slogan is required' }]}
                        >
                            <Input placeholder="Include any established slogan or tagline that should be integrated into the script." />
                        </Form.Item>
                        <Form.Item
                            label="Ad Tone and Style"
                            name="adTone"
                            rules={[{ required: true, message: 'Ad Tone and Style are required' }]}
                        >
                            <CustomTextAreaWithButton
                                placeholder="Indicate the desired tone (e.g., engaging, informative, emotional) and any stylistic elements (e.g., humor, storytelling)."
                                uniqueKey="adTone"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('emotionTone')}
                                onChange={(e) => form.setFieldsValue({ adTone: e.target.value })}
                                onClickButton={() => {
                                    form.setFieldValue('adTone', '')
                                    const product = pageOneValues['product'];
                                    const audience = pageOneValues['audience']
                                    const mainMessage = pageOneValues['mainMessage']
                                    const duration = pageOneValues['duration']
                                    const specialOffer = pageOneValues['specialOffer']

                                    const details = `
                                        Script focus: ${product}\n
                                        Duration: ${duration}\n
                                        Main Message: ${mainMessage}\n
                                        Special Message: ${specialOffer}\n
                                        Audience Demographics: ${audience}\n
                                    `;

                                    const prompt = `Generate an text with not more than 500 characters to sex the ad tone and style for this audio script with these details:\n${details}`;
                                    const socket = sockets["adTone"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Call to Action" name="callToAction">
                            <CustomTextAreaWithButton
                                placeholder="Add a clear call to action (optional)"
                                uniqueKey="callToAction"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('callToAction')}
                                onChange={(e) => form.setFieldsValue({ callToAction: e.target.value })}
                                onClickButton={() => {
                                    form.setFieldValue('callToAction', '')
                                    const product = pageOneValues['product'];
                                    const audience = pageOneValues['audience']
                                    const mainMessage = pageOneValues['mainMessage']
                                    const duration = pageOneValues['duration']
                                    const specialOffer = pageOneValues['specialOffer']

                                    const details = `
                                        Script focus: ${product}\n
                                        Duration: ${duration}\n
                                        Main Message: ${mainMessage}\n
                                        Special Message: ${specialOffer}\n
                                        Audience Demographics: ${audience}\n
                                    `;

                                    const prompt = `Generate a clear call to action with not more than 500 characters for this audio script with these details:\n${details}`;
                                    const socket = sockets["callToAction"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Additional Features (Optional)"
                            name="additional"
                            
                        >
                            <CustomTextAreaWithButton
                                placeholder="Mention any additional elements that should be included, such as background music cues or sound effects."
                                uniqueKey="additional"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('lyrics')}
                                onChange={(e) => form.setFieldsValue({ lyrics: e.target.value })}
                                onClickButton={() => {
                                    form.setFieldValue('lyrics', '')
                                    const title = pageOneValues['title'];
                                    const objective = pageOneValues['objective']
                                    const mainMessage = pageOneValues['mainMessage']
                                    const specialMessage = pageOneValues['specialMessage']
                                    const targetAudience = pageOneValues['targetAudience']

                                    const details = `
                                        Title: ${title}\n
                                        Objective: ${objective}\n
                                        Main Message: ${mainMessage}\n
                                        Special Message: ${specialMessage}\n
                                        Target Audience: ${targetAudience}\n
                                    `;

                                    const prompt = `Generate the lyrics for this jingle ( with not more than 500 characters ) for this jingle with these details:\n${details}`;
                                    const socket = sockets["lyrics"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item>
                        {/* <Form.Item label="Slogan Integration" name="sloganIntegration">
                            <CustomTextAreaWithButton
                                placeholder="Seamlessly integrate your brand's slogan"
                                uniqueKey="sloganIntegration"
                                loadingStates={loadingStates}
                                setLoadingStates={setLoadingStates}
                                value={form.getFieldValue('sloganIntegration')}
                                onChange={(e) => form.setFieldsValue({ sloganIntegration: e.target.value })}
                                onClickButton={() => {
                                    form.setFieldValue('sloganIntegration', '')
                                    let title = pageOneValues['title'];
                                    let objective = pageOneValues['objective']
                                    let mainMessage = pageOneValues['mainMessage']
                                    let specialMessage = pageOneValues['specialMessage']
                                    let targetAudience = pageOneValues['targetAudience']

                                    let details = `
                                        Title: ${title}\n
                                        Objective: ${objective}\n
                                        Main Message: ${mainMessage}\n
                                        Special Message: ${specialMessage}\n
                                        Target Audience: ${targetAudience}\n
                                    `;

                                    let prompt = `Generate a brand slogan with not more than 500 characters for this jingle with these details:\n${details}`;
                                    const socket = sockets["sloganIntegration"];
                                    onGenerateWithAI(socket, prompt);
                                    return true;
                                }}
                            />
                        </Form.Item> */}
                    </>
                )}
            </div>

            <div className="flex justify-between">
                {page > 1 && (
                    <button
                        className="w-full md:w-[8rem] bg-transparent border border-customOrange text-customOrange rounded p-2"
                        onClick={handlePrevious}
                    >
                        Previous
                    </button>
                )}
                {page < 2 ? (
                    <button className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2" onClick={handleNext}>
                        Next
                    </button>
                ) : (
                    <button type="submit" className="w-full md:w-[8rem] bg-customOrange text-white rounded p-2">
                        Generate
                    </button>
                )}
            </div>
        </Form>
    );
};
export default JingleScriptForm;
