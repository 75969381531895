import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { BASE_URL } from '@src/utils/constants';
import { getRegion } from '@src/utils/app_functions';
import { Button as CustomButton } from "@src/pages/acquisition/pricing/components/ui/button";

interface AffiliateModalProps {
    isVisible: boolean;
    onClose: () => void;
    showModal: () => void;
}

const AffiliateConfirmationModal = ({ isVisible, onClose,showModal }: AffiliateModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  const handleConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please login to continue");
      return;
    }

    setLoading(true);

    const baseurl = BASE_URL;
    const finalUrl = `${baseurl}/user/become_an_affiliate`;

    const res = await fetch(finalUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });

    const result = await res.json();

    setLoading(false);

    if (result.statusCode !== 1) {
      toast.error(result.message);
      return;
    }

    // toast.success("Affiliate request submitted successfully");
   
    handleCancel();
    showModal();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    onClose();
  };

  return (
    <div>
      <Modal
        title={<h2 className="text-center text-[0.9rem]">{`Become an Affiliate`}</h2>}
        open={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={500}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <CustomButton key="confirm" disabled={loading} variant={'outline'} className="ml-3 bg-green-600 text-white" onClick={handleConfirm}>
            Confirm
          </CustomButton>
        ]}
      >
        <p>
          Join our affiliate program and start earning rewards by sharing the benefits of our platform. As an affiliate, you can earn commissions while helping others discover valuable features and services. Confirm your participation today and unlock exclusive benefits!
        </p>
      </Modal>
    </div>
  );
};

export default AffiliateConfirmationModal;
