import React, { useState } from "react";
import bottom from "../../../assets/bottom-left.png"
import top from "../../../assets/top-right.png"
import content from "../../../assets/features/content.svg"
import template from "../../../assets/features/template.svg"
import tone from "../../../assets/features/tone.svg"
import reference from "../../../assets/features/refrence.svg"
import word from "../../../assets/features/word.svg"
import Footer from "../components/footer";
import { GetStartedButton } from "@src/components/GetStartedButton";
import { useNavigate } from "react-router";
import { landingPagePrps } from "@src/core/interfaces/general";
import { getDeviceId } from "@src/utils/app_functions";
import useEndpoint from "../auth/auth_endpoints";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { creationData } from "../strutured_data/structured_data";
import RequestDemoModal from "../components/request_demo_modal";


const Features2 = ({ isLoggedIn }: landingPagePrps) => {
    const navigatePage = useNavigate()
    const [showRequestModal, setShowRequestModal] = useState(false);

    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = '/dashboard/chat';
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    }
    return (
        <div className="w-full bg-white">
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(creationData)}</script>
                <meta name="google-site-verification" content="9kRjjJnXQxcAzarlLOs0gHWJCAfJTZ01WZaUSK-tTec" />
                <meta
                    name="description"
                    content="Super-charge your productivity with the best AI tool. Streamline workflows, and enhance efficiency with powerful AI features tailored TO your needs."
                />
                <meta
                    name="title"
                    content="AI Tools And Features | GMind AI"     
                />
                <title>Creations</title>
                <link rel="canonical" href="https://gmind.ai/creation" />
                <meta name="keywords" content="gmind, ai, chat" />
            </Helmet>

            <RequestDemoModal isVisible={showRequestModal} onCancel={() => setShowRequestModal(false)} />


            <div className="w-full h-screen flex items-center justify-center bg-[#000A26] text-white relative z-20">
                <div className="text-center px-6 md:px-12">
                    <h2 className="text-3xl md:text-6xl font-medium md:max-w-4xl">
                        <span className="text-white font-archivo">Effortless Content Creation<br />
                            With Built-in
                            <span className="text-[#E65109] font-archivo">Gmind AI</span>
                            Templates
                        </span>
                    </h2>
                    <p className="mt-6 md:mt-8 text-sm max-w-2xl mx-auto font-poppins">
                        Creating high-quality content has never been easier. GMind AI’s structured templates guide you through the process, allowing creators to achieve polished, engaging results without needing extensive prompting skills.
                    </p>
                    <div className="mt-8 md:mt-14 flex flex-col sm:flex-row justify-center gap-4">
                        <GetStartedButton
                            isLoggedIn={isLoggedIn}
                            className="px-4 py-2 border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                            onclick={() => {
                                if ( isLoggedIn ){
                                    navigatePage('/dashboard/chat')
                                    return;   
                                }
                                handleGuestLogin()
                            }}
                        />
                        <a
                            // onClick={() => navigatePage('/features?showSection=1')}
                            onClick={() => {
                                setShowRequestModal(true);

                                // const email = "support@gmind.ai ";
                                // const subject = "Request Demo for Gmind AI";
                                // const body = "Hi,\n\nI am interested in scheduling a demo for Gmind AI. Please let me know the available dates and times.\n\nThank you.";
                              
                                // window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                            }}
                            className="cursor-pointer w-full sm:w-auto flex justify-center md:items-center px-4 py-3 text-center border border-white text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                        >
                            {/* Watch a demo */}
                            Request Demo
                        </a>
                    </div>
                </div>
            </div>
            <section className="w-full bg-white py-16 px-6 md:px-24 relative">
                {/* Top-right Image */}
                <img
                    src={top}
                    alt="Top decorative"
                    className="absolute -top-40 right-0 w-24 sm:w-32 md:w-48 lg:w-64"
                />

                {/* Our Story Section */}
                <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                    {/* Text Content */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <p className="text-[#111111] text-2xl sm:text-3xl md:text-5xl font-medium mt-4 md:leading-[70px] tracking-wide font-jakarta ">
                            Why GMind AI is Different
                        </p>
                        <p className="text-[#7E8492] text-sm sm:text-base leading-relaxed mt-4 text-justify font-jakarta ">
                            Content creation is challenging, but GMind AI makes it accessible to everyone with built-in templates that reduce the guesswork. No need to master complex prompts—just start with our pre-designed templates, and GMind AI will handle the rest, generating content in the specific tone and format you need.
                        </p>
                    </div>

                    {/* Main Image */}
                    <div className="w-full md:w-2/5 flex justify-center">
                        <img
                            src={content}
                            alt="Story image"
                            className="w-full object-cover rounded-lg"
                        />
                    </div>
                </div>
                {/* Bottom-left Image */}
                <img
                    src={bottom}
                    alt="Bottom decorative"
                    className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                />
            </section>
            <section className="w-full bg-white py-16 px-6 md:px-24 border-t border-[#E8E8E8]">
                {/* Centered Header Section */}
                <div className="flex flex-col items-center text-center mb-12">
                    <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full">
                        GMind AI Tools
                    </span>
                    <h4 className="text-2xl sm:text-3xl md:text-4xl font-semibold font-archivo mt-4 md:max-w-lg">
                        Key Features for Content Creation
                    </h4>
                    <p className="text-sm sm:text-base text-[#7E8492] mt-2">
                        Essential productivity tools to enhance workflow efficiency
                    </p>
                </div>

                {/* Features 1 Section */}
                <div className="flex flex-wrap md:flex-nowrap gap-8 mb-8">
                    {/* First Feature */}
                    <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                        <h5 className="text-lg sm:text-xl md:text-6xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                            Structured Templates
                        </h5>
                        <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                            GMind AI’s templates cater to all content types, ensuring consistent tone, style, and quality while simplifying ideation and setup for social media posts and articles.
                        </p>
                        {/* <div>
                            <a
                                href="#"
                                className="inline-flex items-center gap-2 px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                            >
                                <span>See Templates</span>
                                <span className="bg-white p-1.5 rounded-full">
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div> */}
                    </div>

                    {/* Second Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={template}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Simplify Templates
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Powerful text engine designed for high-end content generation
                            </p>
                        </div>
                    </div>

                    {/* Third Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={tone}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Customizable Tone
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Templates adjust tone for tailored, professional content.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Features 2 section */}
                <div className="flex flex-wrap md:flex-nowrap gap-8 flex-row-reverse ">
                    {/* First Feature */}
                    <div className="flex flex-col justify-between w-full md:w-2/5 py-6">
                        <h5 className="text-lg sm:text-xl md:text-5xl font-medium font-jakarta text-[#111111] mb-4 md:leading-[70px]">
                            Quick Content  Effortlessly
                        </h5>
                        <p className="text-sm sm:text-lg text-[#7E8492] mb-6 font-jakarta">
                            Content Generator creates drafts for blogs, products, and social media effortlessly, ensuring consistent quality with minimal input.
                        </p>
                        {/* <div>
                            <a
                                href="#"
                                className="inline-flex items-center gap-2 px-6 py-2 bg-[#24252A] text-white rounded-2xl border border-[#24252A] hover:bg-white hover:text-black transition duration-300"
                            >
                                <span>Create Content</span>
                                <span className="bg-white p-1.5 rounded-full">
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div> */}
                    </div>

                    {/* Second Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>

                            <img
                                src={word}
                                alt="writing"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Above 4000 Words
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Revamp your words, transform your message and rewrite your text.
                            </p>
                        </div>
                    </div>


                    {/* Third Feature */}
                    <div className="flex flex-col w-full md:w-[30%] rounded-2xl shadow-md border border-[#CCCCCC]">
                        <div className=' bg-gray-100/40 p-6 md:pb-0'>
                            <img
                                src={reference}
                                alt="writing"
                                className="w-full object-cover rounded-lg "
                            />
                        </div>
                        <div className="bg-white p-4 md:py-6 rounded-b-2xl">
                            <h5 className="text-3xl font-medium font-archivo text-[#111111] mb-4">
                                Accurate References
                            </h5>
                            <p className="text-sm text-[#7E8492] font-workSans md:text-base">
                                Create custom visuals to enhance your engaging content.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#F9F7FF] py-16 px-6 flex flex-col items-center text-center">
                {/* Badge */}
                <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full mb-4 font-archivo">
                    What you get
                </span>

                {/* Main Heading */}
                <h4 className="text-2xl sm:text-5xl font-medium text-[#24252A] mb-4 font-archivo md:max-w-2xl md:leading-[70px] md:px-8">
                    Ready to Enhance Your Content Creation?
                </h4>

                {/* Description */}
                <p className="text-sm text-[#7E8492] mb-8 max-w-2xl mx-auto font-workSans">
                    With GMind AI’s structured templates and powerful content generator, you can produce polished, engaging content quickly, even without deep knowledge of prompting techniques.
                </p>

                {/* Buttons */}
                <div className="flex flex-wrap justify-center gap-4">
                    {/* Try GMind AI Free Button */}
                    <GetStartedButton
                        isLoggedIn={isLoggedIn}
                        className="px-4 py-2 border border-white bg-[#031A57] text-white rounded-2xl hover:bg-white hover:text-black transition duration-300"
                        onclick={() => {
                            handleGuestLogin()
                        }}
                    />
                    {/* Explore Pricing Button */}
                    <a
                        onClick={() => navigatePage(isLoggedIn ? '/dashboard/pricing' : '/pricing')}
                        className="cursor-pointer flex items-center gap-2 px-6 py-2 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300"
                    >
                        <span>Explore Pricing</span>
                        <span>
                            <svg
                                width="12"
                                height="10"
                                viewBox="0 0 12 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                    fill="#24252A"
                                />
                            </svg>
                        </span>
                    </a>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Features2;
