import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ExampleCard from '../../../../components/example_card';
import curve from '@assets/curve.svg';
import learningGmind from '@assets/learning_gmind.svg';
import { useNavigate } from 'react-router';
import { GetStartedForFree } from '@src/components/GetStartedButton';
import userIcon from '@icons/user_icon.svg';
import boostUseIcon from '@icons/boost_svg.svg';
import heroIcon from '@assets/essay/LandingIcon.png';
import beside from '@assets/beside.svg';
import choose from '@assets/choose.svg';
import hero from '@assets/hero_image.svg';
import learning from '@assets/learning.svg';
import lesson from '@assets/lesson.svg';
import audio from '@assets/audio.png';
import media from '@assets/media.png';
import settings from '@assets/settings.svg';
import resource from '@assets/resources.svg';
import ask1 from '@assets/ask1.svg';
import world from '@assets/world.png';
import ask2 from '@assets/ask2.svg';
import review from '@assets/reviews.svg';
import osmosis from '@assets/osmosis.svg';
import one from '@assets/1.svg';
import two from '@assets/2.svg';
import three from '@assets/3.svg';
import four from '@assets/4.svg';
import five from '@assets/5.svg';
import six from '@assets/6.svg';
import top from '@assets/top-right.png';
import bottom from '@assets/bottom-left.png';
import market from '@assets/market.svg';


// import DoubleEllipse from '@assets/essay/DoubleEllipse_cropped.png';
// import Review from '@assets/essay/Reviews.png';
// import Verticel from '@assets/essay/Vertical Menu.png';
import useEndpoint from '../../auth/auth_endpoints';
import { toast } from 'react-toastify';
import { getDeviceId } from '@src/utils/app_functions';
import { ArrowRightIcon } from 'lucide-react';
import Faq from '../faq';
import Footer from '../footer';
import Testimonials from './testimonial';
import RequestDemoModal from '../request_demo_modal';

interface Feature {
    id: number;
    title: string;
    text: string;
    icon: any,

}

const About = ({ isLoggedIn, faqRef }: { isLoggedIn?: boolean, faqRef: React.MutableRefObject<HTMLDivElement | null> }) => {
    const texts = ['Creators', 'Educators', 'Learners', 'Researchers'];
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
    const textRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (textRef.current) {
                const nextText = currentText.substring(0, currentText.length - 1);
                setCurrentText(nextText);
                if (nextText.length === 0) {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
                    setCurrentText(texts[currentIndex]);
                }
            }
        }, 2000); // Adjust interval for desired speed

        return () => clearInterval(intervalId);
    }, [texts.length]);

    const handleGuestLogin = async () => {
        function handleCallback() {
            const userData = localStorage.getItem('user');
            if (userData) {
                const userDetails = JSON.parse(userData);

                // if ( userDetails?.plan?.details !== undefined ){
                //     window.location.href = '/dashboard/chat';
                // }else {
                //     window.location.href = '/dashboard/pricing';
                // }
                window.location.href = '/dashboard/chat';
            }
        }

        const deviceId = await getDeviceId();

        useEndpoint()
            .guestLogin({ deviceId })
            .then((res: any) => {
                if (res?.success) {
                    localStorage.setItem('token', res?.data?.token);
                    const { id, firstName, lastName, email, isVerified, plan } = res.data;
                    localStorage.setItem('user', JSON.stringify({ id, firstName, lastName, email, isVerified, plan }));
                    handleCallback();
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            });
    };

    const features: Feature[] = [
        {
            id: 1,
            icon: one,
            title: "Seamless Collaboration",
            text: "GMind enhances team collaboration by allowing users to invite colleagues via email for streamlined editing, commenting, and coordinated project management."
        },
        {
            id: 2,
            icon: two,
            title: "In- Built text Editor",
            text: "GMind's built-in text editor empowers users with full ownership and authoring capabilities by providing tools for comprehensive content creation and customization.",
        },
        {
            id: 3,
            icon: three,
            title: "Language inclusivity",
            text: "GMind's multi-linguistic support caters to diverse language preferences, breaking down barriers across regions, local communities and around the world.",
        },
        {
            id: 4,
            icon: four,
            title: "Humanized communication",
            text: "The built-in humanizer enables users to generate highly relatable, human-like responses, enhancing the naturalness and effectiveness of communication.",
        },
        {
            id: 5,
            icon: five,
            title: "Streamlined task templates",
            text: "GMind's easy-to-use templates provide prompt assistance, guiding input and context for efficient content generation and refined, high-quality results.",
        },
        {
            id: 6,
            icon: six,
            title: "AI Powered Search",
            text: "GMind harness AI-powered search capabilities to source up-to-date information and provide insightful, specific recommendations, thereby boosting task efficiency",
        },
    ];

    return (
        <div>
            <RequestDemoModal isVisible={showRequestModal} onCancel={() => setShowRequestModal(false)} />


            <div className="hidden lg:block">
                {/* Hero section */}
                <div className="bg-[#F1F1EA] px-6 py-16 md:py-24 pb-0 md:pb-0">
                    <div className="max-w-6xl mx-auto text-center flex flex-col items-center">
                        {/* Badge */}
                        <span className="bg-[#8080F240] px-4 py-2 text-sm rounded-full mb-6 font-poppins text-[#1E3A8A]">
                            Your Smart AI Companion
                        </span>

                        {/* Hero Content */}
                        <div className="relative">
                            {/* Positioned Image */}
                            <img
                                src={beside}
                                alt="beside"
                                className="md:absolute -top-12 left-1/2 hidden md:block md:-left-56 transform -translate-x-1/2 md:translate-x-0 w-64 md:w-2/4"
                            />

                            {/* Title */}
                            <h2 className="relative z-50 text-3xl md:text-5xl lg:text-6xl font-bold text-[#24252A] sm:max-w-lg md:max-w-3xl font-archivo md:leading-tight">
                                Elevate Your Productivity {' '}
                                <img src={review} alt='reviews' className='inline' /> With <span className="text-[#E65109] font-archivo">Gmind AI</span> Today
                            </h2>
                        </div>

                        {/* Subtitle */}
                        <p className="mt-6 md:mt-8 text-sm max-w-3xl mx-auto text-[#3A3A3A] font-poppins md:leading-relaxed">
                            Boost productivity and streamline your workflow with GMind AI, the ultimate AI-powered productivity tool. Automate daily tasks, optimize workflows, and enhance efficiency with smart automation and AI-driven solutions for educators, businesses, and professionals.
                        </p>

                        {/* Buttons */}
                        <div className="flex flex-wrap justify-center items-center gap-4 mt-8">
                            {/* Get Started Button */}
                            <GetStartedForFree
                                isLoggedIn={isLoggedIn}
                                className="px-6 py-3 text-white rounded-2xl transition duration-300 font-poppins"
                                onclick={() => {
                                    if (!isLoggedIn) {
                                        handleGuestLogin()
                                    } else {
                                        navigate('/dashboard/chat')
                                    }
                                }}
                            />
                            {/* Watch Demo Button */}
                            <a
                                // onClick={() => navigate('/features?showSection=1')}
                                onClick={() => {
                                    setShowRequestModal(true);
                                    // const email = "support@gmind.ai ";
                                    // const subject = "Request Demo for Gmind AI";
                                    // const body = "Hi,\n\nI am interested in scheduling a demo for Gmind AI. Please let me know the available dates and times.\n\nThank you.";
                                  
                                    // window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                                }}      
                                className="flex items-center gap-2 px-6 py-3 border border-[#E9EAE9] text-black rounded-2xl hover:bg-white hover:text-black transition duration-300 font-poppins cursor-pointer"
                            >
                                {/* Watch Demo */}
                                Request Demo
                                <span>
                                    <svg
                                        width="12"
                                        height="10"
                                        viewBox="0 0 12 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.4879 5.33166L7.26914 9.55041C7.18118 9.63836 7.06189 9.68778 6.9375 9.68778C6.81311 9.68778 6.69381 9.63836 6.60586 9.55041C6.5179 9.46245 6.46849 9.34316 6.46849 9.21877C6.46849 9.09438 6.5179 8.97508 6.60586 8.88713L10.0248 5.46877H0.84375C0.71943 5.46877 0.600201 5.41938 0.512294 5.33147C0.424386 5.24357 0.375 5.12434 0.375 5.00002C0.375 4.8757 0.424386 4.75647 0.512294 4.66856C0.600201 4.58065 0.71943 4.53127 0.84375 4.53127H10.0248L6.60586 1.11291C6.5179 1.02495 6.46849 0.905656 6.46849 0.781267C6.46849 0.656877 6.5179 0.537582 6.60586 0.449626C6.69381 0.361669 6.81311 0.312256 6.9375 0.312256C7.06189 0.312256 7.18118 0.361669 7.26914 0.449626L11.4879 4.66838C11.5315 4.71191 11.566 4.76361 11.5896 4.82051C11.6132 4.87742 11.6254 4.93842 11.6254 5.00002C11.6254 5.06162 11.6132 5.12261 11.5896 5.17952C11.566 5.23643 11.5315 5.28812 11.4879 5.33166Z"
                                            fill="#24252A"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>

                    {/* Hero Image */}
                    <div className="mt-12 flex justify-center">
                        <img src={hero} alt="hero-image" className="w-full max-w-3xl shadow-md" />
                    </div>
                </div>
                <section className="w-full bg-white py-16 px-6 md:px-24 relative">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="hidden md:block absolute -top-56 right-2 w-24 sm:w-32 md:w-48 lg:w-64"
                    />

                    {/* Our Story Section */}
                    <div className="flex flex-col md:flex-row items-center md:items-center md:justify-between gap-8 relative z-10">
                        {/* Text Content */}
                        <div className="w-full md:w-1/2 text-left mt-8 md:mt-0">
                            {/* Why Us Title */}
                            <h4 className="text-[#E55109] text-xl sm:text-2xl font-normal font-workSans">
                                Why Us
                            </h4>

                            {/* Main Heading */}
                            <p className="text-[#111111] text-4xl md:text-6xl font-medium mt-1 md:leading-[70px] max-w-xs md:max-w-xl tracking-wide font-jakarta">
                                Why Choose <span className="font-jakarta">Gmind AI?</span>
                            </p>

                            {/* Supporting Description */}
                            <p className="text-[#7E8492] text-sm leading-relaxed mt-4 text-justify font-workSans">
                                GMIND AI distinguishes itself as a local maven in productivity tools, alleviating the stress of searching, generating, analyzing, and providing learning support. It enhances productivity through structured templates that guide response generation, ensuring consistency and clarity. Furthermore, the platform sets itself apart as an AI system capable of understanding and reflecting local languages, colloquialisms, and cultural nuances.
                            </p>
                        </div>
                        {/* Main Image */}
                        <div className="w-full md:w-2/5 flex justify-center">
                            <img
                                src={choose}
                                alt="Story image"
                                className="w-full object-cover rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="w-full bg-white py-16 h-full">
                        <Swiper
                            pagination={{
                                clickable: true,
                            }}
                            autoHeight={true}
                            modules={[Pagination]}
                            spaceBetween={16} // Default spacing between slides
                            slidesPerView={1} // Default for smaller screens
                            breakpoints={{
                                640: {
                                    slidesPerView: 1, // Single slide view on small screens
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2, // Two slides for medium screens
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3, // Three slides for large screens
                                    spaceBetween: 40,
                                },
                            }}
                            className="w-full h-full"
                        >
                            {features.map((feature) => (
                                <SwiperSlide
                                    key={feature.id}
                                    className="flex flex-col items-center justify-between h-full w-full p-6 rounded-lg shadow-md bg-white border border-[#E5E5E5]"
                                >
                                    {/* Card Content */}
                                    <div className="flex flex-col items-center sm:items-start h-full">
                                        {/* Icon */}
                                        <div className="flex-shrink-0 mb-4">
                                            <img
                                                alt="icon"
                                                src={feature.icon}
                                                className="w-16 h-16 object-contain"
                                            />
                                        </div>
                                        {/* Title */}
                                        <h4 className="text-xl font-semibold font-archivo my-4 mb-2 text-left">
                                            {feature.title}
                                        </h4>
                                        {/* Text */}
                                        <p className="text-sm text-[#7E8492] text-left flex-1 tracking-tight">
                                            {feature.text}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {/* Bottom-left Image */}
                    <img
                        src={bottom}
                        alt="Bottom decorative"
                        className="hidden md:block absolute -bottom-64 left-0 w-32 md:w-48 lg:w-64"
                    />
                </section>
                <section className="w-full bg-white py-16 px-6 md:px-24 border-t border-[#E8E8E8]">
                    {/* Centered Header Section */}
                    <div className="flex flex-col items-center text-center mb-12">
                        <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full font-archivo">
                            Our Features
                        </span>
                        <h4 className="text-2xl sm:text-3xl md:text-5xl font-semibold font-archivo mt-4 md:max-w-xl md:leading-[60px]">
                            Unleash The Power of Gmind's Features
                        </h4>
                        <p className="text-sm sm:text-base text-[#7E8492] mt-2 font-workSans">
                            Essential features to enhance workflow efficiency
                        </p>
                    </div>

                    {/* Features 1 Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={ask1}
                                    alt="Writing"
                                    className="w-1/3 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={ask2}
                                    alt="Organizing"
                                    className="w-2/3  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Ask G-mind AI
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Enhance your productivity with G-mind AI—your smart assistant for organizing tasks, generating insights, and optimizing your workflow.
                                </p>
                            </div>
                        </div>

                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={learning}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Streamline Workflow
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    All-in-one solution: No additional writing tools needed.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Features 2 */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col-reverse rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-2">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={media}
                                    alt="Writing"
                                    className="w-1/3 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={audio}
                                    alt="Organizing"
                                    className="w-2/3 object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:py-4">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Media Resources
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Media Resources Enhancing Communication Through Media Tools.
                                </p>
                            </div>
                        </div>


                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden order-1">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={resource}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:py-4">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Curriculum Planner
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Efficiently organize and streamline
                                    your teaching with our planner.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Features 3 section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mb-8 px-4 md:px-0">
                        {/* Feature Card */}
                        <div className="col-span-2 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="flex bg-gray-100/40 gap-4 p-4 md:pb-0">
                                {/* Smaller Image */}
                                <img
                                    src={market}
                                    alt="Writing"
                                    className="w-1/3 object-cover rounded-lg"
                                />
                                {/* Larger Image */}
                                <img
                                    src={settings}
                                    alt="Organizing"
                                    className="w-2/3  object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Business Resource
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Navigating the complexities of running a business requires more than just vision and hard work—it takes access to the right resources, guidance, and support. Our comprehensive guide to Essential Business Support is designed to empower entrepreneurs and established businesses alike.
                                </p>
                            </div>
                        </div>

                        {/* Placeholder for Additional Content */}
                        <div className="col-span-2 md:col-span-1 flex flex-col rounded-2xl shadow-md border border-[#CCCCCC] overflow-hidden">
                            {/* Image Section */}
                            <div className="bg-gray-100/40 p-4 md:p-6">
                                <img
                                    src={lesson}
                                    alt="Workflow"
                                    className="w-full object-cover rounded-lg"
                                />
                            </div>
                            {/* Text Content */}
                            <div className="bg-white p-6 md:p-8">
                                <h5 className="text-xl md:text-2xl font-medium font-archivo text-[#111111] mb-4">
                                    Class Resources
                                </h5>
                                <p className="text-sm md:text-base text-[#7E8492] font-workSans leading-relaxed">
                                    Empowering Students Through Key
                                    Educational Assets
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full bg-white py-16 px-6 md:px-24 relative overflow-hidden">
                    {/* Top-right Image */}
                    <img
                        src={top}
                        alt="Top decorative"
                        className="absolute -top-28 right-2 w-24 sm:w-32 md:w-48 lg:w-64"
                    />

                    {/* Content Section */}
                    <div className="flex flex-col md:flex-row items-center gap-8">
                        {/* Left - Image */}
                        <div className="flex-1">
                            <img
                                src={osmosis}
                                alt="Mobile"
                                className="w-full h-auto object-contain"
                            />
                        </div>

                        {/* Right - Text Content */}
                        <div className="flex-1">
                            <h4 className="text-2xl sm:text-3xl md:text-5xl font-medium font-archivo text-[#111111] mb-6 md:leading-[60px] tracking-wider">
                                Start Using Gmind AI In Just 4 Quick Steps
                            </h4>

                            {/* Features List */}
                            <div className="">
                                {/* Feature 1 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="125"
                                            viewBox="0 0 3 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="125"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-medium text-[#24252A] mb-2 font-archivo md:text-2xl">
                                            Visit The Website
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans" >
                                            Begin your journey by visiting the website at www.gmind.ai on your browser. The process is quick, easy, and completely free,
                                        </p>
                                    </div>
                                </div>

                                {/* Feature 2 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="125"
                                            viewBox="0 0 3 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="125"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-semibold text-[#24252A] mb-2">
                                            Create Your Account
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans">
                                            Sign up with your email or social media account to create your profile. Personalize your experience with just a few taps.
                                        </p>
                                    </div>
                                </div>
                                {/* Feature 3 */}
                                <div className="flex items-start gap-4">
                                    {/* Icon and Line */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mb-2"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                        <svg
                                            width="3"
                                            height="125"
                                            viewBox="0 0 3 125"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line
                                                x1="1.5"
                                                y1="125"
                                                x2="1.5"
                                                y2="0"
                                                stroke="#24252A"
                                                strokeWidth="2"
                                                strokeDasharray="6 6"
                                            />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-semibold text-[#24252A] mb-2">
                                            Subscribe to A Plan
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans">
                                            Subscribe to unlock a world of features! Choose a plan that fits your needs and dive into exclusive tools and benefits designed just for you.
                                        </p>
                                    </div>
                                </div>
                                {/* Feature 4*/}
                                <div className="flex items-start gap-4">
                                    {/* Icon */}
                                    <div className="flex flex-col items-center">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="8" cy="8" r="7.5" fill="#24252A" />
                                        </svg>
                                    </div>

                                    {/* Text Content */}
                                    <div>
                                        <h5 className="text-xl font-medium text-[#24252A] mb-2 font-archivo md:text-2xl">
                                            Explore the Features
                                        </h5>
                                        <p className="text-sm text-[#7E8492] font-workSans">
                                            Start exploring today and take your experience to the next level!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-[#F1F1EA] px-6 py-16 md:py-24">
                    {/* Centered Heading Section */}
                    <div className="flex flex-col items-center text-center mb-12">
                        <span className="bg-[#FCEBBB59] px-4 py-1 text-sm font-medium rounded-full text-[#24252A] font-archivo">
                            Our User
                        </span>
                        <h4 className="text-2xl sm:text-3xl md:text-4xl font-semibold font-archivo mt-4 md:max-w-sm text-[#24252A] md:leading-[50px]">
                            Serving Users Across The Globe
                        </h4>
                    </div>

                    {/* Centered Image */}
                    <div className="flex justify-center">
                        <img src={world} alt="World Map" className="max-w-full md:max-w-3xl object-contain" />
                    </div>
                </section>
                <Testimonials />
                <section ref={faqRef} className="w-full px-6 md:px-24 bg-white">
                    <Faq />
                </section>
                <section>
                    <Footer />
                </section>
            </div>

            <div className="flex flex-col-reverse gap-y-6 py-[3vh] z-50 lg:hidden">
                <div className="w-full flex flex-col items-center gap-y-5">
                    {/* Text Content */}
                    <div className="flex flex-col gap-y-4 w-full px-4">
                        <h3 className="font-[Poppins] font-bold text-[1.5rem] text-left text-white leading-snug">
                            Smart AI Solutions for{" "}
                            <span ref={textRef} className="text-[var(--gmind-orange)]">
                                {texts[currentIndex]}
                            </span>
                        </h3>

                        <p
                            className="font-normal text-[0.9rem] text-white text-left leading-relaxed"
                        >
                            Generate consistent, high-quality content with minimal prompts with structured templates. Reduce AI drift and boost efficiency for reliable, ready-to-publish results every time. <br />
                            <span className='mt-4 font-normal text-[0.9rem] text-white text-left leading-relaxed'>
                                Smart AI assistant for teachers, content creators, researchers, and business professionals.
                            </span>
                        </p>
                    </div>

                    {/* Mobile Buttons */}
                    <div className="flex justify-center flex-wrap gap-4 w-full px-4">
                        <button
                            onClick={() => navigate("/features?showSection=1")}
                            className="cursor-pointer rounded-md py-2 px-4 text-white border border-white text-sm sm:text-base sm:py-3 sm:px-5"
                        >
                            Watch Demo
                        </button>

                        <button
                            onClick={() => {
                                if (!isLoggedIn) {
                                    handleGuestLogin();
                                } else {
                                    navigate('/dashboard/chat');
                                }
                            }}
                            className="cursor-pointer font-[Poppins] text-white text-sm rounded-md px-4 py-2 bg-[var(--gmind-orange)] sm:text-base sm:py-3 sm:px-5"
                        >
                            {isLoggedIn ? "Dashboard" : "Try it for Free"}
                        </button>
                    </div>
                </div>

                {/* Image Section */}
                <div className="w-full mt-6">
                    <img
                        className="w-[90%] mx-auto"
                        src={heroIcon}
                        alt="optimized"
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    );
};

export default About;