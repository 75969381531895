import { Card, ContentElement } from '../card';
import { Hero } from '../hero';
import '../style.css';
import {Footer} from '../../components/footer/footer';

const Cookies = () => {
    const CardContents = [
        {
            title: 'Introduction',
            content:
                'This Cookie Policy explains how Gmind AI uses cookies and similar technologies when you access and use our website and services. This policy describes what cookies are, why we use them, and your rights to control their use.',
        },
        {
            title: 'What are Cookies?',
            content:
                'Cookies are small text files downloaded to your device while browsing a website. They enable the website to recognize your device and store information about your preferences or past actions.',
        },
        {
            title: 'Types of Cookies',
            content: (
                <ContentElement
                    heading="We use three types of cookies"
                    list={[
                        <span>
                            <strong>Essential Cookies: </strong>Necessary for operating our Services
                        </span>,
                        <span>
                            <strong>Performance Cookies: </strong>Used to analyze visitor behavior and performance
                        </span>,
                        <span>
                            <strong>Functionality Cookies: </strong>Used to recognize and personalize your experience
                        </span>,
                    ]}
                />
            ),
        },
        {
            title: 'Your Choices',
            content: (
                <ContentElement
                    heading="You have the following options regarding cookie use:"
                    list={[
                        <span>
                            <strong>Accept All Cookies: </strong> Consent to our use of cookies by using our Services,
                        </span>,
                        <span>
                            <strong>Reject All Cookies: </strong>
                            Disable cookies in your browser or app settings
                        </span>,
                        <span>
                            <strong>Customize Cookie Settings: </strong>
                            Adjust your settings to accept or reject specific types of cookies
                        </span>,
                    ]}
                />
            ),
        },
        {
            title: 'Managing Cookies',
            content: (
                <ContentElement
                    heading="To manage cookies, follow these steps:"
                    list={[
                        <span>
                            <strong>Browser Settings:</strong>" Adjust your browser's cookie settings",
                        </span>,
                        <span>
                            <strong>App Settings: </strong>Adjust your app's cookie settings
                        </span>,
                        <span>
                            <strong>Cookie Management Tools: </strong>Use third-party tools to manage cookies
                        </span>,
                    ]}
                />
            ),
        },
        {
            title: 'Changes to this Cookie Policy',
            content:
                'We may update this policy to reflect changes to our cookie use or for operational, legal, or regulatory reasons. Please review this policy regularly to stay informed.',
        },
        {
            title: 'Contact Us',
            content: (
                <div className="space-y-2">
                    <p>
                        If you have any questions regarding this Cookie Policy or your personal information, please
                        contact us at <a className='text-brandOrange' href="mailto:support@gotocourse.com">support@gotocourse.com</a>
                    </p>
                    <p>By using our website or services, you consent to the terms of this Cookie Policy.</p>
                    <p>This Cookie Policy was last updated on 05/26/2024.</p>
                    <p>
                        Remember to seek legal advice or have a legal professional review the policy to ensure it
                        complies with relevant laws and regulations in your jurisdiction.
                    </p>
                </div>
            ),
        },
    ];
    return (
        <section className="cookie-parent space-y-8">
            <Hero label='Cookie' highlighted="Policy" link='cookies' />

            <div className="space-y-12 px-16 max-md:px-8">
                {CardContents.map(({ title, content }) => (
                    <Card title={title} content={content} />
                ))}
            </div>
            <Footer />
        </section>
    );
};


export default Cookies;
