// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyDrI082nxkS8kf90cny2gWKUNygyPPdL_I',
    authDomain: 'g-mind-bucket.firebaseapp.com',
    projectId: 'g-mind-bucket',
    storageBucket: 'g-mind-bucket.appspot.com',
    messagingSenderId: '228750197911',
    appId: '1:228750197911:web:641b7c39e8424ba4e755d2',
    measurementId: 'G-BT40MQX131',
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;
