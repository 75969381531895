import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { gradeOptions } from '../../curriculum/constants';
import { useEffect, useRef, useState } from 'react';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import "./style.scss";
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';


const QuoteOfTheDay = ({ onGenerate, setTopic }: LessonMaterialProps) => {
    let grades = gradeOptions();

    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const content = e.target?.result;
    
                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);
    
                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };    
            reader.onerror = () => console.error('Error reading file');
    
            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }
     
        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);
       
    };
    async function handleMicrophone(field:string) {
        if (recording ) {
            stopRecording();
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field:string) => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const onFinish = (values: any) => {
        let promptMessage = '';

        const topic = values.topic;
        const gradeLevel = values.gradeLevel;

        // check if all data is passed
        if (!topic || !gradeLevel ) {
            toast.error('Please enter all prompts field');
            return;
        }

        promptMessage = `Generate an inspirational quote of the day from this topic ${topic} for this grade level students ${gradeLevel}`;

        onGenerate(promptMessage, false);
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
          }
    }, [fileInputRef]);

    return (
        <Form form={form} className="flex flex-col py-4 px-10 space-y-4" onFinish={onFinish} layout="vertical">
            <h1 className="text-xl font-bold dark:text-white">Quote of the Day</h1>
            <p className='dark:text-gray-300'>Generate quote of the day suggestions based on any topic.</p>

            <Form.Item label="Quotes about…" name="topic" className='w-full md:w-[60%]'>
                <div className="flex items-start border dark:border-white border-gray-700 rounded">
                    <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                        <AudioOutlined style={{cursor:"pointer"}} onClick={()=> handleMicrophoneClicked('topic')} className={`hover:text-green-500 transition-colors duration-200 ${recording ? 'dark:text-customOrange' : ' dark:text-white text-gray-500'}`} />

                        
                        <PaperClipOutlined className = 'dark:text-white' onClick={onPaperClipClick} />
                      
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept=".doc,.docx,.pdf"
                            style={{ display: 'none' }} // Hide the input element
                            onChange={(e) => handleFileChange(e, 'topic')}
                        />
                    </div>
                    <TextArea
                             value={form.getFieldValue('topic'|| docData)} // This makes it controlled
                             onChange={(e) => {
                                 setDocData(e.target.value); // Update both docData and form values
                                 form.setFieldsValue({ topic: e.target.value });
                             }}                        className="p-2 rounded-none border-none w-full focus:outline-none"
                        placeholder="Enter a topic"
                        style={{ outline: 'none', boxShadow: 'none' }} // Remove outline and box shadow

                        rows={7}
                    />
                </div>
            </Form.Item>

            <Form.Item label="Grade Level" name="gradeLevel" className='w-full md:w-[60%]'>
                <Select placeholder="Select grade level">
                    {
                        grades.map((item, index) => (
                            <Select.Option value={item}>{item}</Select.Option>
                        ))
                    }
                    {/* <Select.Option value="grade1">Grade 1</Select.Option>
                    <Select.Option value="grade2">Grade 2</Select.Option> */}
                    {/* Add more options as needed */}
                </Select>
            </Form.Item>

            <Form.Item>
                <button
                    type="submit"
                    className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded-lg hover:none"
                >
                    Generate
                </button>
            </Form.Item>
        </Form>
    );
};

export default QuoteOfTheDay;
