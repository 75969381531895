/* eslint-disable react-hooks/exhaustive-deps */
import right from '@assets/right.svg';
import collapse from '@assets/collapse.svg';
import './style.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataType } from './interface/interface';


const RightComponent = ({
    click,
    data,
    name,
    placeholder,
    className,
    style,
    isMobile,
    isHidden,
}: {
    click: (v: string) => void;
    data: DataType<string>[];
    name: string;
    placeholder: string;
    className?: string;
    isMobile?: boolean;
    style?: React.CSSProperties;
    isHidden?: (b: boolean) => void;
}) => {
    const [hidden, setHidden] = useState(false);
    const parentRef = useRef<any>(null);
    const [Name, setName] = useState('last');
    const [isCollapsed, setIsCollapsed] = useState(false);

    function handleSetHidden() {
        setHidden(!hidden);
    }

    useEffect(() => {
        const listener: any = window.addEventListener('resize', () => {
            if (!isMobile) {
                setHidden(false);
                isHidden && isHidden(false);
            }
        });
        if (isMobile) {
            //
        } else {
            setHidden(false);
            isHidden && isHidden(false);
        }

        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [isMobile]);

    useEffect(() => {
        setName(name);
        setIsCollapsed(false)
    }, [name]);

    useEffect(() => {
        console.log(Name, 'is name');
        if (Name && isMobile) {
            setMobileHidden();
        }
    }, [Name]);

    function setMobileHidden() {
        if (!parentRef.current) return;
        const parentStyle = parentRef.current.style;
        parentRef.current.style.transform =
            parentStyle.transform === 'translateX(24rem)' ? 'translateX(0)' : 'translateX(24rem)';
    }
    return (
        <section
            ref={parentRef}
            style={{
                ...style,
                right: '0',
                width: isCollapsed ? '0vw': "20vw",
                transform: isMobile ? 'translateX(24rem)' : 'translateX(0)',
            }}
            className={'h-screen fixed z-[300] shadow-md transition-all w-full flex flex-col bg-[#f9fafc] ' + className}
        >
            <img
                onClick={() => {
                    !isMobile && handleSetHidden();
                    isMobile && setMobileHidden();
                }}
                role="button"
                src={collapse}
                className="mr-auto xl:hidden ps-4 block p-3 w-[1.5rem]"
            />
            <div
                className="flex mt-0 items-center justify-between  transition-all px-2 border-b border-gray-300 h-16"
                style={hidden ? { opacity: '0' } : { opacity: '1' }}
            >
                <p className="font-medium m-0 flex items-center gap-2">
                    <span style={isCollapsed ? { opacity: '0', userSelect: 'none' } : { opacity: '1' }}>{name}</span>
                </p>
                <button
                    style={
                        isCollapsed
                            ? { position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: 'fit-content' }
                            : {}
                    }
                    className="w-fit"
                    type="button"
                    onClick={() => {}}
                >
                    <img
                        onClick={() => {
                            setIsCollapsed(!isCollapsed);
                        }}
                        role="button"
                        src={collapse}
                        className="w-5 block"
                    />
                </button>
            </div>

            <div
                className="mt-3 px-3 h-full overflow-auto flex flex-col gap-3 transition-all"
                style={isCollapsed ? { opacity: '0', scrollbarWidth: 'auto' } : { opacity: 1 }}
            >
                {data?.length ? (
                    data.map((d: DataType<string>) => {
                        const { title, content, id, date } = d;
                        return (
                            <Card
                                key={id}
                                title={title}
                                content={content}
                                id={id}
                                date={date ?? '14:23  12-8-2023'}
                                onClick={(id: string) => {
                                    click(id);
                                    window.innerWidth <= 768 && setHidden(true);
                                }}
                            />
                        );
                    })
                ) : (
                    <p className=" text-gray-400 ">{placeholder}</p>
                )}
            </div>
        </section>
    );
};

const Card = ({
    title,
    content,
    date,
    id,
    onClick,
}: {
    title: string;
    content: string;
    id: string;
    date: string;
    onClick: (id: string) => void;
}) => {
    return (
        <section
            onClick={() => onClick(id)}
            role="button"
            className="flex history_card items-center gap-2 border border-gray-200 rounded-lg p-2"
        >
            <div className="flex flex-col gap-1">
                <p className="font-medium text-sm">{title?.length > 16 ? title.slice(0, 16) + '...' : title}</p>
                <p className="">{content?.length > 30 ? content.slice(0, 30) + '...' : content}</p>
                <p className="text-[0.6rem] text-brandOrange font-medium">{date}</p>
            </div>
            <img src={right} alt="load" className="w-5 ms-auto transition-all history_right" />
        </section>
    );
};

export default RightComponent;
