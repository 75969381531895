const Edit = ({ color, className }: { color: string; className?: string }) => {
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.49992 5.83325L4.99992 5.83325C4.07944 5.83325 3.33325 6.57944 3.33325 7.49992L3.33325 14.9999C3.33325 15.9204 4.07944 16.6666 4.99992 16.6666H12.4999C13.4204 16.6666 14.1666 15.9204 14.1666 14.9999L14.1666 12.4999"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.49992 5.83325L4.99992 5.83325C4.07944 5.83325 3.33325 6.57944 3.33325 7.49992L3.33325 14.9999C3.33325 15.9204 4.07944 16.6666 4.99992 16.6666H12.4999C13.4204 16.6666 14.1666 15.9204 14.1666 14.9999L14.1666 12.4999"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.5 12.5H10L17.0833 5.41669C17.7737 4.72634 17.7737 3.60705 17.0833 2.91669C16.393 2.22634 15.2737 2.22634 14.5833 2.91669L7.5 10V12.5"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.5 12.5H10L17.0833 5.41669C17.7737 4.72634 17.7737 3.60705 17.0833 2.91669C16.393 2.22634 15.2737 2.22634 14.5833 2.91669L7.5 10V12.5"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.3333 4.16675L15.8333 6.66675"
                stroke={color}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.3333 4.16675L15.8333 6.66675"
                stroke="white"
                stroke-opacity="0.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default Edit;
