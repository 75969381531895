import React, { useEffect, useState } from 'react';
import { Modal, Input, Card, Row, Col } from 'antd';
import { TeachingStandardSet } from '@src/utils/teaching_standards';
const { Meta } = Card;


interface BrowseTeachingActivitiesProps {
    visible: boolean;
    onCancel: () => void;
    cardsData: TeachingStandardSet[];
    click: (card: TeachingStandardSet) => void;
}

const BrowseTeachingActivities = ({ visible, onCancel, cardsData, click }: BrowseTeachingActivitiesProps) => {
    const [filteredCards, setFilteredCards] = useState(cardsData);
    
    useEffect(() => {
        setFilteredCards(cardsData);
    }, [cardsData]);

    const handleSearch = (value: any) => {
        const filtered = cardsData.filter(
            (card) =>
                card.name.toLowerCase().includes(value.toLowerCase()) ||
                card.description.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredCards(filtered);
    };

    return (
        <Modal
            open={visible}
            title={
                <div className="text-[1.25rem] text-black-500 font-[Inter,sans-serif]">
                    Choose one from the learning activities.
                </div>
            }
            onCancel={onCancel}
            footer={null}
            width={'57rem'}
        >
            <Input
                style={{ marginBottom: 24, width: '350px' }}
                placeholder="Search templates"
                onChange={(e) => handleSearch(e.target.value)}
                suffix={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                            cx="8.33333"
                            cy="8.33333"
                            r="5.83333"
                            stroke="#E55109"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M17.5 17.5L12.5 12.5"
                            stroke="#E55109"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
            <div style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Row gutter={[16, 16]}>
                    {filteredCards.map((card, index) => (
                        <Col span={12} key={index}>
                            <div className="shadow-md rounded-[0.5rem] flex flex-col justify-between  h-[22rem] md:h-[18rem]" style={{border: "1px solid var(--gmind-border-black)"}}>
                                <div className="p-2 md:p-3 flex flex-col gap-y-2">
                                    <div className="flex items-center justify-between">
                                        <div className="shadow-md text-[--gmind-green-conetent] text-[0.75rem] font-normal p-2 rounded-[1.5rem] bg-[var(--gmind-green)]" style={{border: "1px solid var(--gmind-inout-border)"}} >
                                            100%
                                        </div>

                                        <h2 className="font-medium text-[1rem] text-[var(--gmind-black)]">{card.name}</h2>

                                        <p className="font-normal text-[0.75rem] text-[var(--gmind-light-black)]">
                                            {card.description}
                                        </p>
                                    </div>

                                    <h2 className='font-medium text-[0.813rem] text-[var(--gmind-black)]'>{card.name}</h2>

                                    <p className='font-normal text-[0.75rem] text-[var(--gmind-light-black)]'>{card.description}</p>
                                </div>

                                <button onClick={() => click(card)} className='flex rounded-bl-[0.5rem] rounded-br-[0.5rem] p-2 md:p-3 hover:bg-[var(--gmind-hover-color)] justify-center text-white hover:text-[var(--gmind-light-black)] font-medium text-[0.75rem]'>
                                    Use Activity
                                </button>
                            </div>
                        </Col>
                    ))}
                </Row>

            
            </div>
        </Modal>
    );
};

export default BrowseTeachingActivities;
