/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from 'react';
import cancel from '@assets/cancel.svg';
import search from '@assets/search.svg';
import type { ChatType } from '@src/core/interfaces/chat';
import { dictionary, reverseDictionary } from '@src/utils/constants';
import deleteImage from "@icons/delete.svg";
import { DataType } from './interface/interface';

/* eslint-disable @typescript-eslint/no-unused-vars */

interface HistoryType {
    click: (v: string) => void;
    data: DataType<string>[];
    name?: string;
    className?: string;
    date?: string;
    style?: React.CSSProperties;
    onClose?: () => void;
    hidden?: boolean;
    topChild?: ReactNode;
    closeId?: string
}

const ArticleModal = ({ click, data, name = 'History', className, onClose, style, hidden }: HistoryType) => {
    const [collapse, setCollapse] = useState(!hidden);
    const [paths, setPaths] = useState<any[]>([]);

    useEffect(() => {
        setCollapse(!hidden);
    }, [hidden]);


    useEffect(() => {
        const { pathname } = window.location;
        const split = pathname.split('/').slice(2);
        const formatPaths = split.map((path: string) => dictionary[path]);
        setPaths(formatPaths);
    }, [window.location.pathname]);

    function handleClick(id: string) {
        setCollapse(false);
        onClose && onClose();
        click(id);
    }

    const Data: ChatType[][] = [
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '1' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '2' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '3' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '4' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '5' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '6' },
        ],
        [
            { role: 'user', content: 'Hello Gmind', id: '0' },
            { role: 'assistant', content: 'Hello! How can I assist you today?', id: '7' },
        ],
    ];

    return (
        <section
            style={
                collapse
                    ? { userSelect: 'none', opacity: '0', zIndex: '-1', ...style }
                    : { userSelect: 'auto', opacity: '1', ...style }
            }
            className={
                'bg-white transition-all fixed left-auto inset-0 w-screen h-screen flex flex-col gap-6 z-[500] p-5 px-169 ' +
                className
            }
        >
            <button
                className="ms-auto block"
                onClick={() => {
                    setCollapse(!collapse);
                    onClose && onClose();
                }}
                type="button"
            >
                <img className="w-6" src={cancel} alt="cancel" />
            </button>
            <div className="flex items-center justify-between gap-x-3">
                    <p
                        style={paths.length === 0 ? { fontWeight: '600' } : { fontWeight: '400' }}
                        role="button"
                        className="m-0 text-[1.25rem] font-medium text-[var(--gmind-black)]"
                    >
                        {name}
                    </p>
                    
                    { 

                        name.toLowerCase() == 'resources' && <button className='rounded-[0.5rem] text-[1rem] px-10 py-3 font-normal bg-[--gmind-orange] text-white'>
                            Add Resource
                        </button> 
                    }

                    
            </div>
            <div className='flex justify-between'>
                <span className="border relative hover:border-gray-500 border-gray-300 transition-all rounded-md p-2">
                    <input
                        className="text-xs w-[60vh] font-normal pe-6"
                        type="text"
                        placeholder="Search by date or topic"
                    />
                    <img className="w-4 absolute right-3 top-[50%] translate-y-[-50%]" src={search} alt="search" />
                </span>

                <p className='text-[0.75rem] text-[var(--gmind-orange)] font-normal mr-5'>Clear All</p>
            </div>
            <div className="space-y-5 h-[70vh] overflow-y-auto pe-5">
                {data.map((history: DataType<string>) => {
                    const { title, content, id, date } = history;

                    return (
                        <section
                            role="button"
                            onClick={() => handleClick(id)}
                            key={id}
                            className="border hover:border-gray-500 transition-all border-gray-300 flex items-center justify-between p-3 px-4 rounded-lg"
                        >
                            <div className="flex flex-col gap-2">
                                <span className="text-gray-600 font-medium">
                                    {
                                        title
                                          ?? 'No Title'}
                                </span>
                                <span className="font-normal text-xs">
                                    { 
                                        content ?? 'No Content'
                                    }
                                </span>
                            </div>

                            <div className="flex flex-row gap-x-2">
                                <span className="text-xs text-brandOrange">{date ?? '14:23 12-8-2023'}</span>
                                <img src={deleteImage} alt="delete" />
                            </div>
                        </section>
                    );
                })}
            </div>
        </section>
    );
};

export default ArticleModal;
