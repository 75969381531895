import React, { useEffect, useState } from 'react';
import { Modal, Input, Card, Row, Col } from 'antd';
import { InstructionalModel } from '@src/utils/instruction_model';
const { Meta } = Card;

export interface CardData {
    id: number;
    title: string;
    description: string;
}

interface NewCurriculumProps {
    visible: boolean;
    onCancel: () => void;
    cardsData: InstructionalModel[];
    click: (card: InstructionalModel) => void;
}

const BrowseModal = ({ visible, onCancel, cardsData, click }: NewCurriculumProps) => {
    const [filteredCards, setFilteredCards] = useState(cardsData);
    const [hoveredCard, setHoveredCard] = useState<number>(0);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const handleCardHover = (cardId: number) => {
        setHoveredCard(cardId);
    };

    const handleCardLeave = () => {
        setHoveredCard(0);
    };
    useEffect(() => {
        setFilteredCards(cardsData);
    }, [cardsData]);

    const handleSearch = (value: any) => {
        const filtered = cardsData.filter(
            (card) =>
                card.instruction.toLowerCase().includes(value.toLowerCase()) ||
                card.description.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredCards(filtered);
    };

    return (
        <Modal
            open={visible}
            title={
                <div className="text-[1.25rem] text-black-500 font-[Inter,sans-serif]">Browse Instructional Models</div>
            }
            onCancel={onCancel}
            footer={null}
            width={916}
        >
            <Input
                className='w-full md:w-[21.875rem]'
                style={{ marginBottom: 24}}
                placeholder="Search templates"
                onChange={(e) => handleSearch(e.target.value)}
                suffix={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                            cx="8.33333"
                            cy="8.33333"
                            r="5.83333"
                            stroke="#E55109"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M17.5 17.5L12.5 12.5"
                            stroke="#E55109"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                }
            />
            <div style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Row gutter={[16, 16]}>
                    {filteredCards.map((card, index) => (
                        <Col
                            span={isMobile ? 12: 6}
                            key={index}
                            onMouseEnter={() => handleCardHover(index)}
                            onMouseLeave={handleCardLeave}
                        >
                            <Card className='w-[10rem] flex flex-col gap-y-3 md:w-auto' style={{ height: '172px' }}>
                                <h2 className='font-medium text-[0.813rem] text-[var(--gmind-black)]'>{card.instruction}</h2>

                                <p className='font-normal text-[0.75rem] text-[var(--gmind-light-black)]'>{card.description}</p>
                                {/* <Meta className='text-[0.813rem md:text-auto]' title={card.instruction} description={card.description} /> */}
                                {hoveredCard === index && (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => click(card)}
                                        className="absolute bottom-0 left-0 w-full md:w-[204px] h-10 bg-gray-200 flex flex-row justify-center items-center"
                                    >
                                        Use Templates
                                    </div>
                                )}
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Modal>
    );
};

export default BrowseModal;
