import React, { useState } from 'react';
// import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
// import { Textarea } from '@/components/ui/textarea';
import { Button, ConfigProvider, Progress, Radio, Tag, Upload, UploadProps, message } from 'antd';
import { BackpackIcon, CheckCheckIcon, ChevronLeft } from 'lucide-react';
// import { Button as ShButton } from '@/components/ui/button';
// import type { GetProp, UploadProps } from "antd";

import { UploadOutlined } from '@ant-design/icons';
import { Toaster, toast } from 'sonner';
import usePowerPointStore from '@src/providers/store';
import useFormStore from '@src/providers/formStore';
import { generateOpenAimage } from '../../helper';
import { uploadToCloudinary } from '../../upload';
import { Input, Select } from 'antd';
const { TextArea } = Input;
import "./global.scss"
type Props = {};
export const cloud_name = 'dobksjz6x';
export const upload_preset = 'ml_default';

function SecondForm({}: Props) {
    const slides = usePowerPointStore((state) => state.slides);
    const updateSlide = usePowerPointStore((state) => state.updateSlide);
    const previousStep = useFormStore((state) => state.previousStep);
    const nextStep = useFormStore((state) => state.nextStep);
    const [selectedValues, setSelectedValues] = useState<string[]>(Array(slides.length).fill('ai'));
    const [editedContent, setEditedContent] = useState<string[]>(slides.map((slide) => slide.content));
    const [showProgress, setShowProgress] = useState(Array(slides.length).fill(false));
    const [progress, setProgress] = useState(Array(slides.length).fill(0));
    const [showCheck, setShowCheck] = useState(Array(slides.length).fill(false));
    const handleUploadChange = (info: any, index: any) => {
        toast.info('Uploading....');

        const { status } = info.file;
        if (status === 'done') {
            const image_url = info.file.response.secure_url;
            toast.success(`${info.file.name} file uploaded successfully.`);
            // Update the specific slide with the new image URL
            updateSlide(index, { ...slides[index], image: image_url });
        } else if (status === 'error') {
            toast.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadProps = (index: any): UploadProps => ({
        name: 'file',
        action: `https://api.cloudinary.com/v1_1/${cloud_name}/upload`,
        headers: {
            'X-Requested-With': '',
        },
        data: {
            upload_preset: upload_preset,
        },
        onChange: (info) => handleUploadChange(info, index),
    });

    const handleRadioChange = (value: string, index: number) => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues[index] = value;
        setSelectedValues(newSelectedValues);
    };
    const handleContentChange = (value: string, index: number) => {
        const newEditedContent = [...editedContent];
        newEditedContent[index] = value;
        setEditedContent(newEditedContent);
        updateSlide(index, { ...slides[index], content: value });
    };
    // const beforeUpload = (file: FileType) => {
    //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    //   if (!isJpgOrPng) {
    //     message.error("You can only upload JPG/PNG file!");
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 4;
    //   if (!isLt2M) {
    //     message.error("Image must smaller than 2MB!");
    //   }
    //   return isJpgOrPng && isLt2M;
    // };

    const handleGenerateAIImage = async (index: number) => {
        setShowProgress((prev) => prev.map((value, i) => (i === index ? true : value)));

        setProgress((prev) => prev.map((value, i) => (i === index ? 0 : value)));
        try {
            const subjectElement = document.getElementById(`slide-type-${index}-subject`) as HTMLInputElement;
            const subjectValue = subjectElement.value;
            const moodElement = document.getElementById(`slide-type-${index}-mood`) as HTMLInputElement;
            const moodValue = moodElement.value;
            const colorElement = document.getElementById(`slide-type-${index}-color`) as HTMLInputElement;
            const colorValue = colorElement.value;

            if ( !subjectValue ){
                toast.error("Kindly enter subject of the slide.");
            }


            let moodFinalValue = moodValue ? ` in ${moodValue}${moodValue.includes('mood') ? '': ' mood'}`: "";
            let colorFinalValue = colorValue ? ` with a ${colorValue} background color`: "";

            const aiCommand = `Generate an image of ${subjectValue}${moodFinalValue}${colorFinalValue}`;

            // console.log(aiCommand);
            const timer = setInterval(() => {
                setProgress((prev) => prev.map((value, i) => (i === index && value < 100 ? value + 10 : value)));
            }, 1500);

            const aiImageUrl = await generateOpenAimage(aiCommand, '1024x1024');
            const url = await uploadToCloudinary(aiImageUrl?.data.data[0].b64_json);
            updateSlide(index, {
                ...slides[index],
                image: url,
            });

            setTimeout(() => {
                clearInterval(timer);
                setProgress((prev) => prev.map((value, i) => (i === index ? 100 : value)));
                setShowProgress((prev) => prev.map((value, i) => (i === index ? false : value)));
                setShowCheck((prev) => prev.map((value, i) => (i === index ? true : value)));
            }, 1500);
            if (aiImageUrl) {
                toast.success('AI-generated image added successfully.');
            }
        } catch (error) {
            console.error('Error generating AI image:', error);
            setProgress((prev) => prev.map((value, i) => (i === index ? 100 : value)));
            setShowProgress((prev) => prev.map((value, i) => (i === index ? false : value)));
            setShowCheck((prev) => prev.map((value, i) => (i === index ? false : value)));
            toast.error('Failed to generate AI image. Please try again.');
        } finally {
            setProgress((prev) => prev.map((value, i) => (i === index ? 100 : value)));
            setShowProgress((prev) => prev.map((value, i) => (i === index ? false : value)));
            setShowCheck((prev) => prev.map((value, i) => (i === index ? false : value)));
        }
    };

    function Back() {
        previousStep();
    }
    function Next() {
        nextStep();
    }

    return (
        <div className="text-[#232323] font-[500] overflow-auto">
            <ConfigProvider
                theme={{
                    components: {
                        Radio: {
                            buttonBg: 'white',
                            buttonCheckedBg: '#E55109',
                            dotSize: 16,
                        },
                    },
                }}
            ></ConfigProvider>
            <Toaster richColors />
           
            
            <h1 className="font-[500] text-[36px] dark:text-white md:mt-[3%]">Preview Slides Content</h1>
            <p className="text-gray-500 font-[400] dark:text-gray-300 text-[14px] mb-8">
                Provide content and additional context necessary
            </p>
            {slides.map((data, index) => (
                <div className="space-y-2 max-w-[50%]  max-sm:max-w-full" key={index}>
                    <div className="space-y-1">
                        <label className="text-gray-500 dark:text-gray-300 underline text-[16px]" htmlFor={`slide-type-${index}`}>
                            {data.title}
                        </label>
                        <TextArea
                            className="focus:border-[#E55109] h-auto active:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109] !mb-4"
                            id={`slide-type-${index}-id`}
                            placeholder="E.g. Business presentation, Educational slides, etc."
                            value={editedContent[index]}
                            onChange={(e) => handleContentChange(e.target.value, index)}
                            rows={3}
                        />
                        <p className="text-[14px] dark:text-gray-300 text-gray-500">Select image type</p>

                        <Radio.Group
                            className="text-[8px] flex text-gray-500 !mb-4 !text-sm gap-4"
                            value={selectedValues[index]}
                            onChange={(e) => handleRadioChange(e.target.value, index)}
                        >
                            <div
                                className={`flex items-center space-x-2 border border-[gray] p-1 rounded-md pr-2 ${
                                    selectedValues[index] === 'ai' ? 'bg-[#E55109] text-white border-none' : ''
                                }`}
                            >
                                <Radio
                                    className={`${selectedValues[index] === 'ai' ? 'text-white !border-black' : ''}`}
                                    value="ai"
                                    id={`r1-${index}`}
                                />
                                <label htmlFor={`r1-${index}`} className="!text-sm">
                                    AI- Generated
                                </label>
                            </div>
                            <div
                                className={`flex items-center space-x-2 border border-[gray] p-1 rounded-md pr-2 ${
                                    selectedValues[index] === 'manual' ? 'bg-[#E55109] text-white border-none' : ' '
                                }`}
                            >
                                <Radio
                                

                                    className={`${selectedValues[index] === 'manual' ? 'text-white border-white ' : ' border-white'}`}
                                    value="manual"
                                    id={`r2-${index}`}
                                />
                                <label htmlFor={`r2-${index}`}>Manual</label>
                            </div>
                        </Radio.Group>
                    </div>
    
                    <div className="flex !items-start md:!items-center tex-start md:!text-center gap-2 !mb-3">
                        <div className="flex flex-col gap-y-2 !items-start">
                            <label className="self-start dark:text-gray-300 text-gray-500" htmlFor={`slide-type-${index}`}>
                                Enter subject / object for the image to generate for slide {`${index + 1}*`}
                            </label>
                            <Input
                                className="focus:border-[none] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109]  "
                                id={`slide-type-${index}-subject`}
                                placeholder={`e.g. business team, technology, nature for Slide ${index + 1}.`}
                                disabled={selectedValues[index] === 'manual'}
                                // onKeyPress={(e) => {
                                //     if (e.key === 'Enter' || e.key === 'Send') {
                                //         handleGenerateAIImage(index);
                                //     }
                                // }}
                                // disabled
                            />
                            <label className="text-gray-500 dark:text-gray-300" htmlFor={`slide-type-${index}`}>
                                Enter style / mood for the image to generate for slide {`${index + 1}`}
                            </label>
                            <Input
                                className="focus:border-[none] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109]  "
                                id={`slide-type-${index}-mood`}
                                placeholder={`e.g. modern, minimalist, vibrant for Slide ${index + 1}.`}
                                disabled={selectedValues[index] === 'manual'}
                                // onKeyPress={(e) => {
                                //     if (e.key === 'Enter' || e.key === 'Send') {
                                //         handleGenerateAIImage(index);
                                //     }
                                // }}
                                // disabled
                            />
                            <label className="text-gray-500 dark:text-gray-300" htmlFor={`slide-type-${index}`}>
                                Enter colors / textures for the image to generate for slide {`${index + 1}`}
                            </label>
                            <Input
                                className="focus:border-[none] focus:border-[#E55109] active:border-[none] focus:outline-none focus:shadow-none hover:border-[#E55109]  "
                                id={`slide-type-${index}-color`}
                                placeholder={`e.g. blue and white, wooden, metallic for Slide ${index + 1}.`}
                                disabled={selectedValues[index] === 'manual'}
                                // onKeyPress={(e) => {
                                //     if (e.key === 'Enter' || e.key === 'Send') {
                                //         handleGenerateAIImage(index);
                                //     }
                                // }}
                                // disabled
                            />

                            <Tag onClick={() => handleGenerateAIImage(index)}  className="text-[12px] py-2 bg-gray-600 dark:bg-transparent text-white px-4 rounded-md cursor-pointer mt-2">
                                Generate
                            </Tag>
                        </div>
                        
                        {/* <small className='text-[12px] !text-center'>Enter</small> */}
                        
                    </div>
                    {showProgress[index] && <Progress percent={progress[index]} />}
                    {showCheck[index] && (
                        <div className="flex text-[12px]">
                            <CheckCheckIcon style={{ color: 'green' }} />
                            Generated image
                        </div>
                    )}{' '}
                    {selectedValues[index] === 'ai' && (
                        <div>
                            {data.image && <img src={data.image} className="h-18 w-20" alt={`Slide ${index + 1}`} />}
                        </div>
                    )}
                    {selectedValues[index] === 'manual' && (
                        // <Upload {...uploadProps(index)} className="mt-[10%]">
                        //   <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        // </Upload>
                        <Upload
                            {...uploadProps(index)}
                            // name="avatar"
                            listType="picture-card"
                            className="avatar-uploader mt-[10%]"
                            showUploadList={false}
                            // className="mt-[10%]"
                            // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                            // beforeUpload={beforeUpload}
                            // onChange={handleChange}
                        >
                            {data.image ? (
                                <img src={data.image} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                                <Button icon={<UploadOutlined />}></Button>
                            )}
                        </Upload>
                    )}
                </div>
            ))}
            <button
                className="bg-[#E55109] text-[white] mt-6 border-none hover:bg-[#b6511e] text-sm py-2 px-7 disabled:bg-[#e4af95] rounded-md mb-24"
                onClick={Next}
            >
                Next
            </button>
        </div>
    );
}

export default SecondForm;
